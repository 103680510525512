import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/CustomMUITable";
import { show_proper_words } from "src/utils/constant";
import {
  delete_food_api,
  get_food_list_api,
  import_food_data,
} from "src/DAL/Food/Food";
import { Icon } from "@iconify/react";
import { sample_food_sheet } from "src/assets";
import CustomPopover from "../UserInformation/CustomPopover";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function UserForm() {
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [openImportModal, setOpenImportModal] = useState(false);

  const getFormTemListing = async (search) => {
    setIsLoading(true);

    let postData = { search_text: search, type_of_food: "" };

    const result = await get_food_list_api(page, rowsPerPage, postData);
    if (result.code === 200) {
      setUserList(result.food);
      setTotalPages(result.total_pages);
      setTotalCount(result.total_food);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getFormTemListing(searchText);
  };

  const handleEdit = (value) => {
    navigate(`/food/edit-food/${value._id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    console.log(file, "filefilefilefile");

    if (file) {
      const fileType = file.name.split(".").pop().toLowerCase();
      if (fileType === "csv" || fileType === "xlsx" || fileType === "xls") {
        console.log("File selected:", file);

        const formData = new FormData();
        formData.append("csv", file);
        const result = await import_food_data(formData);
        if (result.code === 200) {
          setOpenImportModal(false);
          getFormTemListing(searchText);
          enqueueSnackbar(result.message, { variant: "success" });
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
        }
      } else {
        enqueueSnackbar("Please upload a CSV, XLSX or XLS file.", {
          variant: "error",
        });
      }
    }
  };

  const handleSampleDownload = () => {
    const sampleFileUrl = sample_food_sheet;
    const link = document.createElement("a");
    link.href = sampleFileUrl;
    link.download = "sample.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_food_api(deleteDoc._id);
    if (result.code === 200) {
      setUserList((prev) => prev.filter((item) => item._id !== deleteDoc._id));
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNavigate = () => {
    navigate(`/food/add-food`);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", type: "number" },
    { id: "name", label: " Name" },
    {
      id: "food",
      label: "Serving Unit",
      renderData: (data) => {
        return (
          <>
            {data?.measurement_units?.length > 0 && (
              <>
                <Typography variant="subtitle2" noWrap>
                  {data?.measurement_units.map((item1, index) => {
                    return (
                      <div className="d-flex">
                        Serving: {item1?.measurement_unit_name}, Calories:{" "}
                        {item1?.total_calories}, Carb: {item1?.carb}, Protein:{" "}
                        {item1?.protein}, Fat: {item1?.fat}, Type:{" "}
                        {show_proper_words(item1?.type)}
                      </div>
                    );
                  })}
                </Typography>
              </>
            )}
          </>
        );
      },
    },
    { id: "status", label: "Status", type: "row_status" },
  ];

  useEffect(() => {
    getFormTemListing(searchText);
  }, [page, rowsPerPage]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-12 d-flex align-items-baseline">
            <h2>Food List</h2>
          </div>
          <div className="col-lg-8 col-sm-12 text-end">
            <button onClick={handleNavigate} className="small-contained-button">
              Add Food
            </button>
            <button
              onClick={() => {
                setOpenImportModal(true);
              }}
              className="small-contained-button ms-2"
            >
              <Icon icon="basil:upload-solid" width={18} height={18} /> Import
              Food
            </button>
          </div>
          <div className="col-12 mt-3">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={userList}
              MENU_OPTIONS={MENU_OPTIONS}
              className="card-with-background"
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
              pagePagination={true}
              rows_per_page_count={rowsPerPage}
            />
          </div>
        </div>
        <CustomPopover
          isOpenPop={openImportModal}
          isClosePop={setOpenImportModal}
          width="600px"
          title={"Import Food"}
          componentToPassDown={
            <div className="px-4 pb-3">
              <div className="text-muted my-2">
                Please check the sample file. If any value does not follow the
                provided format, that row will not be inserted. Duplicate food
                entries will also be skipped.
              </div>
              <div className="mt-3 d-flex justify-content-end align-items-center">
                <button
                  className="small-contained-button ms-2"
                  onClick={handleSampleDownload}
                >
                  <Icon icon="line-md:downloading" width={18} height={18} />{" "}
                  Download Sample File
                </button>
                <button
                  onClick={handleButtonClick}
                  className="small-contained-button ms-2"
                >
                  <Icon icon="basil:upload-solid" width={18} height={18} />{" "}
                  Import Food
                </button>
                <input
                  type="file"
                  accept=".csv, .xlsx, .xls"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
              </div>
            </div>
          }
        />
      </div>
    </>
  );
}
