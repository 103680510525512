import {
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { makeStyles } from "@mui/styles";
import faker from "faker";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { ExerciseListingApi } from "src/DAL/exercise/exercise";

import { AddWorkoutApi } from "src/DAL/workout/workout";

import WorkoutExercisesUI from "./WorkoutExercisesUI";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import NotePopup from "../Calender/NotePopup";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));
const AddWorkout = ({}) => {
  // console.log(addArray, setaddArray, "dflkadsjjkfahds");

  const { state } = useLocation();
  const [inputs, setInputs] = useState({
    video_url: "",
    image: "",
    coach_notes: "",
  });
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [file, setImage] = React.useState();
  const [imageStatus, setImageStatus] = useState(false);
  const [serchText, setSerchText] = useState("");

  const [addArray, setaddArray] = useState([
    {
      exercise: "",
      title: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          heightType: "cm",
          height: "",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          maxReps: "",
          rest: "",
          tempo: "",
          vdot: null,
          race_type: "c",
          pace_type: "c_km",
          pace: 0,
          paceUp: 423,
          optionsType: [
            { title: "1 KM Pace", key: "c_km" },
            { title: "1 MILE Pace", key: "c_mi" },
          ],
        },
      ],
      parameters: ["Weight", "Reps", "Rest"],
      time_interval: 60,
      total_rounds: "",
      rounds: 10,
      type: "general",
      description: "",

      superSet: [],
      emomSets: [[]],
      superset_sets: "",
    },
  ]);
  const handleAdd = () => {
    let list = addArray;
    list.splice(addArray.length, 0, {
      exercise: "",
      title: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          heightType: "cm",
          height: "",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          maxReps: "",
          rest: "",
          tempo: "",
          vdot: null,
          race_type: "c",
          pace_type: "c_km",
          pace: 0,
          paceUp: 423,
          optionsType: [
            { title: "1 KM Pace", key: "c_km" },
            { title: "1 MILE Pace", key: "c_mi" },
          ],
        },
      ],
      parameters: ["Weight", "Reps", "Rest"],
      time_interval: 60,
      total_rounds: "",
      rounds: 10,
      type: "general",
      description: "",

      superSet: [],
      emomSets: [[]],
      superset_sets: "",
    });

    setaddArray([...list]);
  };
  const [navItems, setNavitems] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [menuLists, setMenuList] = useState([
    "5 sec",
    "10 sec",
    "15 sec",
    "20 sec",
    "25 sec",
    "30 sec",
    "35 sec",
    "40 sec",
    "45 sec",
    "50 sec",
    "55 sec",
    "60 sec",
    "1 min",
    "2 min",
    "3 min",
    "4 min",
    "5 min",
    " 10 min",
    " 15 min",
    " 20 min",
    " 25 min",
    " 30 min",
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [isReaload, setIsReaload] = useState(false);
  const [exercise, setExercise] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  function handleOnDragEnd(result) {
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setaddArray(items);
  }
  const urlPatternValidation = (URL) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    return regex.test(URL);
  };
  const fileChangedHandler = (e) => {
    setImageStatus(true);
    setImage(URL.createObjectURL(e.target.files[0]));
    console.log(e.target.files[0], "sdalkfjlkdsjfa");
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    if (
      urlPatternValidation(inputs.video_url) === false &&
      inputs.video_url != ""
    ) {
      enqueueSnackbar("Enter Valid Video URL", { variant: "error" });
      setIsLoading(false);
      return;
    }
    if (inputs.video_url == "" && inputs.image != "") {
      enqueueSnackbar("Please Enter Video URL ", {
        variant: "error",
      });
      setIsLoading(false);
      return;
    }

    if (inputs.video_url && inputs.image == "") {
      enqueueSnackbar("Please Upload Video Thumbnail", { variant: "error" });
      setIsLoading(false);
      return;
    }
    let isError = false;
    let exerciesArray = addArray.map((item, mainIn) => {
      console.log(item, "sdkfljkajs");
      if (
        item.type != "emom" &&
        item.type != "amrap" &&
        item.type != "something_else" &&
        item.type != "rounds_for_time" &&
        item.type != "reps_for_time" &&
        !item.exercise?._id &&
        item.superSet.length == 0
      ) {
        enqueueSnackbar("please select an exercise", { variant: "error" });
        isError = true;
      }
      if (item.type == "something_else" && !item?.title) {
        enqueueSnackbar("please add name", {
          variant: "error",
        });
        isError = true;
      }
      if (item.type == "rounds_for_time" && !item?.total_rounds) {
        enqueueSnackbar("please add Total Rounds", {
          variant: "error",
        });
        isError = true;
      }

      if (
        item.type == "reps_for_time" &&
        Array.isArray(item.reps_round) &&
        item.reps_round.some((value) => value === "" || isNaN(value))
      ) {
        enqueueSnackbar("Please add valid Reps in all fields", {
          variant: "error",
        });
        isError = true;
      }

      let data = {
        exercise_info: {
          _id: item?.superSet[0]?.exercise?._id
            ? item?.superSet[0]?.exercise?._id
            : item.exercise?._id,
          title: item?.superSet[0]?.exercise?.title
            ? item?.superSet[0]?.exercise?.title
            : item.exercise?.title,
          image: item?.superSet[0]?.exercise?.image
            ? item?.superSet[0]?.exercise?.image
            : item.exercise?.image,
        },
        title: item?.title,
        description: item.description,
        parameters: item.parameters,
      };
      if (item?._id) {
        data["_id"] = item?._id;
      }
      if (item.superset_sets) {
        data["superset_sets"] = item.superset_sets;
      }
      if (item.rounds) {
        data["emom_rounds"] = item.rounds;
      }
      if (item.type) {
        data["exercise_type"] = item.type;
      }
      if (item.time_interval) {
        data["interval_duration"] = item.time_interval;
      }
      if (item.total_rounds) {
        data["total_rounds"] = item.total_rounds;
      }

      if (item.reps_round && item.reps_round.length > 0) {
        data["reps_round"] = item.reps_round;
      }

      if (item.set) {
        data["sets"] = [];
        if (item.set.length > 0) {
          data["sets"] = item.set.map((item) => {
            console.log("dskjfjksjdklfja", item);
            let data = {};
            if (item.vdot) {
              data["vdot"] = item.vdot;
            }
            if (item.race_type) {
              data["race_type"] = item.race_type;
            }
            if (item.pace_type) {
              data["pace_type"] = item.pace_type;
            }
            if (item.pace) {
              data["pace_low"] = item.pace;
            }
            if (item.paceUp) {
              data["pace_up"] = item.paceUp;
            }
            if (item.optionsType) {
              data["pace_options"] = item.optionsType;
            }
            if (item.mhr) {
              data["mhr"] = item.mhr;
            }
            if (item.reps) {
              data["reps"] = item.reps;
            }
            if (item.maxReps) {
              data["maxReps"] = item.maxReps;
            }
            if (item.rest) {
              data["rest"] = item.rest;
            }
            if (item.weight) {
              data["weight"] = item.weight;
            }
            if (item.height) {
              data["height"] = item.height;
            }
            if (item.time) {
              data["duration"] = item.time;
            }
            if (item.weightType) {
              data["weight_unit"] = item.weightType;
            }
            if (item.heightType) {
              data["height_unit"] = item.heightType;
            }
            if (item.distance) {
              data["distance"] = item.distance;
            }
            if (item.distanceType) {
              data["distance_unit"] = item.distanceType;
            }
            if (item.timeType) {
              data["duration_unit"] = item.timeType;
            }
            if (item.restType) {
              data["rest_unit"] = item.restType;
            }
            if (item.tempo) {
              data["tempo"] = item.tempo;
            }
            return data;
          });
        }
      }
      if (
        item.type == "emom" ||
        item.type == "amrap" ||
        item.type == "rounds_for_time" ||
        item.type == "reps_for_time"
      ) {
        data["emom_amrap"] = [];
        if (item.emomSets.length > 0) {
          item.emomSets.map((item1, ind) => {
            console.log(item1, "sjdkfksjdka");
            if (item1.length > 0) {
              data["emom_amrap"][ind] = item1.map((item2) => {
                if (isError) {
                  return;
                }
                if (!item2.exercise?._id) {
                  if (item.type == "emom") {
                    enqueueSnackbar(
                      `please select an exercise in EMOM,  EMOM placed on ${
                        mainIn + 1
                      } position. `,
                      {
                        variant: "error",
                      }
                    );
                  }
                  if (item.type == "amrap") {
                    enqueueSnackbar(
                      `please select an exercise in AMRAP, AMRAP placed on ${
                        mainIn + 1
                      } position. `,
                      {
                        variant: "error",
                      }
                    );
                  }
                  if (item.type == "rounds_for_time") {
                    enqueueSnackbar(
                      `please select an exercise in Rounds for Time, Rounds for Time placed on ${
                        mainIn + 1
                      } position. `,
                      {
                        variant: "error",
                      }
                    );
                  }

                  if (item.type == "reps_for_time") {
                    enqueueSnackbar(
                      `please select an exercise in Reps For Time, Reps For Time placed on ${
                        mainIn + 1
                      } position. `,
                      {
                        variant: "error",
                      }
                    );
                  }

                  isError = true;

                  return;
                }
                let data = {
                  exercise_info: {
                    _id: item2.exercise?._id,
                    title: item2.exercise?.title,
                    image: item2.exercise?.image,
                  },
                  title: item2.exercise?.title,
                  description: item2.description,
                  parameters: item2.parameters,
                };
                if (item2.set) {
                  data["sets"] = [];
                  if (item2.set.length > 0) {
                    data["sets"] = item2.set.map((item3) => {
                      let data = {};
                      if (item3.race_type) {
                        data["race_type"] = item3.race_type;
                      }
                      if (item3.pace) {
                        data["pace_low"] = item3.pace;
                      }
                      if (item3.mhr) {
                        data["mhr"] = item3.mhr;
                      }
                      if (item3.reps) {
                        data["reps"] = item3.reps;
                      }
                      if (item3.maxReps) {
                        data["maxReps"] = item3.maxReps;
                      }
                      if (item3.rest) {
                        data["rest"] = item3.rest;
                      }
                      if (item3.weight) {
                        data["weight"] = item3.weight;
                      }
                      if (item3.height) {
                        data["height"] = item3.height;
                      }
                      if (item3.time) {
                        data["duration"] = item3.time;
                      }
                      if (item3.weightType) {
                        data["weight_unit"] = item3.weightType;
                      }
                      if (item3.heightType) {
                        data["height_unit"] = item3.heightType;
                      }
                      if (item3.distance) {
                        data["distance"] = item3.distance;
                      }
                      if (item3.distanceType) {
                        data["distance_unit"] = item3.distanceType;
                      }
                      if (item3.timeType) {
                        data["duration_unit"] = item3.timeType;
                      }
                      if (item3.restType) {
                        data["rest_unit"] = item3.restType;
                      }
                      if (item3.tempo) {
                        data["tempo"] = item3.tempo;
                      }
                      if (item3.vdot) {
                        data["vdot"] = item3.vdot;
                      }
                      if (item3.pace_type) {
                        data["pace_type"] = item3.pace_type;
                      }
                      if (item3.paceUp) {
                        data["pace_up"] = item3.paceUp;
                      }
                      if (item3.optionsType) {
                        data["pace_options"] = item3.optionsType;
                      }
                      return data;
                    });
                  }
                }

                return data;
              });
            } else {
              if (item.type == "emom") {
                enqueueSnackbar(
                  `please add a Movement on ${
                    ind + 1
                  } Interval in EMOM, EMOM placed on ${mainIn + 1} position.`,
                  {
                    variant: "error",
                  }
                );
              }
              if (item.type == "amrap") {
                enqueueSnackbar(
                  `please add a Movement in AMRAP,  placed on ${
                    mainIn + 1
                  } position.`,
                  {
                    variant: "error",
                  }
                );
              }
              if (item.type == "rounds_for_time") {
                enqueueSnackbar(
                  `please add a Movement in Rounds for Time,  placed on ${
                    mainIn + 1
                  } position.`,
                  {
                    variant: "error",
                  }
                );
              }

              if (item.type == "reps_for_time") {
                enqueueSnackbar(
                  `please add a Movement in Reps For Time,  placed on ${
                    mainIn + 1
                  } position.`,
                  {
                    variant: "error",
                  }
                );
              }

              isError = true;

              return;
            }
          });
        }
      }
      if (item.superSet) {
        data["sub_exercises"] = [];
        if (item.superSet.length > 0) {
          data["sub_exercises"] = item.superSet.map((item) => {
            if (isError) {
              return;
            }
            if (!item.exercise?._id) {
              enqueueSnackbar(
                `please select an exercise in Superset,  placed on ${
                  mainIn + 1
                } position.`,
                {
                  variant: "error",
                }
              );

              isError = true;
              return;
            }
            let data = {
              exercise_info: {
                _id: item.exercise?._id,
                title: item.exercise?.title,
                image: item.exercise?.image,
              },
              title: item.exercise?.title,
              description: item.description,
              parameters: item.parameters,
            };
            if (item.set) {
              data["sets"] = [];
              if (item.set.length > 0) {
                data["sets"] = item.set.map((item) => {
                  let data = {};
                  if (item.race_type) {
                    data["race_type"] = item.race_type;
                  }
                  if (item.pace) {
                    data["pace_low"] = item.pace;
                  }
                  if (item.mhr) {
                    data["mhr"] = item.mhr;
                  }
                  if (item.reps) {
                    data["reps"] = item.reps;
                  }
                  if (item.maxReps) {
                    data["maxReps"] = item.maxReps;
                  }
                  if (item.rest) {
                    data["rest"] = item.rest;
                  }
                  if (item.weight) {
                    data["weight"] = item.weight;
                  }
                  if (item.height) {
                    data["height"] = item.height;
                  }
                  if (item.time) {
                    data["duration"] = item.time;
                  }
                  if (item.weightType) {
                    data["weight_unit"] = item.weightType;
                  }
                  if (item.heightType) {
                    data["height_unit"] = item.heightType;
                  }
                  if (item.distance) {
                    data["distance"] = item.distance;
                  }
                  if (item.distanceType) {
                    data["distance_unit"] = item.distanceType;
                  }
                  if (item.timeType) {
                    data["duration_unit"] = item.timeType;
                  }
                  if (item.restType) {
                    data["rest_unit"] = item.restType;
                  }
                  if (item.tempo) {
                    data["tempo"] = item.tempo;
                  }
                  if (item.vdot) {
                    data["vdot"] = item.vdot;
                  }
                  if (item.pace_type) {
                    data["pace_type"] = item.pace_type;
                  }
                  if (item.paceUp) {
                    data["pace_up"] = item.paceUp;
                  }
                  if (item.optionsType) {
                    data["pace_options"] = item.optionsType;
                  }
                  return data;
                });
              }
            }

            return data;
          });
        }
      }
      return data;
    });
    if (isError) {
      setIsLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("title", state.title);

    formData.append("image", state.image);
    if (imageStatus) {
      formData.append("video_thumbnail", inputs.image);
    }

    formData.append("video_url", inputs.video_url);
    formData.append("type", state.type);
    formData.append(
      "difficulty_levels",
      JSON.stringify(state?.difficulty_levels)
    );
    formData.append("description", state.short_description);
    formData.append("status", state.status);
    formData.append("exercises", JSON.stringify(exerciesArray));
    formData.append("coach_notes", inputs.coach_notes);
    console.log(exerciesArray, "dskjfhakjdsh");
    console.log(...formData, "dskjfhakjdsh");

    const result = await AddWorkoutApi(formData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeUp = (value) => {
    if (value == 0) {
      enqueueSnackbar("You are already on Top !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value - 1, 0, reorderedItem);
    setaddArray(items);
  };

  const handleChangeDown = (value) => {
    if (value >= addArray.length - 1) {
      enqueueSnackbar("You are already on Bottom !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value + 1, 0, reorderedItem);
    setaddArray(items);
  };
  const memueOptions = (value) => {
    const MENU_OPTIONS = [];

    MENU_OPTIONS.unshift(
      {
        label: "Move Up",
        icon: "mdi:arrow-up",
        handleClick: handleChangeUp,
        disabled: value == 0 ? true : false,
      },
      {
        label: "Move Down",
        icon: "ic:outline-arrow-downward",
        handleClick: handleChangeDown,
        disabled: value >= addArray.length - 1 ? true : false,
      }
    );

    return MENU_OPTIONS;
  };
  const MENU_OPTIONS_MOVE = [
    {
      label: "Move Up",
      icon: "mdi:arrow-up",
      handleClick: handleChangeUp,
    },
    {
      label: "Move Down",
      icon: "ic:outline-arrow-downward",
      handleClick: handleChangeDown,
    },
  ];

  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  // console.log(state, "sdjkfklsjdlfka");
  // console.log(addArray, "addArray ok 11");
  return (
    <div className="container">
      <div className="row mt-3 align-items-center">
        <div className="col-12 col-md-10">
          <div className="d-flex align-items-center">
            <IconButton
              className="back-screen-button mb-0"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            <h1 className="programmes-heading ms-2">{state?.title}</h1>
          </div>
        </div>
        <div className="col-12 col-md-2 text-end">
          <button
            className="small-contained-button "
            onClick={() => {
              handleOpen();
            }}
          >
            Add Coach Note
          </button>
        </div>
        <div className="col-12 section-space">
          <div
            dangerouslySetInnerHTML={{
              __html: state?.short_description,
            }}
          ></div>
        </div>
        <div className="row my-3">
          <div className="col-11 video-text">
            <p>
              The video URL and the thumbnail image are linked, as adding a
              video URL requires a thumbnail and vice versa. The thumbnail will
              appear on the calendar, and clicking it will open the popup.
            </p>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12 ">
            <TextField
              id="outlined-basic"
              label="Video Url"
              variant="outlined"
              fullWidth
              // required
              name="video_url"
              value={inputs.video_url}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12">
            <div className="row w-100 div-style ms-0 p-0">
              <div className="col-lg-5 col-md-5 col-sm-12">
                <p className="mb-0">Upload Video Thumbnail </p>
                <FormHelperText className="pt-0" sx={{ fontSize: 9 }}>
                  Image Size(500 X 500) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className=" col-lg-2 col-md-2 col-sm-12">
                {file ? (
                  <img className="image-border" src={file} height="50" />
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12  text-lg-end text-md-end text-sm-start pt-2">
                <label htmlFor="contained-button-file">
                  <input
                    accept=".jpg, .jpeg, .png, .webp"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    required
                    onChange={fileChangedHandler}
                    style={{ display: "none" }}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                    variant="contained"
                    size="small"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.image.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.image.name}</p>
            )}
          </div>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd} isDragDisabled={true}>
          <Droppable droppableId="characters" isDragDisabled={true}>
            {(provided) => (
              <div
                className="characters"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <form onSubmit={handleSubmit}>
                  {addArray.length > 0 ? (
                    <>
                      <WorkoutExercisesUI
                        addArray={addArray}
                        setaddArray={setaddArray}
                        setModalChangeExe={() => {}}
                        isSidebar={true}
                      />
                      <div className="text-end mt-4">
                        <button className="small-contained-button">
                          {isLoading ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="text-end container my-4">
                      <button
                        className="small-contained-button "
                        onClick={() => {
                          handleAdd();
                        }}
                      >
                        Add Exercise
                      </button>
                    </div>
                  )}
                </form>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <NotePopup
        inputs={inputs}
        setInputs={setInputs}
        open={open}
        handleClose={handleClose}
      />
    </div>
  );
};
export default AddWorkout;
