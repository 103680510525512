import React, { useState, useEffect, useRef } from "react";
// import FormsDrewer from "../../components/FormsDrewer/FormsDrewer";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  styled,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "@fullcalendar/daygrid/main.css";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import AddIcon from "@mui/icons-material/Add";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import { eventsListing } from "src/DAL/Calendar/Calendar";
import { CircularProgress, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";

import FullPagePopup from "src/components/FormsDialog/FullPagePopup";
import DetailPopUpModel from "src/components/Calender/DetailPopUpModel";

import DayCalendar from "src/pages/Calender/DayCalendar";
import WeekCalendar from "src/pages/Calender/WeekCalendar";

import AddWorkoutPopup from "src/pages/Calender/component/AddWorkoutPopup";
import EditWorkoutPopup from "src/pages/Calender/component/EditWorkoutPopup";
import CustomConfirmation from "../ModalPopover/Confirmation";
import {
  add_program_workout_api,
  delete_program_workout_Api,
  delete_program_workout_details_Api,
  update_program_workout_api,
  reorder_events_api,
  paste_multiple_workouts,
  delete_program_workout_Api_all,
} from "src/DAL/programmeWorkout/programmeWorkout";
import ConfirmationWithDescription from "../ModalPopover/ConfirmationWithDescription";
import { Icon } from "@iconify/react";
import { get_root_value } from "src/utils/domUtils";
import { is_small_medium_screen } from "src/utils/constant";
import { s3baseUrl } from "src/config/config";
import { addNotes, getNotes } from "src/DAL/notes";
import TextEditor from "./GeneralQuestions/TextEditor";
import GeneralCkeditor from "./GeneralCkeditor";
import CloseIcon from "@mui/icons-material/Close";
import CustomPopoverSection from "../MenuOption/CustomPopoverSection";
import CustomPopover from "src/pages/UserInformation/CustomPopover";
import PastedWorkouts from "./PastedWorkouts";

const get_view_dates = (calendarValue) => {
  let tool = calendarValue;
  // if(calendarValue=="month")

  const startOfMonth = moment().startOf(tool);
  const daysToSubtract = startOfMonth.day() - 0; // 0 represents Sunday
  const previousSunday = startOfMonth.subtract(daysToSubtract, "days");
  let previousSundayDate = previousSunday.toDate();
  let add_days = 42;
  if (tool == "week") {
    add_days = 6;
  } else if (tool == "day") {
    add_days = 0;
  }
  let nextSaturday = startOfMonth.add(add_days, "days");
  let nextSaturdayDate = nextSaturday.toDate();
  if (tool == "day") {
    previousSundayDate = new Date();
    nextSaturdayDate = new Date();
  }

  return {
    start_date: previousSundayDate,
    end_date: nextSaturdayDate,
  };
};
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function GeneralCalendar({
  programWorkoutList,
  setCurrentDate,
  showAddEvent,
  time_zone,
  currentDate,
  setIsLoading,
  isLoading,
  user_type,
  reloadList,
  calendar_title,
  hideArrowsAndButtons,
  programme,
  isPasted,
  setIsPasted,
  difficultyLevel,
  setDifficultyLevel,
}) {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const rowRef = useRef(null);
  const classes = useStyles();
  const [eventDetailData, setEventDetailData] = useState({});
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [openEditor, setOpenEditor] = useState(false);

  const [addNewEvent, setAddNewEvent] = useState(false);
  const [popupState, setPopupState] = useState(false);
  const [drawerState, setDrawerState] = useState(false);
  const member_id = useParams();
  console.log(member_id, "member_idmember_idmember_id");

  const [generalInfo, setGeneralInfo] = useState({
    slot_1_percentage: 0,
    slot_2_percentage: 0,
    slot_3_percentage: 0,
    slot_1_percentage_color: "#000",
    slot_2_percentage_color: "#000",
    slot_3_percentage_color: "#000",
    slot_1_coins: 0,
    slot_2_coins: 0,
    slot_3_coins: 0,
    activity_day: "Monday",
    activity_time: 0,
    already_completed_description: 0,
  });

  const handleOpenEditor = () => {
    setOpenEditor(true);
  };
  const handleCloseEditor = () => {
    setOpenEditor(false);
  };
  const INITIAL_VALUES = {
    title: "",
    description: "",
    categories: [],
    status: true,
    image: null,
    imagePreview: null,
    videoURL: "",
  };

  const [drawerStateUpdate, setDrawerStateUpdate] = useState(false);
  const [inputs, setInputs] = useState(INITIAL_VALUES);

  const [open, setOpen] = useState(false); // State to control dialog visibility

  const [openItration, setopenItration] = useState(false);
  const [selectedTool, setSelectedTool] = useState("month");
  const [openDelete, setOpenDelete] = useState(false);
  const [openMultiDelete, setOpenMultiDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [editItrationDrawerState, setEditItrationDrawerState] = useState();
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredDate, setHoveredDate] = useState(null);
  const [selectedDate, setSelectDate] = useState("");
  const [dataDel, setDataDel] = useState();
  const [dataCopy, setDataCopy] = useState();
  const [pasteDate, setPasteDate] = useState();
  const [effectedMembers, setEffectedMembers] = useState(programme?.members);
  const [isPastedWorkouts, setIsPastedWorkouts] = useState(false);
  const [editWorkputData, setEditWorkputData] = useState();
  const [startDate, setStartDate] = useState(null);
  const calendarRef = useRef(null);
  const [openDragConfig, setOpenDragConfig] = useState(false);
  const [DragConfigDate, setDragConfigDate] = useState(false);
  const [DragConfigData, setDragConfigData] = useState(false);
  const [openCopyPasteConfig, setOpenCopyPasteConfig] = useState(false);
  const [selectedWorkouts, setSelectedWorkouts] = useState([]);
  const [pastedWorkouts, setPastedWorkouts] = useState([]);
  const [intervals, setIntervals] = useState([]);
  const [highlightedDates, setHighlightedDates] = useState([]);
  const [hoverdInfo, setHoverdInfo] = useState(null);
  const [slot1Info, setSlot1Info] = useState({
    button_title: "",
    button_link: "",
    result_box_1: "",
    result_box_2: "",
    result_box_3: "",
    result_box_4: "",
    result_box_5: "",
  });
  const [slot2Info, setSlot2Info] = useState({
    button_title: "",
    button_link: "",
    result_box_1: "",
    result_box_2: "",
    result_box_3: "",
    result_box_4: "",
    result_box_5: "",
  });
  const [slot3Info, setSlot3Info] = useState({
    button_title: "",
    button_link: "",
    result_box_1: "",
    result_box_2: "",
    result_box_3: "",
    result_box_4: "",
    result_box_5: "",
  });
  const [notes, setNotes] = useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((old) => ({ ...old, [name]: value }));
  };

  const handleDeleteEvent = (eventToDelete) => {
    // Handle deletion logic here
  };

  const handleCellHover = (info) => {
    // info.el.classList.add("highlighted-hover");
    setHoveredDate(info.date);
    setHoverdInfo(info);
  };

  const handleCellUnhover = (info) => {
    // setHoveredDate(null);
  };
  const handleOpen = () => {
    setOpen(true); // Open the dialog
  };

  const handleOpenMultiDelete = () => {
    setOpenMultiDelete(true);
  };

  const handleProgrammeMember = () => {
    setIsPastedWorkouts(true);
  };

  const handleClose = () => {
    setOpen(false); // Close the dialog
  };

  const getNotesList = async () => {
    const result = await getNotes(programme?._id);

    if (result.code === 200) {
      if (result.data && result.data.content) {
        setInputs((oldInputs) => ({
          ...oldInputs,
          description: result.data.content,
        }));
      }
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
      });
      setNotes(result.data);
    }
  };

  useEffect(() => {
    getNotesList();
  }, [programme?._id]);

  const handleDescriptionChange = (newValue) => {
    setInputs((old) => ({
      ...old,
      description: newValue,
    }));
  };

  const handleAddNote = async () => {
    const postData = {
      programId: programme?._id,
      content: inputs.description,
    };

    const result = await addNotes(postData);

    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      getNotesList();
      handleClose();
    } else {
      enqueueSnackbar(result.message || "Failed to add the note", {
        variant: "error",
      });
    }
  };

  const isFirstEventOnDate = (test) => {
    let date = moment(test.workout_date).format("YYYY-MM-DD");
    const sameDateEvents = events.filter((e) => e.date === date);
    return sameDateEvents[0]._id === test._id;
  };

  const isLastEventOnDate = (test) => {
    let date = moment(test.workout_date).format("YYYY-MM-DD");
    const sameDateEvents = events.filter((e) => e.date === date);
    return sameDateEvents[sameDateEvents.length - 1]._id === test._id;
  };
  const handleScrollCalled = (data) => {
    const todayCell = document.querySelector(
      `.calendar-workout-card[id="${data}"]`
    ); // Find the DOM element corresponding to today's date
    if (todayCell)
      todayCell.scrollIntoView({ behavior: "smooth", block: "center" });
    // todayCell.scrollIntoView({ behavior: "instant", block: "center" });
  };
  const get_dates = (tool) => {
    if (tool == "month") {
      return get_view_dates(tool);
    } else {
      return {
        start_date: moment().startOf(tool).toDate(),
        end_date: moment().endOf(tool).toDate(),
      };
    }
  };

  const handleOpenDrawer = (arg) => {
    // setPastedWorkouts([]);
    // setSelectedWorkouts([]);
    setSelectDate(arg);
    setDrawerState(true);
  };

  const isWorkoutSelected = (workout) =>
    selectedWorkouts.some((item) => item._id === workout._id) ||
    (pastedWorkouts && pastedWorkouts.some((item) => item._id === workout._id));

  function generateDates(intervals, pastedDate, selected) {
    const result = []; // Store the final dates
    for (let i = 0; i < selected.length; i++) {
      let j = i - 1;
      const newWorkoutDate = new Date(pastedDate);
      pastedDate = newWorkoutDate;
      if (i === 0) {
      } else {
        const interval = intervals[j] || 0;

        newWorkoutDate.setDate(newWorkoutDate.getDate() + interval);

        console.log("New Workout Date before validation:", newWorkoutDate);
        result.push(newWorkoutDate);
      }
    }
    return result;
  }

  const handlePaste = async (arg) => {
    const dates =
      selectedWorkouts &&
      selectedWorkouts.map((workout) => workout.workout_date);
    const intervalsAll = calculateIntervals(dates);

    const newDates = [arg, ...generateDates(intervalsAll, arg, dates)];
    setIntervals(newDates);
    setPasteDate(arg);
    setOpenCopyPasteConfig(true);
  };
  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };

  const eventDetail = (event) => {
    setEventDetailData(event);
    setPopupState(true);
  };
  const handleAgreeDelete = async (data) => {
    // setPastedWorkouts([]);
    // setSelectedWorkouts([]);
    setDataDel(data);

    setEffectedMembers(programme?.members);
    setOpenDelete(true);
    // const result = await delete_program_workout_details_Api(data?._id);
    // if (result.code === 200) {
    //
    // } else {
    //   enqueueSnackbar(result.message, { variant: "error" });
    // }
  };
  const handleCopyExercise = (data) => {
    setEditWorkputData();
    setPastedWorkouts();
    setSelectedWorkouts((prevSelected) => {
      const updatedSelected = prevSelected.some(
        (workout) => workout._id === data._id
      )
        ? prevSelected.filter((workout) => workout._id !== data._id)
        : [...prevSelected, data];

      // Set `setDataCopy(true);` only if there are selected workouts
      if (updatedSelected.length > 0) {
        setDataCopy(true);
      } else {
        setDataCopy(false);
      }

      return updatedSelected;
    });
  };
  const handleUpdate = () => {
    handleOpenEditDrawer();
  };
  const handleOpenEditIterationDrawer = () => {
    setEditItrationDrawerState(true);
  };
  const handleUpdateIteration = (data) => {
    setSelectDate(data?.workout_date);
    setEditWorkputData(data);
    handleOpenEditIterationDrawer();
  };
  const closeDragConfig = async (value) => {
    setOpenDragConfig(false);
    getEventListing(programWorkoutList, time_zone);
    // reloadList();
  };
  const closeCopyPasteConfig = async (value) => {
    setPastedWorkouts([]);
    setSelectedWorkouts([]);
    setOpenCopyPasteConfig(false);
    setDataCopy(false);
    getEventListing(events, time_zone);
    // reloadList();
  };

  const calculateIntervals = (dates) => {
    const intervals = [];
    for (let i = 1; i < dates.length; i++) {
      const prevDate = new Date(dates[i - 1]);
      const currDate = new Date(dates[i]);
      const interval = Math.ceil((currDate - prevDate) / (1000 * 60 * 60 * 24)); // Days
      // const formatted = moment(interval).format("YYYY-MM-DD");
      intervals.push(interval);
    }
    return intervals;
  };
  const handleCopyPaste = async (value) => {
    setIsPasted(true);
    const workoutsIds = selectedWorkouts.map((workout) => workout._id);

    const formDataObject = {
      copiedWorkoutIds: workoutsIds,
      workout_copy_type: "unlimited",
      targetStartDate: moment(pasteDate).format("YYYY-MM-DD"),
      target_program_id: programme?._id,
    };

    console.log(formDataObject, "formDataObject paste multiple");

    const result = await paste_multiple_workouts(formDataObject);
    if (result.code === 200) {
      console.log(result, "paste multiple");
      // setEditWorkputData(result?.copiedWorkouts[0]);
      setPastedWorkouts(result?.copiedWorkouts);
      setSelectedWorkouts([]);

      // enqueueSnackbar(result.message, { variant: "success" });
      setDataCopy(false);
      reloadList(
        result?.copiedWorkouts[0]?._id,
        result.message,
        setOpenCopyPasteConfig
      );
      // const workout = result?.copiedWorkouts.map((workout, i) => {
      //   return {
      //     ...workout,
      //     title: workout.title,
      //     color: workout.program_info?.color,
      //     workout_color: workout.program_info?.color,
      //     workout_title: workout.title,
      //     workout_exercises: workout.exercises,
      //     workout_program: workout.program_info,
      //     status: workout.status,
      //     is_deleteAble: workout?.workout_loggers.length > 0 ? false : true,
      //     date: moment(workout.workout_date).format("YYYY-MM-DD"),
      //     index: events.length + i,
      //     open: true,
      //     editable: workout?.workout_loggers.length > 0 ? false : true,
      //   };
      // });

      // let list = [...events, ...workout];
      // setEvents(list);
      // setTimeout(() => {
      //   handleScrollCalled(result?.copiedWorkouts[0]?._id);
      // });
    } else {
      setSelectedWorkouts([]);
      enqueueSnackbar(result.message, { variant: "error" });
      setDataCopy(false);
      setIsPasted(false);
    }
  };
  const handleDraged = async (value) => {
    setIsPasted(true);
    const formDataObject = {
      title: DragConfigData?.workout_title,
      description: DragConfigData?.description,
      exercises: DragConfigData?.workout_exercises,
      workout_type: "",
      status: true,
      workout_date: moment(DragConfigDate).format("YYYY-MM-DD"),
      video_url: DragConfigData?.video_url,
      video_thumbnail: DragConfigData?.video_thumbnail,
    };
    const formData = new FormData();

    formData.append("title", formDataObject.title);
    formData.append("description", formDataObject.description);
    formData.append("exercises", JSON.stringify(formDataObject.exercises));
    formData.append("workout_type", formDataObject.workout_type);
    formData.append("status", formDataObject.status);
    formData.append("workout_date", formDataObject.workout_date);
    formData.append(
      "video_url",
      formDataObject.video_url ? formDataObject.video_url : ""
    );
    if (formDataObject.video_thumbnail) {
      formData.append(
        "duplicate_image_path",
        JSON.stringify(formDataObject.video_thumbnail)
      );
    }

    const result = await update_program_workout_api(
      formData,
      DragConfigData?.program_workout_slug
    );

    if (result.code === 200) {
      setEditWorkputData(DragConfigData);
      reloadList(
        result?.program_workout?._id,
        result.message,
        setOpenDragConfig
      );
      // enqueueSnackbar(result.message, { variant: "success" });
      // const workout = result?.program_workout;
      // let newData = {
      //   ...workout,
      //   title: workout.title,
      //   color: workout.program_info?.color,
      //   workout_color: workout.program_info?.color,
      //   workout_title: workout.title,
      //   workout_exercises: workout.exercises,
      //   workout_program: workout.program_info,
      //   status: workout.status,
      //   is_deleteAble: workout?.workout_loggers.length > 0 ? false : true,
      //   date: moment(workout.workout_date).format("YYYY-MM-DD"),
      //   index: events.length - 1,
      //   open: true,
      //   editable: workout?.workout_loggers.length > 0 ? false : true,
      // };
      // let list = [...events];
      // list[DragConfigData?.index] = newData;
      // setEvents(list);
      // const todayCell = document.querySelector(
      //   `.calendar-workout-card[id="${result?.program_workout?._id}"]`
      // ); // Find the DOM element corresponding to today's date

      // todayCell.scrollIntoView({ behavior: "instant", block: "center" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      getEventListing(programWorkoutList, time_zone);
    }
  };
  const handleDelete = async (value) => {
    setOpenDelete(false);

    const result = await delete_program_workout_Api(
      dataDel?.program_workout_slug
    );
    if (result.code === 200) {
      setEditWorkputData();
      enqueueSnackbar(result.message, { variant: "success" });
      let updatedEvents = events.filter(
        (item) => item.program_workout_slug != dataDel.program_workout_slug
      );
      setEvents(updatedEvents);

      const today = moment(dataDel?.workout_date).format("YYYY-MM-DD"); // Get today's date in YYYY-MM-DD format
      const todayCell = document.querySelector(`.fc-day[data-date="${today}"]`); // Find the DOM element corresponding to today's date
      if (todayCell) {
        todayCell.scrollIntoView({ behavior: "instant", block: "start" }); // Scroll the cell into view
      }
      setPastedWorkouts([]);
      setSelectedWorkouts([]);
      setDataCopy(false);

      // reloadList();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleDeleteMultipul = async () => {
    setOpenMultiDelete(false);
    const postData = {
      program_workout_slugs: selectedWorkouts.map(
        (workout) => workout.program_workout_slug
      ),
    };
    const result = await delete_program_workout_Api_all(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      let updatedEvents = events.filter(
        (item) =>
          !postData.program_workout_slugs.includes(item.program_workout_slug)
      );
      setEvents(updatedEvents);
      setEditWorkputData();
      setPastedWorkouts([]);
      setSelectedWorkouts([]);
      setDataCopy(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleReorder = async (newdate, newevents) => {
    const ids = newevents.map((event) => event._id);
    let postData = {
      workoutIds: ids,
      // date: moment(newdate).format("YYYY-MM-DD"),
    };
    const result = await reorder_events_api(postData);
    if (result.code === 200) {
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const EventCard = ({ workout, onDelete, onHover }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [data, setData] = useState(workout);
    const timeoutRef = useRef(null);
    useEffect(() => {
      // Cleanup function to clear the timeout on component unmount
      return () => {
        clearTimeout(timeoutRef.current);
      };
    }, []);
    const formatWithLeadingZeros = (value) => {
      return value < 10 ? `0${value}` : `${value}`;
    };
    const getMinutesForInterval = (mainindex, index) => {
      const list = [...workout._def.extendedProps.workout_exercises];
      const interval = list[mainindex].interval_duration;
      if (interval <= 60) {
        return index + 1;
      }
      if (interval % 60 == 0) {
        return `${
          (interval / 60) * index +
          1 +
          " – " +
          ((interval / 60) * index + interval / 60)
        }`;
      }
      let startInterval = interval * index;
      let endtInterval = interval * index + interval;
      let startintervalText =
        interval * index
          ? Math.floor(startInterval / 60) +
            " " +
            ":" +
            formatWithLeadingZeros(startInterval % 60)
          : 0;
      let endIntervalText =
        Math.floor(endtInterval / 60) +
        " " +
        ":" +
        formatWithLeadingZeros(endtInterval % 60);

      return `${startintervalText + " – " + endIntervalText}`;
    };
    const handleMouseEnter = () => {
      clearTimeout(timeoutRef.current); // Clear any existing timeout
      setIsHovered(true);
    };

    const moveEventUp = (eventId) => {
      // setPastedWorkouts([]);
      // setSelectedWorkouts([]);
      setEditWorkputData(eventId);
      // Find the index of the event in the events array
      const eventIndex = events.findIndex(
        (event) => event.index === eventId.index
      );

      // Check if the event is not the first one on its date and if it exists in the events array
      if (eventIndex > 0 && eventIndex !== -1) {
        // Swap the event with the one above it
        const temp = events[eventIndex];

        events[eventIndex] = events[eventIndex - 1];
        events[eventIndex - 1] = temp;
        // Swap the values of order and index
        [events[eventIndex].order, events[eventIndex - 1].order] = [
          events[eventIndex - 1].order,
          events[eventIndex].order,
        ];
        [events[eventIndex].index, events[eventIndex - 1].index] = [
          events[eventIndex - 1].index,
          events[eventIndex].index,
        ];

        const changedDateEvents = events.filter(
          (event) => event.workout_date === eventId.workout_date
        );
        setEvents([...events]);
        handleReorder(eventId.workout_date, changedDateEvents);

        setTimeout(() => {
          handleScrollCalled(eventId._id);
          // Scroll the cell into view
        }, 0);
      }
    };

    const moveEventDown = (eventId) => {
      // setPastedWorkouts([]);
      // setSelectedWorkouts([]);
      setEditWorkputData(eventId);
      // Find the index of the event in the events array
      const eventIndex = events.findIndex(
        (event) => event.index === eventId.index
      );

      // Check if the event is not the last one on its date and if it exists in the events array
      if (eventIndex < events.length - 1 && eventIndex !== -1) {
        // Swap the event with the one below it
        const temp = events[eventIndex];
        events[eventIndex] = events[eventIndex + 1];
        events[eventIndex + 1] = temp;

        // Swap the values of order and index
        [events[eventIndex].order, events[eventIndex + 1].order] = [
          events[eventIndex + 1].order,
          events[eventIndex].order,
        ];
        [events[eventIndex].index, events[eventIndex + 1].index] = [
          events[eventIndex + 1].index,
          events[eventIndex].index,
        ];

        // Filter the events of the changed date
        const changedDateEvents = events.filter(
          (event) => event.workout_date === eventId.workout_date
        );

        // Call the function to handle reordering on the server/API with only the events of the changed date
        setEvents([...events]);
        handleReorder(eventId.workout_date, changedDateEvents);

        setTimeout(() => {
          handleScrollCalled(eventId._id);
          // Scroll the cell into view
        }, 0);
      }
    };

    const handleMouseLeave = () => {
      // Introduce a delay of 100 milliseconds before updating state on hover out
      const delay = 300;
      timeoutRef.current = setTimeout(() => {
        setIsHovered(false);
      }, delay);
    };

    // useEffect(() => {
    //   if (
    //     editWorkputData?._id == workout._def.extendedProps?._id &&
    //     calendarRef?.current
    //   ) {
    //     // Get today's date in YYYY-MM-DD format
    //     const todayCell = document.querySelector(
    //       `.calendar-workout-card[id="${editWorkputData?._id}"]`
    //     ); // Find the DOM element corresponding to today's date
    //     if (todayCell) {
    //       todayCell.scrollIntoView({ behavior: "instant", block: "center" }); // Scroll the cell into view
    //     }
    //   }
    // }, [events, editWorkputData]);
    return (
      <div
        className="calendar-workout-card"
        id={workout._def.extendedProps?._id}
        style={{
          boxShadow: isWorkoutSelected(workout._def.extendedProps)
            ? "-2px -2px 7px 1px var(--portal-theme-primary), 1px 1px 7px 2px var(--portal-theme-primary)"
            : "",
          border:
            // isWorkoutSelected(workout._def.extendedProps) ||
            editWorkputData?._id == workout._def.extendedProps?._id
              ? "2px solid var(--portal-theme-primary)"
              : "0px solid var(--portal-theme-primary)",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {is_small_medium_screen() ? (
          <div className="w-100 h-100" onMouseEnter={handleMouseEnter}>
            {
              <div className="all-icon-calendar-workout-general-small-screen d-flex w-100 justify-content-between">
                <div className="d-flex">
                  <Tooltip
                    title={`${
                      workout._def.extendedProps.is_deleteAble
                        ? "Delete"
                        : "Can't be deleted, This workout has results."
                    }`}
                  >
                    <DeleteIcon
                      className={`${
                        workout._def.extendedProps.is_deleteAble
                          ? "del-icon-calendar-workout-general"
                          : "del-icon-calendar-workout-disabled-general"
                      }`}
                      onClick={
                        workout._def.extendedProps.is_deleteAble
                          ? () => handleAgreeDelete(workout._def.extendedProps)
                          : () => {}
                      }
                    />
                  </Tooltip>
                  <Tooltip title={`${"Copy"}`}>
                    <div
                      className={`${"copy-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center"}`}
                    >
                      <ContentCopyIcon
                        sx={{ width: 17, height: 17 }}
                        onClick={() =>
                          handleCopyExercise(workout._def.extendedProps)
                        }
                      />
                    </div>
                  </Tooltip>
                </div>
                <div className="d-flex">
                  {" "}
                  <Tooltip title="Move Up">
                    <div className="arrow-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center">
                      <IconButton
                        disabled={isFirstEventOnDate(
                          workout._def.extendedProps
                        )}
                      >
                        <KeyboardArrowUpIcon
                          sx={{ width: 17, height: 17 }}
                          // onClick={() =>
                          //   handleCopyExercise(workout._def.extendedProps)
                          // }
                          onClick={() =>
                            moveEventUp(workout._def.extendedProps)
                          }
                        />
                      </IconButton>
                    </div>
                  </Tooltip>{" "}
                  <Tooltip title="Move Down">
                    <div className="arrow-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center">
                      <IconButton
                        disabled={isLastEventOnDate(workout._def.extendedProps)}
                      >
                        <KeyboardArrowDownIcon
                          sx={{ width: 17, height: 17 }}
                          // onClick={() =>
                          //   handleCopyExercise(workout._def.extendedProps)
                          // }
                          onClick={() =>
                            moveEventDown(workout._def.extendedProps)
                          }
                        />
                      </IconButton>
                    </div>
                  </Tooltip>{" "}
                </div>
              </div>
            }
          </div>
        ) : (
          <div className="w-100 h-100" onMouseEnter={handleMouseEnter}>
            {isHovered && (
              <div className="all-icon-calendar-workout-general d-flex w-100 justify-content-between">
                <div className="d-flex">
                  <Tooltip
                    title={`${
                      workout._def.extendedProps.is_deleteAble
                        ? "Delete"
                        : "Can't be deleted, This workout has results."
                    }`}
                  >
                    <DeleteIcon
                      className={`${
                        workout._def.extendedProps.is_deleteAble
                          ? "del-icon-calendar-workout-general"
                          : "del-icon-calendar-workout-disabled-general"
                      }`}
                      onClick={
                        workout._def.extendedProps.is_deleteAble
                          ? () => handleAgreeDelete(workout._def.extendedProps)
                          : () => {}
                      }
                    />
                  </Tooltip>
                  <Tooltip title={`${"Copy"}`}>
                    <div
                      className={`${"copy-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center"}`}
                    >
                      <ContentCopyIcon
                        sx={{ width: 17, height: 17 }}
                        onClick={() =>
                          handleCopyExercise(workout._def.extendedProps)
                        }
                      />
                    </div>
                  </Tooltip>
                </div>
                <div className="d-flex">
                  {" "}
                  <Tooltip title="Move Up">
                    <div className="arrow-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center">
                      <IconButton
                        disabled={isFirstEventOnDate(
                          workout._def.extendedProps
                        )}
                      >
                        <KeyboardArrowUpIcon
                          sx={{ width: 17, height: 17 }}
                          // onClick={() =>
                          //   handleCopyExercise(workout._def.extendedProps)
                          // }
                          onClick={() =>
                            moveEventUp(workout._def.extendedProps)
                          }
                        />
                      </IconButton>
                    </div>
                  </Tooltip>{" "}
                  <Tooltip title="Move Down">
                    <div className="arrow-icon-calendar-workout-general ms-1 d-flex justify-content-center align-items-center">
                      <IconButton
                        disabled={isLastEventOnDate(workout._def.extendedProps)}
                      >
                        <KeyboardArrowDownIcon
                          sx={{ width: 17, height: 17 }}
                          // onClick={() =>
                          //   handleCopyExercise(workout._def.extendedProps)
                          // }
                          onClick={() =>
                            moveEventDown(workout._def.extendedProps)
                          }
                        />
                      </IconButton>
                    </div>
                  </Tooltip>{" "}
                </div>
              </div>
            )}
          </div>
        )}

        <div onMouseEnter={handleMouseEnter}>
          <div
            className="calendar-workout-card-title"
            style={{
              backgroundColor: workout._def.extendedProps.workout_color,
            }}
            onMouseEnter={handleMouseEnter}
          >
            <div
              className="calendar-workout-card-program"
              onClick={() => handleUpdateIteration(workout._def.extendedProps)}
            >
              {workout._def.extendedProps?.workout_program?.title}
              {/* {workout._def.extendedProps?.order} */}
            </div>
            <div
              className="d-flex justify-content-between"
              onMouseEnter={handleMouseEnter}
            >
              <div
                className="calendar-workout-card-workout"
                onClick={() =>
                  handleUpdateIteration(workout._def.extendedProps)
                }
                onMouseEnter={handleMouseEnter}
              >
                {workout.title}
              </div>
              <div
                className="d-flex align-items-center"
                onMouseEnter={handleMouseEnter}
              >
                <Icon
                  onClick={() =>
                    handleUpdateIteration(workout._def.extendedProps)
                  }
                  icon={
                    workout._def.extendedProps.practice_type == "workout"
                      ? "mdi:gym"
                      : workout._def.extendedProps.practice_type == "food"
                      ? "fluent:food-24-filled"
                      : workout._def.extendedProps.practice_type == "mindset"
                      ? "flowbite:brain-outline"
                      : workout._def.extendedProps.practice_type == "video"
                      ? "lets-icons:video-fill"
                      : ""
                  }
                  className="workout-type-icon-container "
                />
                {!workout._def.extendedProps?.open ? (
                  <KeyboardArrowDownIcon
                    sx={{
                      cursor: "pointer",
                      opacity: 0.7,
                      fontSize: "20px",
                    }}
                    onClick={() => {
                      const list = [...events];
                      list[workout._def.extendedProps.index]["open"] = true;
                      setEvents(list);
                    }}
                  />
                ) : (
                  <KeyboardArrowUpIcon
                    sx={{
                      cursor: "pointer",
                      opacity: 0.7,
                      fontSize: "20px",
                    }}
                    onClick={() => {
                      const list = [...events];
                      list[workout._def.extendedProps.index]["open"] = false;
                      setEvents(list);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {workout._def.extendedProps?.open && (
            <div
              className="calendar-workout-card-exercises"
              onMouseEnter={handleMouseEnter}
            >
              <div
                className="calendar-workout-card-container py-1"
                onMouseEnter={handleMouseEnter}
                onClick={() =>
                  handleUpdateIteration(workout._def.extendedProps)
                }
              >
                {workout._def.extendedProps.practice_type == "video" ? (
                  <>
                    <div className="inner-div workout-calendar-exercise-card my-1">
                      {workout?._def?.extendedProps?.dumyImage ? (
                        <div
                          style={{
                            whiteSpace: "break-spaces",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            className="image-border"
                            src={workout?._def?.extendedProps?.dumyImage}
                            height="50"
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            whiteSpace: "break-spaces",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            className="image-border"
                            src={
                              s3baseUrl +
                              workout?._def?.extendedProps?.video_thumbnail
                                ?.thumbnail_1
                            }
                            height="50"
                          />
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {workout._def.extendedProps.workout_exercises.map(
                  (exercise, index) => {
                    if (exercise?.exercise_type == "something_else") {
                      return (
                        <div className="inner-div workout-calendar-exercise-card my-1">
                          <div
                            style={{
                              whiteSpace: "break-spaces",
                            }}
                          >
                            {exercise?.title}
                          </div>
                        </div>
                      );
                    } else if (
                      exercise?.exercise_type == "rounds_for_time" &&
                      exercise?.emom_amrap &&
                      exercise?.emom_amrap.length > 0
                    ) {
                      return (
                        <div className="workout-calendar-Superset-card inner-div my-1">
                          <div className="text-center">Rounds For Time</div>
                          {exercise?.emom_amrap?.map(
                            (interval, intervalIndex) => {
                              return (
                                <div className="my-1">
                                  <div style={{ fontSize: "8px" }}>
                                    Movements
                                  </div>
                                  {interval?.map((exerData) => {
                                    return (
                                      <div
                                        className="inner-div"
                                        style={{
                                          whiteSpace: "break-spaces",
                                          marginBottom: "3px",
                                        }}
                                      >
                                        {exerData?.exercise_info?.title}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    } else if (
                      exercise?.exercise_type == "reps_for_time" &&
                      exercise?.emom_amrap &&
                      exercise?.emom_amrap.length > 0
                    ) {
                      return (
                        <div className="workout-calendar-Superset-card inner-div my-1">
                          <div className="text-center">Reps For Time</div>
                          {exercise?.emom_amrap?.map(
                            (interval, intervalIndex) => {
                              return (
                                <div className="my-1">
                                  <div style={{ fontSize: "8px" }}>
                                    Movements
                                  </div>
                                  {interval?.map((exerData) => {
                                    return (
                                      <div
                                        className="inner-div"
                                        style={{
                                          whiteSpace: "break-spaces",
                                          marginBottom: "3px",
                                        }}
                                      >
                                        {exerData?.exercise_info?.title}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    } else if (
                      exercise?.exercise_type == "amrap" &&
                      exercise?.emom_amrap &&
                      exercise?.emom_amrap.length > 0
                    ) {
                      return (
                        <div className="workout-calendar-Superset-card inner-div my-1">
                          <div className="text-center">AMRAP</div>
                          {exercise?.emom_amrap?.map(
                            (interval, intervalIndex) => {
                              return (
                                <div className="my-1">
                                  <div style={{ fontSize: "8px" }}>
                                    Movements
                                  </div>
                                  {interval?.map((exerData) => {
                                    return (
                                      <div
                                        className="inner-div"
                                        style={{
                                          whiteSpace: "break-spaces",
                                          marginBottom: "3px",
                                          // marginBottom: "1px",
                                          // backgroundColor:
                                          //   "rgba(255, 255, 255,255)",
                                          // overflow: "hidden",
                                          // textOverflow: "ellipsis",
                                        }}
                                      >
                                        {exerData?.exercise_info?.title}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    } else if (
                      exercise?.exercise_type == "emom" &&
                      exercise?.emom_amrap &&
                      exercise?.emom_amrap.length > 0
                    ) {
                      return (
                        <div className="workout-calendar-Superset-card inner-div my-1">
                          <div className="text-center">EMOM</div>
                          {exercise?.emom_amrap?.map(
                            (interval, intervalIndex) => {
                              return (
                                <div className="my-1">
                                  <div style={{ fontSize: "8px" }}>
                                    Minute{" "}
                                    {getMinutesForInterval(
                                      index,
                                      intervalIndex
                                    )}
                                  </div>
                                  {interval?.map((exerData) => {
                                    return (
                                      <div
                                        className="inner-div"
                                        style={{
                                          whiteSpace: "break-spaces",
                                          marginBottom: "3px",
                                          // marginBottom: "1px",
                                          // backgroundColor:
                                          //   "rgba(255, 255, 255,255)",
                                          // overflow: "hidden",
                                          // textOverflow: "ellipsis",
                                        }}
                                      >
                                        {exerData?.exercise_info?.title}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    } else if (
                      exercise?.sub_exercises &&
                      exercise?.sub_exercises.length > 0
                    ) {
                      return (
                        <div className="workout-calendar-Superset-card inner-div my-1">
                          {exercise?.sub_exercises?.map(
                            (exerData, exerIndex) => {
                              return (
                                <div
                                  className="inner-div"
                                  style={{
                                    whiteSpace: "break-spaces",
                                    marginBottom: "3px",
                                    // overflow: "hidden",
                                    // textOverflow: "ellipsis",
                                  }}
                                >
                                  {exerData?.exercise_info?.title}
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    } else
                      return (
                        <div className="inner-div workout-calendar-exercise-card my-1">
                          <div
                            style={{
                              whiteSpace: "break-spaces",
                            }}
                          >
                            {exercise?.exercise_info?.title}
                          </div>
                        </div>
                      );
                  }
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  const renderEventContent = (eventInfo) => {
    return <EventCard workout={eventInfo.event} onDelete={handleDeleteEvent} />;
  };
  const getEventListing = async (workout, time_zone) => {
    let all_events = [];
    // moment.tz.setDefault(time_zone);
    if (workout.length > 0) {
      all_events = workout.map((workout, i) => {
        return {
          ...workout,
          title: workout.title,
          color: workout.program_info?.color,
          workout_color: workout.program_info?.color,
          workout_title: workout.title,
          workout_exercises: workout.exercises,
          workout_program: workout.program_info,
          status: workout.status,
          is_deleteAble: workout?.workout_loggers.length > 0 ? false : true,
          date: moment(workout.workout_date).format("YYYY-MM-DD"),
          index: i,
          open: true,
          editable: workout?.workout_loggers.length > 0 ? false : true,

          order: i,
          // start_date: moment(workout.start_date_time).format("YYYY-MM-DD"),
          // end_date: moment(workout.end_date_time).format("YYYY-MM-DD"),
          // start: get_start_date_end_time(workout.start_date_time),
          // end: get_start_date_end_time(workout.end_date_time),
          // start_time: get_start_end_time(workout.start_date_time),
          // end_time: get_start_end_time(workout.end_date_time),
        };
      });
    }

    setEvents(all_events);
  };
  const getEventListingAtAdd = async (workout, time_zone) => {
    setAddNewEvent(false);
    let all_events = [];
    // moment.tz.setDefault(time_zone);
    if (workout.length > 0) {
      all_events = workout.map((workout, i) => {
        return {
          ...workout,
          // title: workout.title,
          // color: workout.program_info?.color,
          // workout_color: workout.program_info?.color,
          // workout_title: workout.title,
          // workout_exercises: workout.exercises,
          // workout_program: workout.program_info,
          // status: workout.status,
          // is_deleteAble: workout?.workout_loggers.length > 0 ? false : true,
          // date: moment(workout.workout_date).format("YYYY-MM-DD"),
          index: i,
          order: i,
          // open: true,
          // editable: workout?.workout_loggers.length > 0 ? false : true,

          // order:
          //   workout?._id == "6638707fb939942319d6814d"
          //     ? 1
          //     : workout?._id == "66387078b939942319d68141"
          //     ? 8
          //     : workout?._id == "663870aeb939942319d681d8"
          //     ? 2
          //     : i,
          // start_date: moment(workout.start_date_time).format("YYYY-MM-DD"),
          // end_date: moment(workout.end_date_time).format("YYYY-MM-DD"),
          // start: get_start_date_end_time(workout.start_date_time),
          // end: get_start_date_end_time(workout.end_date_time),
          // start_time: get_start_end_time(workout.start_date_time),
          // end_time: get_start_end_time(workout.end_date_time),
        };
      });
    }
    setEvents(all_events);
  };

  const TOOL_BUTTONS = [
    {
      text: "month",
    },
    {
      text: "week",
    },
    {
      text: "day",
    },
  ];

  const getCenteredText = () => {
    let text = moment(new Date()).format("MMM YYYY");
    if (selectedTool == "month") {
      if (calendarRef?.current) {
        const calendarApi = calendarRef?.current.getApi();
        const currentMonth = calendarApi.getDate().getMonth(); // Get the current month (0-based index)
        const currentYear = calendarApi.getDate().getFullYear(); // Get the current year

        text = moment().month(currentMonth).format("MMM") + " " + currentYear;
      }
      if (
        moment(new Date()).isBetween(
          currentDate.start_date,
          currentDate.end_date,
          null,
          "[)"
        )
      ) {
      }
    } else if (selectedTool == "week") {
      let month = moment(currentDate.start_date).format("MMM");
      let start_date = moment(currentDate.start_date).format("DD");
      let end_date = moment(currentDate.end_date).format("DD");
      let year = moment(currentDate.start_date).format("YYYY");
      text = `${month} ${start_date} - ${end_date} ${year}`;
    } else {
      text = moment(currentDate.start_date).format("MMM DD, YYYY");
    }
    return text;
  };

  const hanldeChangeDates = (value) => {
    let start_date = currentDate.start_date;
    let end_date = currentDate.end_date;

    if (calendarRef?.current) {
      const calendarApi = calendarRef?.current.getApi();

      const currentView = calendarApi.view;
      const startDate = currentView.activeStart;
      const endDate = currentView.activeEnd;
      start_date = startDate;
      end_date = endDate;
      console.log("Start date of the current month:", startDate, endDate);
    }
    let new_object = {};
    if (value == "next") {
      new_object = {
        start_date: moment(start_date),
        end_date: moment(end_date),
      };
    } else {
      new_object = {
        start_date: moment(start_date),
        end_date: moment(end_date),
      };
    }
    setCurrentDate(new_object);
  };

  const handleChangeNextPrev = (value) => {
    if (selectedTool == "month") {
      let classToClick = "fc-next-button";
      if (value == "prev") {
        classToClick = "fc-prev-button";
      }
      let click_element = document.getElementsByClassName(classToClick)[0];
      click_element.click();
    }
    hanldeChangeDates(value);
  };

  const handleClickDate = (workout) => {
    setSelectedTool("day");
    setCurrentDate({
      start_date: workout.dateStr,
      end_date: workout.dateStr,
    });
  };
  const handleEventDrop = (info) => {
    const { event, oldEvent, view } = info;

    // Access the dropped date using the view's activeStart property
    const dropDate = view.calendar.formatDate(event.start, {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    // Access the dropped event's data
    const eventData = event.extendedProps; // assuming you have extendedProps with data
    setOpenDragConfig(true);
    setDragConfigDate(event.start);
    setDragConfigData(eventData);
  };
  const renderDayCell = (arg) => {
    // Render custom content for each date cell

    const isHovered =
      hoveredDate && arg.date.getTime() === hoveredDate.getTime();
    //  const { dayNumberText, day } = eventInfo;

    return (
      <div
        style={{
          position: "relative",
          height: "100%",
          marginTop: -10,
        }}
      >
        <span>{arg?.dayNumberText}</span>{" "}
        <span
          className="day-cell-name"
          style={{
            opacity: 0.5,
            fontSize: 10,
          }}
        >
          {moment(arg?.date).format("ddd")}
        </span>
        {isHovered && (
          <div className="cell-icon-workout d-flex text-end w-100">
            {dataCopy && (
              <Tooltip touch="on" placement="bottom" title={"Paste"}>
                <div className="paste-icon-workout-general me-1">
                  <AssignmentTurnedInIcon
                    onClick={() => handlePaste(arg?.date)}
                    sx={{
                      cursor: "pointer",
                      height: 15,
                      width: 15,
                      mb: "2px",
                      mr: "2px",
                    }}
                  />
                </div>
              </Tooltip>
            )}

            <AddIcon
              className="add-icon-workout-general "
              onClick={() => handleOpenDrawer(arg?.date)}
              sx={{
                cursor: "pointer",
              }}
            />
          </div>
        )}
        {/* <AddIcon className="add-icon-workout" /> */}
      </div>
    );
  };

  const goToToday = () => {
    if (calendarRef?.current) {
      const calendarApi = calendarRef.current.getApi();
      const today = new Date();

      calendarApi.gotoDate(today);
      const newDateRange = {
        start_date: calendarApi.view.activeStart,
        end_date: calendarApi.view.activeEnd,
      };
      setCurrentDate(newDateRange);
      setTimeout(() => {
        const todayDate = today.toISOString().slice(0, 10);
        const todayCell = document.querySelector(
          `.fc-day[data-date="${todayDate}"]`
        );
        if (todayCell) {
          todayCell.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 1000);
    }
  };

  useEffect(() => {
    let start_date = currentDate.start_date;
    let end_date = currentDate.end_date;

    if (calendarRef?.current) {
      const calendarApi = calendarRef?.current.getApi();

      const currentView = calendarApi.view;
      const startDate = currentView.activeStart;
      const endDate = currentView.activeEnd;
      start_date = startDate;
      end_date = endDate;
    }
  }, [calendarRef]);

  function getNewDates(refDate, inputDate) {
    const dateDifferences = [];
    const referenceDates = refDate
      .map((date) => new Date(date.split("/").reverse().join("-")))
      .sort((a, b) => a - b);

    for (let i = 1; i < referenceDates.length; i++) {
      const diffInDays =
        (referenceDates[i] - referenceDates[i - 1]) / (1000 * 60 * 60 * 24);
      dateDifferences.push(diffInDays);
    }

    const newDates = [new Date(inputDate.split("/").reverse().join("-"))];

    for (let diff of dateDifferences) {
      const nextDate = new Date(newDates[newDates.length - 1]);
      nextDate.setDate(nextDate.getDate() + diff);
      newDates.push(nextDate);
    }

    newDates.sort((a, b) => a - b);

    return newDates.map((date) =>
      date.toLocaleDateString("en-GB").split("/").join("/")
    );
  }

  const eventOrderFunction = (a, b) => {
    return a.order - b.order;
  };

  useEffect(() => {
    if (!hoverdInfo) return;
    const cellDate = moment(hoverdInfo?.date).format("DD/MM/YYYY");
    const dates = selectedWorkouts.map((workout) => {
      return moment(workout.workout_date).format("DD/MM/YYYY");
    });

    let newDates = [];
    if (dates.length > 0 && cellDate) {
      newDates = getNewDates(dates, cellDate);
    }
    document.querySelectorAll(".fc-daygrid-day").forEach((cell) => {
      cell.classList.remove("highlighted-hover");
    });
    if (newDates.length > 0) {
      document.querySelectorAll(".fc-daygrid-day").forEach((cell) => {
        const cellDate = moment(cell.getAttribute("data-date")).format(
          "DD/MM/YYYY"
        );

        if (newDates.includes(cellDate)) {
          cell.classList.add("highlighted-hover");
        }
      });
    }
  }, [hoverdInfo, selectedWorkouts]);

  let width_cal = 0;
  const screenWidth = window.innerWidth;

  const element = document.querySelector(".simplebar-wrapper");
  if (element) {
    width_cal = screenWidth - element.offsetWidth - 36;
  } else {
    width_cal = screenWidth;
  }

  let height_cal = 0;

  if (rowRef.current) {
    height_cal = rowRef.current.offsetHeight - 12;
  }

  const MENU_OPTIONS = [
    {
      label: "Delete Workouts",
      icon: "mdi:dumbbell",
      handleClick: handleOpenMultiDelete,
    },
    {
      label: "Add To programme/Member",
      icon: "mdi:account-plus",
      handleClick: handleProgrammeMember,
    },
  ];

  useEffect(() => {
    let timeout;
    if (!isLoading && calendarRef?.current) {
      timeout = setTimeout(() => {
        const today = new Date().toISOString().slice(0, 10);
        const todayCell = document.querySelector(
          `.fc-day[data-date="${today}"]`
        );
        if (todayCell) {
          todayCell.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 500);
    }
    return () => clearTimeout(timeout);
  }, [isLoading]);

  useEffect(() => {
    getEventListing(programWorkoutList, time_zone);
  }, [programWorkoutList]);

  useEffect(() => {
    setEffectedMembers(programme?.members);
  }, [programme?.members]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid full-calendar-toolbar">
      <div
        className="row fixed_top"
        style={{ width: `${width_cal}px` }}
        ref={rowRef}
      >
        <div className="col-12 col-md-9">
          <h2 className="font-size-on-sm text-nowrap-md-up">
            <IconButton
              className="back-screen-button mb-0 me-2"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            {calendar_title}
          </h2>
        </div>
        <div className="col-12 col-md-3 text-end">
          <div className="d-flex justify-content-end align-items-center">
            {selectedWorkouts.length > 0 && (
              <>
                <Tooltip title="Delete Workouts">
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    className="me-1"
                    onClick={handleOpenMultiDelete}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
                <Tooltip title="Copy Workouts to Programme/Member">
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className="me-1"
                    onClick={handleProgrammeMember}
                  >
                    <ContentPasteIcon />
                  </Button>
                </Tooltip>
              </>
            )}
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleOpen}
            >
              Notepad
            </Button>
          </div>
        </div>
        <div className="col-4 mt-2 text-start">
          {!hideArrowsAndButtons && (
            <div className="btn-group toollbar-group-buttons group-buttons-font-size-on-sm">
              <button
                className={`small-contained-button`}
                onClick={() => handleChangeNextPrev("prev")}
              >
                <ArrowBackIosIcon />
              </button>
              <button
                className={`small-contained-button`}
                onClick={() => handleChangeNextPrev("next")}
              >
                <ArrowForwardIosIcon />
              </button>
            </div>
          )}
        </div>
        <div className="col-4 mt-2 text-center">
          <h3 className="font-size-on-sm mb-0">{getCenteredText()}</h3>
        </div>
        <div className="col-4 mt-2 d-flex justify-content-end">
          <div className="group-buttons-font-size-on-sm">
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => goToToday()}
            >
              Today
            </Button>
          </div>
          <div className="ms-3">
            {programme.difficulty_level && (
              <FormControl sx={{ minWidth: 150 }} size="small">
                <InputLabel id="demo-simple-select-label">
                  Difficulty Levels
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  multiple
                  name="difficulty_levels"
                  value={difficultyLevel}
                  label="Difficulty Levels"
                  onChange={(e) => {
                    setDifficultyLevel(e.target.value);
                  }}
                >
                  <MenuItem value="novice">Novice</MenuItem>
                  <MenuItem value="intermediate">Intermediate</MenuItem>
                  <MenuItem value="advanced">Advanced</MenuItem>
                </Select>
              </FormControl>
            )}
          </div>
        </div>
      </div>
      <div className="row" style={{ paddingTop: `${height_cal}px` }}>
        <div
          className="full-calendar full-calendar-new-flow mt-3"
          onMouseLeave={() => setHoveredDate(null)}
        >
          {selectedTool === "month" ? (
            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: "today prev,next",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              className="fc-day-sun fc-toolbar-chunk fc eventimage fc-col-header-cell-cushion fc-today-button fc-right fc-prev-button fc-right fc-next-button fc-dayGridMonth-button fc-timeGridWeek-button fc-timeGridDay-button fc-daygrid-day-number fc-daygrid-day-top"
              eventContent={renderEventContent}
              events={events}
              initialView="dayGridMonth"
              dayCellContent={renderDayCell}
              eventDrop={handleEventDrop}
              editable={true}
              selectable={true}
              selectMirror={true}
              eventOrder={eventOrderFunction}
              weekends={true}
              fixedWeekCount={false}
              moreLinkContent={function (args) {
                return "+" + args.num;
              }}
              moreLinkClick={function (args) {
                handleClickDate(args);
              }}
              dayCellDidMount={(arg) => {
                const cell = arg.el;
                cell.addEventListener("mouseover", () => {
                  handleCellHover(arg);
                });
                cell.addEventListener("mouseout", () => {
                  handleCellUnhover(arg);
                });
              }}
            />
          ) : selectedTool === "week" ? (
            <WeekCalendar
              events={events}
              handleClick={eventDetail}
              currentDate={currentDate}
              isLoading={isLoading}
            />
          ) : (
            <DayCalendar
              events={events}
              handleClick={eventDetail}
              currentDate={currentDate}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
      <DetailPopUpModel
        open={popupState}
        setOpen={setPopupState}
        handleAgreeDelete={handleAgreeDelete}
        handleEdit={handleUpdate}
        handleEditIteration={handleUpdateIteration}
        title={"Event Detail"}
        eventDetailData={eventDetailData}
        componentToPassDown={<></>}
      />
      <ConfirmationWithDescription
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to Delete?"}
        descriotion={`If you take action it will delete from ${effectedMembers} users.`}
        handleAgree={handleDelete}
      />
      <ConfirmationWithDescription
        open={openMultiDelete}
        setOpen={setOpenMultiDelete}
        title={"Are you sure you want to Delete?"}
        descriotion=""
        handleAgree={handleDeleteMultipul}
      />

      <ConfirmationWithDescription
        open={openDragConfig}
        setOpen={closeDragConfig}
        title={"Do you want to take this action?"}
        descriotion={`It will impact ${effectedMembers} users, who has access to this program.`}
        handleAgree={handleDraged}
        isLoading={isPasted}
      />
      <ConfirmationWithDescription
        open={openCopyPasteConfig}
        setOpen={closeCopyPasteConfig}
        title={"Do you want to paste it here?"}
        descriotion={`It will impact ${effectedMembers} users, who has access to this program.`}
        handleAgree={handleCopyPaste}
        isLoading={isPasted}
      />
      <FullPagePopup
        open={editDrawerState}
        setOpen={setEditDrawerState}
        componentToPassDown={<></>}
      />
      <AddWorkoutPopup
        open={drawerState}
        setAddNewEvent={setAddNewEvent}
        setOpen={setDrawerState}
        date={selectedDate}
        programme={programme}
        reloadList={reloadList}
        setEditWorkputData={setEditWorkputData}
        setEvents={setEvents}
        events={events}
      />
      <EditWorkoutPopup
        open={editItrationDrawerState}
        setOpen={setEditItrationDrawerState}
        date={selectedDate}
        programme={programme}
        data={editWorkputData}
        reloadList={reloadList}
        setEvents={setEvents}
        events={events}
      />
      <Dialog
        open={open}
        disableEnforceFocus={true}
        onClose={handleClose}
        maxWidth={false} // Disable maxWidth to allow full control over width
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            width: "50vw",
            maxWidth: "none",
            height: "90vh",
          },
        }}
      >
        <div className="close_custom_icon" onClick={handleClose}>
          <CloseIcon
            style={{
              fill: "var(--portal-theme-primary)",
              fontSize: 18,
              marginTop: 6,
            }}
          />
        </div>
        <DialogTitle sx={{ textAlign: "center" }}>
          {" "}
          Coach note for {calendar_title}{" "}
        </DialogTitle>
        <DialogContent
          sx={{
            height: "calc(100% - 64px)", // Adjust height to fill dialog (64px for title & actions)
            overflowY: "auto",
          }}
        >
          <div className="editor-container">
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="description"
              editorHeight={450}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddNote} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <CustomPopover
        isOpenPop={isPastedWorkouts}
        isClosePop={setIsPastedWorkouts}
        title="Add To Programme/Member"
        componentToPassDown={
          <div className="px-4 pb-3">
            <PastedWorkouts
              selectedWorkouts={selectedWorkouts}
              setSelectedWorkouts={setSelectedWorkouts}
              setDataCopy={setDataCopy}
              setIsPastedWorkouts={setIsPastedWorkouts}
            />
          </div>
        }
      />
    </div>
  );
}

export default GeneralCalendar;
