import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";

const NotePopup = ({ inputs, setInputs, open, handleClose }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        disableEnforceFocus={true}
        maxWidth={false}
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            width: "50vw",
            maxWidth: "none",
            height: "90vh",
          },
        }}
      >
        <div className="close_custom_icon" onClick={handleClose}>
          <CloseIcon
            style={{
              fill: "var(--portal-theme-primary)",
              fontSize: 18,
              marginTop: 6,
            }}
          />
        </div>
        <DialogTitle sx={{ textAlign: "center" }}> Coach Note</DialogTitle>
        <DialogContent
          sx={{
            height: "calc(100% - 64px)",
            overflowY: "auto",
          }}
        >
          <div className="editor-container">
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="coach_notes"
              editorHeight={400}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NotePopup;
