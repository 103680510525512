import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import roundFilterList from "@iconify/icons-ic/round-filter-list";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { get_root_value } from "src/utils/domUtils";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
} from "@mui/material";
// components
// import Page from "../components/Page";
// import Label from "./Label";
// import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "src/components/NotFound/SearchNotFound";
import DeletedModal from "src/components/ModalPopover/DeleteModal";
import Label from "src/components/Label";
import { UserListHead } from "src/components/_dashboard/user";
// import UserListHead from "./UserListHead";
// import GoalStatementMenu from "../components/_dashboard/user/GoalStatementMenu";
// import { QuestionListing } from "src/DAL/GoalStatement/GoalStatement";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { project_url, s3baseUrl } from "src/config/config";
import {
  AllActiveWorkoutTypeListingApi,
  WorkoutTypeListingApi,
  deleteWorkoutTypeApi,
} from "src/DAL/workoutType/workoutType";
import UserListToolbars from "src/components/_dashboard/user/UserListToolbars";
import { Pages } from "@mui/icons-material";
import { WorkoutListingApi, deleteWorkouteApi } from "src/DAL/workout/workout";
import ModalExample from "./AddModel";
import moment from "moment";
import WorkoutAssignMemberModel from "./components/WorkoutAssignMemberModel";
import WorkoutAssignProgramModel from "./components/WorkoutAssignProgramModel";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import WorkoutFilter from "./WorkoutFilterNew";

//
// import USERLIST from "../_mocks_/user";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false },

  { id: "number", label: "#", alignRight: false },
  { id: "title", label: " Name", alignRight: false },
  { id: "date", label: "Date created", alignRight: false },

  { id: "status", label: "Status", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user?.fullName?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
const USERLIST = [
  {
    id: 1,
    question: "Current Average Working Hours Per Week?",
    avatarUrl: "/static/mock-images/avatars/avatar_3.jpg",
    status: "active",
  },
];
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
let dumyData = [
  {
    id: 1,
    title: "Cycling - health benefits",
    description:
      "Cycling can help to protect you from serious diseases such as stroke, heart attack, some cancers, depression, diabetes, obesity and arthritis.",
  },
  {
    id: 2,
    title: "Weight Training: Exercises, Safety, and More",
    description:
      "Strength training isn't just about vanity. It can help control weight, stop bone loss, improve balance, and boost energy levels.",
  },
];
const tabs = {
  all: "",
  asc: "asc",
  desc: "desc",
  trash: "trash",
};
export default function WorkoutListing() {
  const navigate = useNavigate();
  const params = useLocation();
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState(tabs.asc);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("number");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const [questionData, setQuestionData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [member, setData] = useState([]);
  const [workout, setWorkout] = useState([]);
  const [UserId, setUserId] = useState(false);
  const [workoutType, setWorkoutType] = useState([]);
  const [openFor, setOpenFor] = useState("Add");

  const [dataForEdit, setDataForEdit] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [assignMeemberModalOpen, setAssignMeemberModalOpen] = useState(false);
  const [assignProgramModalOpen, setAssignProgramModalOpen] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const EMPTY_FILTER = {
    status: "",
    workout_type: null,
  };

  const [filterState, setFilterState] = useState(EMPTY_FILTER);
  // const [filterStateUpdated, setFilterStateUpdated] = useState();
  const [updatedFilter, setUpdatedFilter] = useState(false);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);
  const [modalData, setModalData] = useState();
  const searchFunction = () => {
    setPage(0);
    // setPageCount(1);
    filterState.search_text = filterName;
    getWorkoutList(filterState);
    localStorage.setItem("WorkoutListSearch", JSON.stringify(filterState));
    handleCloseFilterDrawer();
    setIsLoading(true);
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    // setPageCount(1);

    getWorkoutList(data);
    setFilterState(data);
    // localStorage.setItem("WorkoutListSearch", JSON.stringify(data));
  };

  const handleClearFilter = () => {
    let clear_data = { ...EMPTY_FILTER };

    setPage(0);
    // setPageCount(1);
    setFilterName("");
    setFilterState(clear_data);

    getWorkoutList(clear_data);
    handleCloseFilterDrawer();
    setIsLoading(true);
    localStorage.removeItem("WorkoutListSearch");
  };
  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);

    setFilterState(filterStateUpdated);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };
  const handleOpen = (e) => {
    setUserId(e);
    setOpen(true);
  };

  const handleRequestSort = (event, property) => {
    // const isAsc = orderBy === property && order === "asc";
    // setOrder(isAsc ? "desc" : "asc");
    // setOrderBy(property);
  };

  const getWorkoutTypeListing = async () => {
    setIsLoading(true);

    try {
      setIsLoading(true);
      const result = await WorkoutTypeListingApi(page, rowsPerPage, filterName);
      if (result.code === 200) {
        console.log(result, "dfkjsdahaf");
        setData(result.workout_types);
        setIsLoading(false);

        setQuestionData(result.workout_types);

        setUserList(result.workout_types);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };
  // const handleEdit = (value) => {
  //   console.log("asdlfhakj", value);

  //   navigate(`/workout-edit/${value?.workout_slug}`, {
  //     state: value,
  //   });
  // };
  const handleEdit = (value) => {
    console.log("asdlfhakj", value);
    setOpenFor("Edit");
    setDataForEdit(value);
    setModalOpen(true);
  };
  const handleEditWorkout = (value) => {
    console.log("asdlfhakj", value);

    navigate(`/workout-edit/${value?.workout_slug}`, {
      state: value,
    });
  };
  const handleChangePassword = (value) => {
    navigate(`/coach/change-password/${value.id}`, {
      state: value,
    });
  };
  const handleChangeRecording = (value) => {
    navigate(`/consultant/recordings/${value.id}`, {
      state: value,
    });
  };
  const handleChangeGroup = (value) => {
    navigate(`/consultant/groups/${value.id}`, {
      state: value,
    });
  };
  const handleChangePods = (value) => {
    navigate(`/consultant/pods-list/${value.id}`, {
      state: value,
    });
  };
  const handleChangeMember = (value) => {
    navigate(`/consultant/member-list/${value.id}`, {
      state: value,
    });
  };
  const handleChangeGoalStatement = (value) => {
    navigate(`/consultant/goal-statement-list/${value.id}`, {
      state: value,
    });
  };
  const handleChangeEvents = (value) => {
    navigate(`/consultant/events/${value.id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleAssignMember = (value) => {
    setAssignMeemberModalOpen(true);
    setModalData(value);
  };
  const handleAssignProgram = (value) => {
    setAssignProgramModalOpen(true);
    setModalData(value);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteWorkouteApi(deleteDoc.workout_slug);
    // const result = await deleteWorkoutTypeApi(deleteDoc.workout_type_slug);
    if (result.code === 200) {
      getWorkoutList();
      // setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleNavigate = () => {
    navigate(`/type-workout-add`);
  };
  const handleNavigateMember = (e) => {
    console.log(e, "eeeeeee");
    navigate(`/coach/add-coach`);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - workout.length) : 0;

  const filteredUsers = applySortFilter(
    workout,
    getComparator(order, orderBy),
    filterName
  );

  // useEffect(() => {
  //   getWorkoutTypeListing();
  // }, [Pages, rowsPerPage]);
  const getWorkoutList = async (filterData) => {
    let postData = {
      workout_type: filterData?.workout_type
        ? filterData?.workout_type._id
        : "",
      status: filterData?.status ? filterData?.status : "",
      practice_type: "workout",
    };
    setIsLoading(true);
    console.log(postData, "kdsjfhkdsa");
    let result = await WorkoutListingApi(
      page,
      rowsPerPage,
      filterName,
      postData,
      selectedTab
    );
    if (result.code == 200) {
      setFilterCount((values) => 0);

      if (filterData?.status) {
        setFilterCount((values) => values + 1);
      }
      if (filterData?.workout_type) {
        setFilterCount((values) => values + 1);
      }
      let chipData = filterData;
      if (chipData?.search_text) {
        delete chipData.search_text;
      }

      setFilterStateUpdated(chipData);
      setIsLoading(false);
      setTotalCount(result?.total_workouts);
      setWorkout(result?.workouts);
      // setTotalPages(result?.total_pages);
    }
  };
  const getWorkoutTypes = async () => {
    const practice_type = "workout";

    try {
      const result = await AllActiveWorkoutTypeListingApi(practice_type);
      if (result.code === 200) {
        setWorkoutType(result?.workout_types);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {}
  };
  useEffect(() => {
    // const timer = setTimeout(() => {
    //   // Call your function here after the delay
    //   getWorkoutList();
    // }, 500); // 500 milliseconds (half a second)

    // return () => {
    //   // Clear the timer if the component unmounts before the timeout
    //   clearTimeout(timer);
    // };
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("WorkoutListSearch");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setFilterName(filter_data.search_text);
      }
    }
    setFilterState(filter_data);
    setFilterStateUpdated(filter_data);
    //     getMemberListing(filter_data);
    // filterState.search_text = filterName;

    getWorkoutList(filter_data);
  }, [page, rowsPerPage, selectedTab]);
  useEffect(() => {
    getWorkoutTypes();
    localStorage.removeItem("WorkoutExerciseListSearch");
  }, []);
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Change Password",
      icon: "ant-design:lock",
      handleClick: handleChangePassword,
    },
    {
      label: "View Recordings",
      icon: "fluent:video-recording-20-regular",
      handleClick: handleChangeRecording,
    },
    {
      label: "View Groups",
      icon: "fluent:video-recording-20-regular",
      handleClick: handleChangeGroup,
    },
    {
      label: "View Pods",
      icon: "fluent:video-recording-20-regular",
      handleClick: handleChangePods,
    },
    {
      label: "View Members",
      icon: "fluent:video-recording-20-regular",
      handleClick: handleChangeMember,
    },
    {
      label: "View Events",
      icon: "fluent:video-recording-20-regular",
      handleClick: handleChangeEvents,
    },
    {
      label: "View Goal Statement",
      icon: "fluent:video-recording-20-regular",
      handleClick: handleChangeGoalStatement,
    },
  ];
  const MENU_OPTIONS1 = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Edit Fitness",
      icon: "akar-icons:edit",
      handleClick: handleEditWorkout,
    },

    {
      label: "Add to Member",
      icon: "mdi:person",
      handleClick: handleAssignMember,
    },
    {
      label: "Add to Program",
      icon: "eva:bar-chart-fill",
      handleClick: handleAssignProgram,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  const MENU_OPTIONS2 = [
    {
      label: "Add to Member",
      icon: "mdi:person",
      handleClick: handleAssignMember,
    },
    {
      label: "Add to Program",
      icon: "eva:bar-chart-fill",
      handleClick: handleAssignProgram,
    },
  ];
  const isUserNotFound = filteredUsers.length === 0;
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  // console.log(selected, "How data is working ");
  return (
    <>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <WorkoutAssignMemberModel
        open={assignMeemberModalOpen}
        setOpen={setAssignMeemberModalOpen}
        data={modalData}
      />
      <WorkoutAssignProgramModel
        open={assignProgramModalOpen}
        setOpen={setAssignProgramModalOpen}
        data={modalData}
      />
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <WorkoutFilter
            filterData={filterState}
            setFilterData={setFilterState}
            searchSubmitFilter={searchFunction}
            handleClearFilter={handleClearFilter}
            workoutTypes={workoutType}
          />
        }
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <h2>Fitness </h2>
          </div>
          <div className="col-lg-4 col-sm-12 d-flex justify-content-end">
            <button
              className="small-contained-button me-1 "
              onClick={handleOpenFilterDrawer}
              style={{ position: "relative" }}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
              <span
                // className="small-contained-button"
                style={{
                  position: "absolute",
                  bottom: "23px",
                  right: "9px",
                }}
              >
                {filterCount ? (
                  <div className="dot-filter-count">{filterCount}</div>
                ) : (
                  <div></div>
                )}
              </span>
            </button>
            <ModalExample
              open={modalOpen}
              setOpen={setModalOpen}
              workoutTypes={workoutType}
              openFor={openFor}
              data={dataForEdit}
              setOpenFor={setOpenFor}
              searchFunction={searchFunction}
            />
          </div>
        </div>
        {/* <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton> */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={5}
          mb={1}
        >
          <div className="d-flex">
            <div className="me-2 pointer">
              <Label
                onClick={() => {
                  setSelectedTab(tabs.asc);
                  setPage(0);
                }}
                // onClick={() => console.log("fksjdfha")}
                variant={selectedTab === tabs.asc ? "ghost" : ""}
                style={{ width: 50, cursor: "pointer" }}
                color="info"
              >
                A-Z
              </Label>
            </div>
            <div className="me-2 pointer">
              <Label
                onClick={() => {
                  setSelectedTab(tabs.desc);
                  setPage(0);
                }}
                variant={selectedTab === tabs.desc ? "ghost" : ""}
                style={{ width: 50, cursor: "pointer" }}
                color="info"
              >
                Z-A
              </Label>
            </div>
          </div>
          {/* <Button
            className=""
            onClick={handleOpenAdd}
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
          >
            Create Support Ticket
          </Button> */}
        </Stack>
        <Card style={{ overflowX: "auto" }}>
          <UserListToolbars
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            onSearch={() => getWorkoutList()}
          />

          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={workout.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                // onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    const {
                      id,
                      name,
                      status,
                      title,
                      description,
                      image,
                      createdAt,
                      action_by,
                    } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell className="action-in-table-width">
                          {action_by === "coach_user" ? (
                            <CustomPopoverSection
                              menu={MENU_OPTIONS1}
                              data={row}
                            />
                          ) : (
                            <CustomPopoverSection
                              menu={MENU_OPTIONS2}
                              data={row}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="action-in-table-width"
                        >
                          {page * rowsPerPage + (i + 1)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <div>
                              <Avatar
                                variant="rounded"
                                sx={{ height: 60, width: 60 }}
                                src={s3baseUrl + image?.thumbnail_1}
                                alt={Capitalize(title)}
                              />
                            </div>
                            <div style={{ fontSize: 11 }}>
                              <h6 className="h2-heading ">
                                {Capitalize(title)}
                              </h6>

                              {Capitalize(htmlDecode(description)).substring(
                                0,
                                250
                              ) + "..."}
                            </div>
                          </Stack>
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {moment(createdAt).format("DD-MM-YYYY")}
                        </TableCell>

                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={status === false ? "error" : "success"}
                          >
                            {status === false ? "InActive" : "Active"}
                          </Label>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[50, 100, 150]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </div>
    </>
  );
}
