import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText, Input, InputLabel } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "src/config/config";
import { EditWorkoutApi } from "src/DAL/workout/workout";
function ModalExample({
  open,
  setOpen,
  workoutTypes,
  openFor,
  setOpenFor,
  data,
  searchFunction,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("Pending");
  const [isLoading, setIsLoading] = useState(true);
  const [addArray, setaddArray] = useState([]);
  const [file, setProfileImage] = React.useState();
  const [oldImage, setOldImage] = React.useState();

  const navigate = useNavigate();
  const [inputs, setInputs] = React.useState({
    title: "",
    status: true,
    programStatus: "true",
    image: {},
    type: "",
    videoUrl: "",
    short_description: "",
    detailed_description: "",
    video_duration: new Date("00"),
    difficulty_levels: [],
  });
  const handleOpen = () => {
    setOpen(true);
  };
  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };
  const handleClose = () => {
    setOpenFor("Add");
    setOldImage("");
    setIsLoading(false);
    setOpen(false);
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = async () => {
    // Add your logic to save the data (title, description, and status) here
    if (
      inputs.title &&
      (openFor == "Edit" ? oldImage : inputs.image) &&
      inputs.type &&
      inputs.short_description &&
      inputs.difficulty_levels.length > 0
    ) {
      if (openFor == "Edit") {
        setIsLoading(true);
        console.log(data.exercises, "lsdka");

        let exerciesArray = addArray.map((item, mainIn) => {
          console.log(item, "sdkfljkajs");
          if (
            item.type != "emom" &&
            item.type != "amrap" &&
            item.type != "something_else" &&
            !item.exercise?._id &&
            item?.superSet?.length == 0
          ) {
            enqueueSnackbar("please select an exercise", { variant: "error" });
            isError = true;
          }
          if (item.type == "something_else" && !item?.title) {
            enqueueSnackbar("please add name", {
              variant: "error",
            });
            isError = true;
          }
          let data = {
            exercise_info: {
              _id: item?.superSet[0]?.exercise?._id
                ? item?.superSet[0]?.exercise?._id
                : item.exercise?._id,
              title: item?.superSet[0]?.exercise?.title
                ? item?.superSet[0]?.exercise?.title
                : item.exercise?.title,
              image: item?.superSet[0]?.exercise?.image
                ? item?.superSet[0]?.exercise?.image
                : item.exercise?.image,
            },
            title: item?.title,
            description: item.description,
            parameters: item.parameters,
          };
          if (item?._id) {
            data["_id"] = item?._id;
          }
          if (item.superset_sets) {
            data["superset_sets"] = item.superset_sets;
          }
          if (item.rounds) {
            data["emom_rounds"] = item.rounds;
          }
          if (item.type) {
            data["exercise_type"] = item.type;
          }
          if (item.time_interval) {
            data["interval_duration"] = item.time_interval;
          }
          if (item.set) {
            data["sets"] = [];
            if (item.set.length > 0) {
              data["sets"] = item.set.map((item) => {
                console.log("dskjfjksjdklfja", item);
                let data = {};
                if (item.vdot) {
                  data["vdot"] = item.vdot;
                }
                if (item.race_type) {
                  data["race_type"] = item.race_type;
                }
                if (item.pace_type) {
                  data["pace_type"] = item.pace_type;
                }
                if (item.pace) {
                  data["pace_low"] = item.pace;
                }
                if (item.paceUp) {
                  data["pace_up"] = item.paceUp;
                }
                if (item.optionsType) {
                  data["pace_options"] = item.optionsType;
                }
                if (item.mhr) {
                  data["mhr"] = item.mhr;
                }
                if (item.reps) {
                  data["reps"] = item.reps;
                }
                if (item.rest) {
                  data["rest"] = item.rest;
                }
                if (item.weight) {
                  data["weight"] = item.weight;
                }
                if (item.time) {
                  data["duration"] = item.time;
                }
                if (item.weightType) {
                  data["weight_unit"] = item.weightType;
                }

                if (item.distance) {
                  data["distance"] = item.distance;
                }
                if (item.distanceType) {
                  data["distance_unit"] = item.distanceType;
                }
                if (item.timeType) {
                  data["duration_unit"] = item.timeType;
                }
                if (item.restType) {
                  data["rest_unit"] = item.restType;
                }
                if (item.tempo) {
                  data["tempo"] = item.tempo;
                }
                return data;
              });
            }
          }
          if (item.type == "emom" || item.type == "amrap") {
            data["emom_amrap"] = [];
            if (item.emomSets.length > 0) {
              item.emomSets.map((item1, ind) => {
                console.log(item1, "sjdkfksjdka");
                if (item1.length > 0) {
                  data["emom_amrap"][ind] = item1.map((item2) => {
                    if (isError) {
                      return;
                    }
                    if (!item2.exercise?._id) {
                      if (item.type == "emom") {
                        enqueueSnackbar(
                          `please select an exercise in EMOM,  EMOM placed on ${
                            mainIn + 1
                          } position. `,
                          {
                            variant: "error",
                          }
                        );
                      }
                      if (item.type == "amrap") {
                        enqueueSnackbar(
                          `please select an exercise in AMRAP, AMRAP placed on ${
                            mainIn + 1
                          } position. `,
                          {
                            variant: "error",
                          }
                        );
                      }

                      isError = true;

                      return;
                    }
                    let data = {
                      exercise_info: {
                        _id: item2.exercise?._id,
                        title: item2.exercise?.title,
                        image: item2.exercise?.image,
                      },
                      title: item2.exercise?.title,
                      description: item2.description,
                      parameters: item2.parameters,
                    };
                    if (item2.set) {
                      data["sets"] = [];
                      if (item2.set.length > 0) {
                        data["sets"] = item2.set.map((item3) => {
                          let data = {};
                          if (item3.race_type) {
                            data["race_type"] = item3.race_type;
                          }
                          if (item3.pace) {
                            data["pace_low"] = item3.pace;
                          }
                          if (item3.mhr) {
                            data["mhr"] = item3.mhr;
                          }
                          if (item3.reps) {
                            data["reps"] = item3.reps;
                          }
                          if (item3.rest) {
                            data["rest"] = item3.rest;
                          }
                          if (item3.weight) {
                            data["weight"] = item3.weight;
                          }
                          if (item3.time) {
                            data["duration"] = item3.time;
                          }
                          if (item3.weightType) {
                            data["weight_unit"] = item3.weightType;
                          }
                          if (item3.distance) {
                            data["distance"] = item3.distance;
                          }
                          if (item3.distanceType) {
                            data["distance_unit"] = item3.distanceType;
                          }
                          if (item3.timeType) {
                            data["duration_unit"] = item3.timeType;
                          }
                          if (item3.restType) {
                            data["rest_unit"] = item3.restType;
                          }
                          if (item3.tempo) {
                            data["tempo"] = item3.tempo;
                          }
                          return data;
                        });
                      }
                    }

                    return data;
                  });
                } else {
                  if (item.type == "emom") {
                    enqueueSnackbar(
                      `please add a Movement on ${
                        ind + 1
                      } Interval in EMOM, EMOM placed on ${
                        mainIn + 1
                      } position.`,
                      {
                        variant: "error",
                      }
                    );
                  }
                  if (item.type == "amrap") {
                    enqueueSnackbar(
                      `please add a Movement in AMRAP,  placed on ${
                        mainIn + 1
                      } position.`,
                      {
                        variant: "error",
                      }
                    );
                  }

                  isError = true;

                  return;
                }
              });
            }
          }
          if (item.superSet) {
            data["sub_exercises"] = [];
            if (item.superSet.length > 0) {
              data["sub_exercises"] = item.superSet.map((item) => {
                if (isError) {
                  return;
                }
                if (!item.exercise?._id) {
                  enqueueSnackbar(
                    `please select an exercise in Superset,  placed on ${
                      mainIn + 1
                    } position.`,
                    {
                      variant: "error",
                    }
                  );

                  isError = true;
                  return;
                }
                let data = {
                  exercise_info: {
                    _id: item.exercise?._id,
                    title: item.exercise?.title,
                    image: item.exercise?.image,
                  },
                  title: item.exercise?.title,
                  description: item.description,
                  parameters: item.parameters,
                };
                if (item.set) {
                  data["sets"] = [];
                  if (item.set.length > 0) {
                    data["sets"] = item.set.map((item) => {
                      let data = {};
                      if (item.race_type) {
                        data["race_type"] = item.race_type;
                      }
                      if (item.pace) {
                        data["pace_low"] = item.pace;
                      }
                      if (item.mhr) {
                        data["mhr"] = item.mhr;
                      }
                      if (item.reps) {
                        data["reps"] = item.reps;
                      }
                      if (item.rest) {
                        data["rest"] = item.rest;
                      }
                      if (item.weight) {
                        data["weight"] = item.weight;
                      }
                      if (item.time) {
                        data["duration"] = item.time;
                      }
                      if (item.weightType) {
                        data["weight_unit"] = item.weightType;
                      }
                      if (item.distance) {
                        data["distance"] = item.distance;
                      }
                      if (item.distanceType) {
                        data["distance_unit"] = item.distanceType;
                      }
                      if (item.timeType) {
                        data["duration_unit"] = item.timeType;
                      }
                      if (item.restType) {
                        data["rest_unit"] = item.restType;
                      }
                      if (item.tempo) {
                        data["tempo"] = item.tempo;
                      }
                      return data;
                    });
                  }
                }

                return data;
              });
            }
          }
          return data;
        });
        const formData = new FormData();
        formData.append("title", inputs?.title);

        // formData.append("image", state.image);
        if (file) {
          formData.append("image", inputs?.image);
          console.log(file, "dsjfksdjflkaj");
        }
        formData.append("type", inputs?.type);
        formData.append("description", inputs?.short_description);
        formData.append("status", inputs.status);
        formData.append(
          "difficulty_levels",
          JSON.stringify(inputs?.difficulty_levels)
        );
        formData.append("exercises", JSON.stringify(exerciesArray));

        console.log(...formData, "dskjfhakjdsh");

        const result = await EditWorkoutApi(formData, data.workout_slug);
        if (result.code == 200) {
          enqueueSnackbar(result.message, { variant: "success" });
          searchFunction();
          handleClose();
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      } else {
        navigate(`/videoset-add`, {
          state: inputs,
        });

        setOpen(false);
      }
    } else {
      enqueueSnackbar("Please fill all the fields", { variant: "error" });
    }
  };
  useEffect(() => {
    if (openFor == "Edit") {
      setIsLoading(false);
      let givenExercise = data.exercises.map((item) => {
        let SubSet = item?.sub_exercises?.map((item) => {
          return {
            _id: item?._id,
            exercise: item?.exercise_info,
            set: item?.sets.map((item1) => {
              return {
                mhr: item1?.mhr,
                time: item1?.duration,
                weight: item1?.weight,
                weightType: item1?.weight_unit,
                reps: item1?.reps,
                rest: item1?.rest,
                distance: item1?.distance,
                distanceType: item1?.distance_unit,
                timeType: item1?.duration_unit,
                restType: item1?.rest_unit,
                tempo: item1?.tempo,
                race_type: item1?.race_type ? item1?.race_type : "c",
                pace: item1?.pace_low ? item1?.pace_low : 0,
              };
            }),
            rounds: 10,
            emomSets: [[]],
            type: item.exercise_type ? item.exercise_type : "general",
            parameters: item?.parameters,
            description: item?.description,
            superSet: [],
          };
        });
        let emomAndAmrapSets = item?.emom_amrap?.map((item1, ind) => {
          let interval = item1?.map((item) => {
            return {
              _id: item?._id,
              exercise: item?.exercise_info,
              set: item?.sets.map((item1) => {
                return {
                  mhr: item1?.mhr,
                  time: item1?.duration,
                  weight: item1?.weight,
                  weightType: item1?.weight_unit,
                  reps: item1?.reps,
                  rest: item1?.rest,
                  distance: item1?.distance,
                  distanceType: item1?.distance_unit,
                  timeType: item1?.duration_unit,
                  restType: item1?.rest_unit,
                  tempo: item1?.tempo,
                  race_type: item1?.race_type ? item1?.race_type : "c",
                  pace: item1?.pace_low ? item1?.pace_low : 0,
                };
              }),
              parameters: item?.parameters,
              description: item?.description,
              superSet: [],
            };
          });
          return interval;
        });
        return {
          _id: item?._id,
          exercise: item?.exercise_info,
          set: item?.sets.map((item1) => {
            return {
              time: item1?.duration,
              mhr: item1?.mhr,
              weight: item1?.weight,
              weightType: item1?.weight_unit,
              reps: item1?.reps,
              rest: item1?.rest,
              distance: item1?.distance,
              distanceType: item1?.distance_unit,
              timeType: item1?.duration_unit,
              restType: item1?.rest_unit,
              tempo: item1?.tempo,
              vdot: item1?.vdot ? item1?.vdot : null,
              race_type: item1?.race_type ? item1?.race_type : "c",
              pace_type: item1?.pace_type ? item1?.pace_type : "c_km",
              pace: item1?.pace_low ? item1?.pace_low : 0,
              paceUp: item1?.pace_up ? item1?.pace_up : 423,
              optionsType:
                item1?.pace_options && item1?.pace_options.length > 0
                  ? item1?.pace_options
                  : [
                      { title: "1 KM", key: "c_km" },
                      { title: "1 MILE", key: "c_mi" },
                    ],
            };
          }),
          parameters: item?.parameters,
          rounds: item.emom_rounds ? item.emom_rounds : 10,
          title: item.title,
          type: item.exercise_type ? item.exercise_type : "general",
          time_interval: item.interval_duration,
          emomSets: emomAndAmrapSets?.length > 0 ? emomAndAmrapSets : [[]],
          description: item?.description,
          superSet: SubSet,
          superset_sets: item?.superset_sets,
        };
      });

      setaddArray(givenExercise);
      setOldImage(data?.image?.thumbnail_1);
      setInputs((inputs) => ({
        ...inputs,
        ["title"]: data?.title,
        ["status"]: data.status,
        ["type"]: data?.type,
        ["short_description"]: data?.description,
        ["image"]: "",
        ["difficulty_levels"]: data?.difficulty_levels,
      }));
    } else {
      setIsLoading(false);
      setProfileImage("");
      setInputs((inputs) => ({
        ...inputs,
        ["title"]: "",
        ["status"]: true,
        ["type"]: "",
        ["short_description"]: "",
        ["image"]: "",
        ["difficulty_levels"]: [],
      }));
    }
    console.log(openFor, data, "djfkjdshkja");
  }, [open]);

  return (
    <div>
      {/* <Button variant="contained" color="primary" onClick={handleOpen}>
        Open Modal
      </Button> */}
      <button
        className="small-contained-button "
        style={{ padding: "10px 16px" }}
        onClick={handleOpen}
      >
        Add Video
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "65%",

            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            border: "none",
            overflow: "auto",
            maxHeight: "90vh",
          }}
        >
          <CloseIcon
            onClick={handleClose}
            style={{
              fill: "var(--portal-theme-primary)",
              backgroundColor: "var(--portal-theme-secondary)",
              borderRadius: "50px",
              padding: "4px",
            }}
            sx={{ position: "absolute", top: 5, right: 5 }}
          />
          {openFor == "Edit" ? (
            <h2 id="simple-modal-title">Edit Video</h2>
          ) : (
            <h2 id="simple-modal-title">Build Video</h2>
          )}

          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="row">
                <div className="col-12">
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      label="Video Name*"
                      variant="outlined"
                      fullWidth
                      name="title"
                      value={inputs.title}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="demo-simple-select-label">
                      Video Type *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="type"
                      value={inputs.type}
                      label="Video Type *"
                      onChange={handleChange}
                    >
                      {workoutTypes.map((item) => {
                        // console.log(item, "sdkjfjsd");
                        return (
                          <MenuItem value={item._id} key={item._id}>
                            {item.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-12 col-md-6">
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="demo-simple-select-label">
                      Video Status *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="status"
                      value={inputs.status}
                      label="Video Status *"
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>Active</MenuItem>
                      <MenuItem value={false}>Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-12 col-md-6">
                  <FormControl fullWidth sx={{ mt: 2 }} required>
                    <InputLabel id="demo-simple-select-label">
                      Difficulty Levels
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      multiple
                      name="difficulty_levels"
                      value={inputs.difficulty_levels}
                      label="Difficulty Levels"
                      onChange={handleChange}
                    >
                      <MenuItem value="novice">Novice</MenuItem>
                      <MenuItem value="intermediate">Intermediate</MenuItem>
                      <MenuItem value="advanced">Advanced</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 ">
              <FormControl
                fullWidth
                sx={{
                  mt: {
                    xs: 2,
                    sm: 2,
                    md: 0,
                    lg: 0,
                  },
                }}
              >
                <TextField
                  id="outlined-multiline-flexible"
                  label="Short Description* "
                  multiline
                  rows={7}
                  name="short_description"
                  value={inputs.short_description}
                  onChange={handleChange}
                />
                <FormHelperText sx={{ fontSize: 10 }}>
                  (Maximum limit 250 characters)
                </FormHelperText>
              </FormControl>
            </div>
          </div>
          <div className=" my-3">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-lg-5 col-md-5 col-sm-12">
                <p className="">Upload Image *</p>
                <FormHelperText className="pt-0" sx={{ fontSize: 10 }}>
                  Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className=" col-lg-2 col-md-2 col-sm-12">
                {file ? (
                  <img className="image-border" src={file} height="50" />
                ) : oldImage ? (
                  <img
                    className="image-border"
                    src={s3baseUrl + oldImage}
                    height="50"
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12  text-lg-end text-md-end text-sm-start pt-2">
                <label htmlFor="contained-button-file">
                  <input
                    accept=".jpg, .jpeg, .png, .webp"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    required
                    onChange={fileChangedHandler}
                    style={{ display: "none" }}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                    variant="contained"
                    size="small"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.image.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.image.name}</p>
            )}
          </div>

          <button
            className="small-contained-button mt-3"
            type="submit"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {openFor == "Edit" ? "Save" : "START BUILDING"}
          </button>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalExample;
