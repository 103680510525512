import * as React from "react";
import Box from "@mui/material/Box";
import { useState, useMemo } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
//import Editor from "../../components/Editor/Editor";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
// import { VaultListing } from "src/DAL/Vault/Vault";
import Autocomplete from "@mui/material/Autocomplete";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import { TimeZones } from "src/utils/constant";
// import countryList from "react-select-country-list";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import {
  IconButton,
  Divider,
  CircularProgress,
  Checkbox,
  InputAdornment,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
// import { AddProgram } from "src/DAL/Programmes/Programmes";
import { object } from "prop-types";
import { menuList } from "src/DAL/Menu/Menu";
import { AddProgrammeApi } from "src/DAL/Programme/Programme";
import { countries } from "src/utils/country";
import {
  AddMemberApi,
  EditMemberApi,
  memberAllDetailApi,
} from "src/DAL/member/Member1";
// import { coachListing } from "src/DAL/coach/coach";
import { s3baseUrl } from "src/config/config";
import { height } from "@mui/system";
import ActiveLastBreadcrumb from "src/components/ActivePaths";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import { getAllTemplatesListByType } from "src/DAL/FormTemplate/feedbackTemplates";
import CustomConfirmationOk from "src/components/ModalPopover/ConfirmationOk";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import { DatePicker } from "@mui/lab";
import { addDays } from "date-fns";

// import { get_root_value } from "src/utils/domUtils";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Input = styled("input")({
  display: "none",
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export default function EditMember() {
  const navigate = useNavigate();
  const { previousRoute, setPreviousRouteHandler } = usePGIMode();
  const classes = useStyles();
  const { state } = useLocation();
  const { id } = useParams();
  const [previousLocation, setPreviousLocation] = useState(previousRoute);
  const { enqueueSnackbar } = useSnackbar();
  const [iswaiting, setiswaiting] = useState(false);
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [memberData, setMemberData] = useState();
  const [checkedPace, setCheckedPace] = useState(false);

  const [file, setProfileImage] = React.useState();
  const [audioFile, setAudio] = React.useState();
  const [groups, setGroups] = React.useState([]);
  const [country, setCountry] = React.useState("");
  const [menuLists, setMenuList] = React.useState([]);
  const [date, setDate] = React.useState(null);
  const [programName, setProgramName] = React.useState("");
  const [vaultName, setVaultName] = React.useState("");
  const [navItems, setNavitems] = React.useState([]);
  const [coachValue, setCoachValue] = React.useState([]);
  const [oldImage, setOldImage] = useState("");
  // const options = useMemo(() => countryList().getData(), []);
  const [selectCountry, setSelectCountry] = useState("");
  const [imageStatus, setImageStatus] = useState(false);
  const [timeZoneValue, setTimeZoneValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [checked, setChecked] = React.useState();
  const [postalCountry, setPostalCountry] = React.useState("");
  const [memberVdotData, setMemberVdotDataData] = useState({
    time_interval: 0,
  });
  const [allTemplates, setAllTemplates] = useState([]);

  const [intervalTime, setIntervalTime] = useState(0);
  const [lastRecurringDate, setLastRecurringDate] = useState("");
  const [isNumberBefor, setIsNumberBefor] = useState(false);
  const [nextDate, setIsNextDate] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [inputs, setInputs] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    contact_number: "",
    goal_status: "",
    member_status: "",
    street: "",
    city: "",
    zip_code: "",
    state: "",
    coach_id: "",
    first_payment_date: new Date(),
    member_biography: "",
    image: {},
    short_description: "",
    time_zone: "",
    coins_count: "",
    referral_name: "",
    is_membership_purchase: "",
    is_executive: "",
    street2: "",
    postalStreet: "",
    postalStreet2: "",
    postalZip_code: "",
    postalState: "",
    postalCity: "",
    distance: "5km",
    isRecurring: false,
    recurringDays: 1,
    form_template: null,
    recurring_end_date: null,
    last_cycle_date: "",
    high_day_calories: "",
    low_day_calories: "",
    regular_day_calories: "",
    gender: "male",
    weight: "",
    height: "",
    age: "",
    activity_level: "sedentary",
    deficit_percentage: true,
    deficit_value: "",
    days_for_deficit: "",
    kcal_type: "kcal",
    high_day_carb: "",
    high_day_protein: "",
    high_day_fat: "",
    low_day_carb: "",
    low_day_protein: "",
    low_day_fat: "",
  });
  let breadCrumbMenu = [
    {
      title: "Members",
      navigation: `/member`,
      active: false,
    },

    {
      title: "Edit Member",
      navigation: null,
      active: true,
    },
  ];
  const changeHandlerCountry = (value) => {
    //console.log(value.target.value, "asdfghjkl");
    setSelectCountry(value.target.value);
  };
  const getNavItemList = async () => {
    const result = await menuList();
    if (result.code === 200) {
      setMenuList(result.menu);
      //   setVaultName(result.vault_category[0].vault_slug);
    }
  };
  console.log(state, "statestate");
  const memberDetails = async () => {
    setIsLoading(true);
    try {
      const result = await getAllTemplatesListByType("member");
      if (result.code === 200) {
        setAllTemplates(result?.form_templates);
        setInputs((values) => ({ ...values, form_template: "" }));
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {}
    const result = await memberAllDetailApi(id);

    if (result.code == 200) {
      let obj = {};
      if (result?.member?.is_postal_address_same) {
        let beforePars =
          result?.member?.postal_addess_info == undefined
            ? ""
            : JSON.stringify(result?.member?.postal_addess_info);

        obj = beforePars == "" ? "" : JSON.parse(beforePars);
      } else {
        let strigifyObj = JSON.stringify(result?.member?.postal_addess_info);
        obj = result?.member?.postal_addess_info && JSON.parse(strigifyObj);
      }

      let postalInfo = obj;

      setChecked(result?.member?.is_postal_address_same);
      let findCountry = countries.find((item) => {
        if (String(item.code) == String(result?.member?.country)) {
          return item;
        }
      });
      let findPostalCountry = countries.find((item) => {
        if (String(item.code) == String(postalInfo?.country)) {
          return item;
        }
      });
      // setDate(
      //   first_payment_date == "" || first_payment_date == "Invalid date"
      //     ? null
      //     : first_payment_date
      // );
      setOldImage(result?.member?.profile_image);
      setSelectCountry(result?.member?.country);
      setCountry(findCountry);
      setPostalCountry(findPostalCountry);
      setTimeZoneValue(result?.member?.time_zone);
      setLastRecurringDate(result?.member?.last_form_sent_date);
      setCheckedPace(result?.member?.pace_calculator);

      setInputs((inputs) => ({
        ...inputs,
        ["first_name"]: result?.member?.first_name,
        ["last_name"]: result?.member?.last_name,
        ["last_name"]: result?.member?.last_name,
        ["email"]: result?.member?.email,
        // ["password"]:result?.member?. password,
        ["contact_number"]: result?.member?.contact_number,
        ["member_status"]: result?.member?.status,
        ["goal_status"]: result?.member?.goal,
        ["street"]: result?.member?.street,
        ["city"]: result?.member?.city,
        ["state"]: result?.member?.state,
        ["zip_code"]: result?.member?.zip_code,
        ["time_zone"]: result?.member?.time_zone,
        ["coach_id"]: result?.member?.coach,
        ["short_description"]: result?.member?.member_biography,
        ["short_description"]: result?.member?.member_biography,
        ["distance"]: result?.member?.vdot?.race_type,
        ["isRecurring"]: result?.member?.form_template ? true : false,
        ["recurringDays"]: result?.member?.recurring_form_days,
        ["recurring_end_date"]: result?.member?.recurring_end_date,
        ["last_cycle_date"]: result?.member?.last_cycle_date,
        ["form_template"]: result?.member?.form_template,
        ["regular_day_calories"]: result?.member?.regular_day_calories,
        ["high_day_calories"]: result?.member?.high_day_calories,
        ["low_day_calories"]: result?.member?.low_day_calories,
        ["gender"]: result?.member?.gender,
        ["weight"]: result?.member?.weight,
        ["height"]: result?.member?.height,
        ["age"]: result?.member?.age,
        ["activity_level"]: result?.member?.activity_level,
        ["deficit_percentage"]: result?.member?.deficit_percentage,
        ["deficit_value"]: result?.member?.deficit_value,
        ["days_for_deficit"]: result?.member?.days_for_deficit,
        ["deficit_start_date"]: result?.member?.deficit_start_date,
        ["kcal_type"]: result?.member?.kcal_type || "kcal",
        ["high_day_carb"]: result?.member?.high_day_carb || "",
        ["high_day_protein"]: result?.member?.high_day_protein || "",
        ["high_day_fat"]: result?.member?.high_day_fat || "",
        ["low_day_carb"]: result?.member?.low_day_carb || "",
        ["low_day_protein"]: result?.member?.low_day_protein || "",
        ["low_day_fat"]: result?.member?.low_day_fat || "",
        // ["memberShipPurchase"]: state?.object?.is_membership_purchase,
        // ["coins_count"]: coinsCount,
        // ["referral_name"]: state?.object?.refferal_name,
        // ["is_membership_purchase"]: state?.object?.is_membership_purchase,
        // ["is_executive"]: state?.object?.is_executive,
        // ["street2"]: state?.street2,
        // ["postalCity"]: postalInfo?.city,
        // ["postalState"]: postalInfo?.state,
        // ["postalStreet"]: postalInfo?.street,
        // ["postalStreet2"]: postalInfo?.street2,
        // ["postalZip_code"]: postalInfo?.zip_code,
      }));
      setMemberVdotDataData((inputs) => ({
        ...inputs,
        ["time_interval"]: result?.member?.vdot?.time_taken,
      }));
      setMemberData(result?.member);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getFormListing = async () => {
    try {
      const result = await getAllTemplatesListByType("member");
      if (result.code === 200) {
        setAllTemplates(result?.form_templates);
        setInputs((values) => ({ ...values, form_template: "" }));
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {}
  };
  const handleChangeTemplate = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({
      ...values,
      [name]: value,
    }));
  };
  const consultantListData = async () => {
    setIsLoading(true);
    let result = await coachListing();
    //console.log(result, "lololololo");
    if (result.code == 200) {
      //console.log(result.consultant_list, "result.consultant");
      setCoachValue(result.coach_list);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
    //console.log(result, "okokoko");
  };

  const handleChangeNavItem = (event) => {
    const {
      target: { value },
    } = event;
    setNavitems(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setImageStatus(true);
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };
  const handleChangeIsPostalAddress = (event) => {
    setChecked(event.target.checked);
  };
  const audioFileChange = (e) => {
    //console.log("clicked");
    setAudio(e.target.files[0]);
  };

  const handleChangePace = (event) => {
    setCheckedPace(event.target.checked);
    setMemberVdotDataData((inputs) => ({
      ...inputs,
      ["time_interval"]: 0,
    }));
    setInputs((inputs) => ({
      ...inputs,
      ["distance"]: "5km",
    }));
  };

  const handleProgramName = (data) => {
    setProgramName(data.program_slug);
  };

  const handleVaultName = (data) => {
    setVaultName(data.vault_slug);
  };
  const handldeDeleteAudio = () => {
    setAudio();
    //console.log("reset State");
  };
  const handleChangeMembers = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    const membersName = typeof value === "string" ? value.split(",") : value;
    setInputs((input) => ({
      ...input,
      ["members"]: membersName,
    }));
  };
  const handleChangeGroups = (event) => {
    const {
      target: { value },
    } = event;
    setGroups(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    const groupName = typeof value === "string" ? value.split(",") : value;
    setInputs((input) => ({
      ...input,
      ["groups"]: groupName,
    }));
  };
  //console.log(inputs.time_zone, "inputs.time_zone");

  const handleChangeDate = (newValue) => {
    let todayDate = moment(newValue).format("YYYY-MM-DD");
    let dateType = todayDate.toString();
    //console.log(typeof dateType, "dateType");
    setDate(newValue);
  };

  const calculateTDEE = (value) => {
    const { gender, weight, height, age, activity_level } = value;

    if (!gender || !weight || !height || !age || !activity_level) {
      return 0;
    }

    let BMR;

    if (gender === "male") {
      BMR = 10 * weight + 6.25 * height - 5 * age + 5;
    } else if (gender === "female") {
      BMR = 10 * weight + 6.25 * height - 5 * age - 161;
    } else {
      BMR = 10 * weight + 6.25 * height - 5 * age - 78;
    }

    const activityMultipliers = {
      sedentary: 1.2,
      lightly: 1.375,
      moderately: 1.55,
      active: 1.725,
      super: 1.9,
    };

    const TDEE = BMR * (activityMultipliers[activity_level] || 1.2);

    return Math.round(TDEE);
  };

  let totalKcal = calculateTDEE(inputs);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (country == "" || country == undefined) {
      enqueueSnackbar("Country is required", {
        variant: "error",
      });
    } else {
      if (inputs.days < 0) {
        enqueueSnackbar("No of days must be positive number ", {
          variant: "error",
        });
      } else if (inputs?.isRecurring && inputs.recurringDays < 1) {
        enqueueSnackbar("No of Recurring days at least 1", {
          variant: "error",
        });
        return;
      } else if (inputs?.isRecurring && !inputs.form_template) {
        enqueueSnackbar(`Please select a Form Template`, {
          variant: "error",
        });
        return;
      } else if (inputs?.isRecurring && isNumberBefor) {
        enqueueSnackbar(` Please increase form's recurring days`, {
          variant: "error",
        });
        return;
      } else if (inputs.days_for_deficit > 30) {
        enqueueSnackbar("Deficit Duration must be between 1-30", {
          variant: "error",
        });
      } else if (inputs.deficit_value > 30) {
        enqueueSnackbar("Deficit Percentage must be between 1-30", {
          variant: "error",
        });
      } else if (checkedPace && memberVdotData?.time_interval == 0) {
        enqueueSnackbar("Please enter a value in the Duration field.", {
          variant: "error",
        });
      } else {
        let VDOT = {
          race_type: inputs?.distance,
          time_taken: checkedPace ? memberVdotData?.time_interval : 0,
        };
        const formData = new FormData();
        formData.append("first_name", inputs.first_name);
        formData.append("last_name", inputs.last_name);
        formData.append("member_biography", inputs.short_description);
        formData.append("email", inputs.email);
        // formData.append("password", inputs.password);
        formData.append("contact_number", inputs.contact_number);
        formData.append("status", inputs.member_status);
        formData.append("street", inputs.street);
        // formData.append("street2", inputs.street2);
        formData.append("city", inputs.city);
        formData.append("zip_code", inputs.zip_code);
        formData.append("state", inputs.state);
        formData.append("country", country?.code);
        formData.append("regular_day_calories", totalKcal);
        formData.append("high_day_calories", inputs.high_day_calories || 0);
        formData.append("low_day_calories", inputs.low_day_calories || 0);
        formData.append("gender", inputs.gender);
        formData.append("age", inputs.age);
        formData.append("weight", inputs.weight);
        formData.append("height", inputs.height);
        formData.append("activity_level", inputs.activity_level);
        formData.append("deficit_percentage", inputs.deficit_percentage);
        formData.append("deficit_value", inputs.deficit_value || 0);
        formData.append("days_for_deficit", inputs.days_for_deficit || 0);
        formData.append("high_day_carb", inputs.high_day_carb || 0);
        formData.append("high_day_fat", inputs.high_day_fat || 0);
        formData.append("high_day_protein", inputs.high_day_protein || 0);
        formData.append("low_day_carb", inputs.low_day_carb || 0);
        formData.append("low_day_protein", inputs.low_day_protein || 0);
        formData.append("low_day_fat", inputs.low_day_fat || 0);
        formData.append("kcal_type", inputs.kcal_type);
        formData.append(
          "last_cycle_date",
          inputs.gender === "female"
            ? inputs.last_cycle_date && inputs.last_cycle_date !== "null"
              ? inputs.last_cycle_date
              : ""
            : ""
        );
        formData.append("vdot", JSON.stringify(VDOT));
        formData.append(
          "recurring_form_days",
          inputs?.isRecurring ? inputs.recurringDays : ""
        );
        if (inputs.isRecurring) {
          formData.append("recurring_end_date", inputs.recurring_end_date);
        }
        formData.append(
          "form_template",
          inputs?.isRecurring ? inputs.form_template : ""
        );
        formData.append(
          "coach_id",
          inputs.coach_id == undefined ? "" : inputs.coach_id
        );
        formData.append("pace_calculator", checkedPace);
        // formData.append(
        //   "first_payment_date",
        //   date ? moment(date).format("YYYY-MM-DD") : ""
        // );
        // formData.append("goal_statement_status", inputs.goal_status);
        // formData.append("coins_count", inputs.coins_count);
        formData.append("time_zone", timeZoneValue);
        // formData.append("refferal_name", inputs.referral_name);
        // formData.append("is_membership_purchase", inputs.is_membership_purchase);
        // formData.append("is_executive", inputs.is_executive);
        if (imageStatus == true) {
          formData.append("profile_image", inputs.image);
        }

        console.log(...formData, "form data value");

        setIsLoading(true);
        const result = await EditMemberApi(formData, id);
        if (result.code === 200) {
          //console.log(result, "result");
          enqueueSnackbar(result.message, { variant: "success" });
          setIsLoading(false);

          if (
            previousLocation &&
            previousLocation.match("/member-MemberCalendar/")
          ) {
            navigate(`/member/member-profile/${id}`);
            setPreviousRouteHandler("");
          } else navigate(-1);
        } else {
          //console.log(result, "error case");
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      }
    }
  };
  //console.log(inputs, "inputs");
  const handleChangeProgram = (e) => {
    setProgramName(e.target.value);
  };
  const handleChangeVault = (event) => {
    setVaultName(event.target.value);
  };
  const handleHoursChange = (e) => {
    const hours = parseInt(e.target.value) || 0;
    const minutes = Math.floor((memberVdotData.time_interval % 3600) / 60);
    const seconds = memberVdotData.time_interval % 60;
    updateIntervalTime(hours * 3600 + minutes * 60 + seconds);
  };
  const handleMinutesChange = (e) => {
    let minutes = parseInt(e.target.value) || 0;
    minutes = Math.min(Math.max(minutes, 0), 59);

    // Calculate hours separately to prevent resetting
    const currentHours = Math.floor(memberVdotData.time_interval / 3600);
    const seconds = memberVdotData.time_interval % 60;

    // Update total seconds with new minutes and current hours
    updateIntervalTime(currentHours * 3600 + minutes * 60 + seconds);
  };

  const getTotalCalories = (carb = 0, protein = 0, fat = 0) => {
    carb = Number(carb) || 0;
    protein = Number(protein) || 0;
    fat = Number(fat) || 0;

    return carb * 4 + protein * 4 + fat * 9;
  };

  const handleSecondsChange = (e) => {
    let seconds = parseInt(e.target.value) || 0;
    seconds = Math.min(Math.max(seconds, 0), 59); // Ensure the value is between 0 and 60
    const minutes = Math.floor(memberVdotData.time_interval / 60);
    updateIntervalTime(minutes * 60 + seconds);
  };

  // Function to update intervalTime state
  const updateIntervalTime = (totalSeconds) => {
    setIntervalTime(totalSeconds);

    setMemberVdotDataData((prevState) => {
      return {
        ...prevState,
        ["time_interval"]: totalSeconds,
      };
    });
  };

  const formatWithLeadingZeros = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSelectOther = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  React.useEffect(() => {
    if (lastRecurringDate && inputs.recurringDays) {
      try {
        // Create a Moment object for lastRecurringDate
        let momentLastRecurringDate = moment();
        if (lastRecurringDate) {
          momentLastRecurringDate = moment(lastRecurringDate);
        }
        // Add recurring days to the lastRecurringDate
        const formattedDate = momentLastRecurringDate
          .clone() // Clone to avoid modifying original date
          .add(inputs.recurringDays, "days")
          .format("YYYY-MM-DD");

        // Check if the formatted date is before today
        const isBeforeToday = moment(
          formattedDate,
          "YYYY-MM-DD"
        ).isSameOrBefore(moment(), "day");
        setIsNumberBefor(isBeforeToday);
        setIsNextDate(formattedDate);
        console.log(
          "Formatted Date:",
          formattedDate,
          "Is Before Today:",
          isBeforeToday
        );
      } catch (error) {
        console.error("Error occurred while processing dates:", error);
      }
    } else {
      try {
        // Create a Moment object for lastRecurringDate
        let momentLastRecurringDate = moment();
        if (lastRecurringDate) {
          momentLastRecurringDate = moment(lastRecurringDate);
        }
        // Add recurring days to the lastRecurringDate
        const formattedDate = momentLastRecurringDate
          .clone() // Clone to avoid modifying original date
          .add(inputs.recurringDays, "days")
          .format("YYYY-MM-DD");

        // Check if the formatted date is before today

        setIsNextDate(formattedDate);
        console.log("Formatted Date:", formattedDate);
      } catch (error) {
        console.error("Error occurred while processing dates:", error);
      }
    }
  }, [lastRecurringDate, inputs.recurringDays]);

  React.useEffect(() => {
    // consultantListData();
    memberDetails();
    // getFormListing();
  }, []);

  const today = new Date();
  const startDate = inputs?.deficit_start_date
    ? new Date(inputs.deficit_start_date)
    : today;

  if (inputs.days_for_deficit > 0 && inputs.days_for_deficit < 31) {
    startDate.setDate(startDate.getDate() + parseInt(inputs.days_for_deficit));
  }

  const formattedDate = startDate.toISOString().split("T")[0];

  React.useEffect(() => {}, [memberData]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  // console.log(timeZoneValue, "timeZoneValue");

  return (
    <div className="container">
      <CustomConfirmationOk
        open={openInfo}
        setOpen={setOpenInfo}
        title="Please increase number!"
        descriotion={`Your last sent date was ${moment(
          lastRecurringDate
        ).format(
          "YYYY-MM-DD"
        )}. Based on the number of recurring days you entered,  the next scheduled date would be ${nextDate}. Since this date has already passed, please increase the number of recurring days accordingly `}
      />
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <h2 className="me-3">Edit Member</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="First Name"
              variant="outlined"
              fullWidth
              required
              name="first_name"
              value={inputs.first_name}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Last Name"
              variant="outlined"
              fullWidth
              name="last_name"
              value={inputs.last_name}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              defaultValue="Hello World"
              fullWidth
              required
              name="email"
              value={inputs.email}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Contact Number"
              variant="outlined"
              fullWidth
              name="contact_number"
              value={inputs.contact_number}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Member Status *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="member_status"
                value={inputs.member_status}
                label="Member Status *"
                onChange={handleChange}
              >
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Recurring Forms?*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="isRecurring"
                value={inputs.isRecurring}
                label="Recurring Forms?*"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.isRecurring && (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-selec-label">
                    Form Template*
                  </InputLabel>
                  <Select
                    required
                    labelId="demo-simple-selec-label"
                    id="demo-simple-select"
                    name="form_template"
                    value={inputs?.form_template}
                    label="Form Template*"
                    onChange={handleChangeTemplate}
                  >
                    {allTemplates.map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label={`${
                      inputs.accessType == "limited"
                        ? `No Of Day (${inputs?.no_of_weeks ? 1 : 0} to ${
                            inputs?.no_of_weeks * 7
                          })`
                        : "No Of Recurring Days"
                    }`}
                    variant="outlined"
                    type="number"
                    placeholder={`${
                      inputs.accessType == "limited"
                        ? `No Of Day (${inputs?.no_of_weeks ? 1 : 0} to ${
                            inputs?.no_of_weeks * 7
                          })`
                        : "No Of Recurring Days"
                    }`}
                    fullWidth
                    required
                    name="recurringDays"
                    value={inputs.recurringDays}
                    onChange={handleChange}
                  />
                  {isNumberBefor ? (
                    <FormHelperText sx={{ cursor: "pointer" }}>
                      Please increase the number{" "}
                      <span
                        onClick={() => {
                          setOpenInfo(true);
                        }}
                        style={{
                          display: "inline",
                          cursor: "pointer",
                          color: "red",
                        }}
                      >
                        <Icon icon="octicon:info-24" />
                      </span>
                    </FormHelperText>
                  ) : inputs.recurringDays && inputs.recurringDays != "0" ? (
                    <FormHelperText sx={{ cursor: "pointer" }}>
                      Next Form will send on {nextDate}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <LocalizationProvider dateAdapter={AdapterDateFns} required>
                  <DatePicker
                    label="End Date"
                    name="recurring_end_date"
                    disablePast
                    inputFormat="dd-MM-yyyy"
                    value={inputs.recurring_end_date}
                    minDate={addDays(new Date(), 1)}
                    onChange={(e) => {
                      handleSelectOther("recurring_end_date", e);
                    }}
                    format="DD-MM-YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="inputs-fields"
                        fullWidth
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </>
          )}
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-lg-5 col-md-5 col-sm-12">
                <p className="">Upload Image </p>
                <FormHelperText className="pt-0">
                  Image Size(500 X 500) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className="col-lg-2 col-md-2 col-6">
                {file ? (
                  <img className="image-border" src={file} height="50" />
                ) : (
                  oldImage && (
                    <img
                      className="image-border"
                      src={s3baseUrl + oldImage}
                      height="50"
                    ></img>
                  )
                )}
              </div>
              <div className="col-lg-5 col-md-5 col-6 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.image.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.image.name}</p>
            )}
          </div>

          <div className="col-12">
            <h3>Address Details</h3>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <Autocomplete
              id="country-select-demo"
              // sx={{ width: 300 }}
              options={countries}
              autoHighlight
              value={country}
              onChange={(event, newValue) => {
                setCountry(newValue);
              }}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    className="image-border"
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.label} ({option.code}) +{option.phone}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a country *"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="City"
              variant="outlined"
              fullWidth
              name="city"
              required
              value={inputs.city}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="State"
              variant="outlined"
              fullWidth
              required
              name="state"
              value={inputs.state}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Street Address 1"
              variant="outlined"
              fullWidth
              required
              name="street"
              value={inputs.street}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Zip Code"
              variant="outlined"
              fullWidth
              required
              name="zip_code"
              value={inputs.zip_code}
              onChange={handleChange}
            />
          </div>
          <Divider className="mt-2" />
          <h3 className="mt-3">User Info</h3>
          <div className="col-12 col-md-4 mt-3">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Gender</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="gender"
                value={inputs.gender}
                label="Gender"
                onChange={handleChange}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.gender === "female" && (
            <div className="col-12 col-md-4 mt-3">
              <LocalizationProvider dateAdapter={AdapterDateFns} required>
                <DatePicker
                  label="Last Cycle Date"
                  name="last_cycle_date"
                  inputFormat="dd-MM-yyyy"
                  value={inputs.last_cycle_date}
                  onChange={(e) => {
                    handleSelectOther("last_cycle_date", e);
                  }}
                  format="DD-MM-YYYY"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="inputs-fields"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          )}
          <div className="col-12 col-md-4 mt-3">
            <FormControl fullWidth required>
              <InputLabel htmlFor="outlined-adornment-amount">
                Weight
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                value={inputs?.weight}
                onChange={handleChange}
                name="weight"
                type="number"
                endAdornment={
                  <InputAdornment position="end">kg</InputAdornment>
                }
                label="Weight"
              />
            </FormControl>
          </div>
          <div className="col-12 col-md-4 mt-3">
            <FormControl fullWidth required>
              <InputLabel htmlFor="outlined-adornment-amount">
                Height
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                value={inputs?.height}
                type="number"
                onChange={handleChange}
                name="height"
                endAdornment={
                  <InputAdornment position="end">cm</InputAdornment>
                }
                label="Height"
              />
            </FormControl>
          </div>
          <div className="col-12 col-md-4 mt-3">
            <FormControl fullWidth required>
              <InputLabel htmlFor="outlined-adornment-amount">Age</InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                value={inputs?.age}
                type="number"
                onChange={handleChange}
                name="age"
                endAdornment={
                  <InputAdornment position="end">year</InputAdornment>
                }
                label="Age"
              />
            </FormControl>
          </div>
          <div className="col-12 col-md-4 mt-3">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Activity Level
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="activity_level"
                value={inputs.activity_level}
                label="Activity Level"
                onChange={handleChange}
              >
                <MenuItem value="sedentary">
                  Sedentary (little/no exercise)
                </MenuItem>
                <MenuItem value="lightly">
                  Lightly active (1-3 days/week exercise)
                </MenuItem>
                <MenuItem value="moderately">
                  Moderately active (3-5 days/week exercise)
                </MenuItem>
                <MenuItem value="active">
                  Very active (6-7 days/week exercise)
                </MenuItem>
                <MenuItem value="super">
                  Super active (athlete or hard labor)
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <h3 className="mt-3">Allowed Calories</h3>
          <div className="col-12 col-md-4 mt-3">
            <TextField
              id="outlined-basic"
              label="Regular Day Calories"
              variant="outlined"
              fullWidth
              required
              InputProps={{
                readOnly: true,
              }}
              type="number"
              name="regular_day_calories"
              onChange={handleChange}
              value={totalKcal}
            />
          </div>
          <div className="col-12 col-md-4 mt-3">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="kcal_type"
                value={inputs.kcal_type}
                label="Type"
                onChange={handleChange}
              >
                <MenuItem value="kcal">Calories</MenuItem>
                <MenuItem value="macro">Macro Nutrients</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-12 col-md-4"></div>
          {inputs.kcal_type == "kcal" ? (
            <>
              <div className="col-12 col-md-4 mt-3">
                <TextField
                  id="outlined-basic"
                  label="High Day Calories"
                  variant="outlined"
                  fullWidth
                  type="number"
                  required
                  name="high_day_calories"
                  onChange={handleChange}
                  value={inputs.high_day_calories}
                />
              </div>
              <div className="col-12 col-md-4 mt-3">
                <TextField
                  id="outlined-basic"
                  label="Low Day Calories"
                  variant="outlined"
                  fullWidth
                  type="number"
                  required
                  name="low_day_calories"
                  onChange={handleChange}
                  value={inputs.low_day_calories}
                />
              </div>
            </>
          ) : (
            <>
              {inputs.kcal_type == "macro" && (
                <>
                  <div className="col-12 col-md-4 mt-3">
                    <TextField
                      id="outlined-basic"
                      label="High Day Carb"
                      variant="outlined"
                      fullWidth
                      type="number"
                      required
                      name="high_day_carb"
                      onChange={handleChange}
                      value={inputs.high_day_carb}
                    />
                  </div>
                  <div className="col-12 col-md-4 mt-3">
                    <TextField
                      id="outlined-basic"
                      label="High Day Protein"
                      variant="outlined"
                      fullWidth
                      type="number"
                      required
                      name="high_day_protein"
                      onChange={handleChange}
                      value={inputs.high_day_protein}
                    />
                  </div>
                  <div className="col-12 col-md-4 mt-3">
                    <TextField
                      id="outlined-basic"
                      label="High Day Fat"
                      variant="outlined"
                      fullWidth
                      type="number"
                      required
                      name="high_day_fat"
                      onChange={handleChange}
                      value={inputs.high_day_fat}
                    />
                  </div>
                  <div className="col-12 text-end mt-2">
                    <p className="mb-0 text-muted fs_10">
                      High Day Calories:{" "}
                      {getTotalCalories(
                        inputs.high_day_carb,
                        inputs.high_day_protein,
                        inputs.high_day_fat
                      )}
                    </p>
                  </div>
                  <div className="col-12 col-md-4 mt-3">
                    <TextField
                      id="outlined-basic"
                      label="Low Day Carb"
                      variant="outlined"
                      fullWidth
                      type="number"
                      required
                      name="low_day_carb"
                      onChange={handleChange}
                      value={inputs.low_day_carb}
                    />
                  </div>
                  <div className="col-12 col-md-4 mt-3">
                    <TextField
                      id="outlined-basic"
                      label="Low Day Protein"
                      variant="outlined"
                      fullWidth
                      type="number"
                      required
                      name="low_day_protein"
                      onChange={handleChange}
                      value={inputs.low_day_protein}
                    />
                  </div>
                  <div className="col-12 col-md-4 mt-3">
                    <TextField
                      id="outlined-basic"
                      label="Low Day Fat"
                      variant="outlined"
                      fullWidth
                      type="number"
                      required
                      name="low_day_fat"
                      onChange={handleChange}
                      value={inputs.low_day_fat}
                    />
                  </div>
                  <div className="col-12 text-end mt-2">
                    <p className="mb-0 text-muted fs_10">
                      Low Day Calories:{" "}
                      {getTotalCalories(
                        inputs.low_day_carb,
                        inputs.low_day_protein,
                        inputs.low_day_fat
                      )}
                    </p>
                  </div>
                </>
              )}
            </>
          )}
          <h3 className="mt-3">Calorie Deficit</h3>
          {inputs.deficit_percentage && (
            <p className="text-muted mb-0">
              Enter a calorie deficit percentage between 1-30% and set the
              duration between 1-30 days.
            </p>
          )}
          <div className="col-12 col-md-4 mt-3">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Is Allowed Calorie Deficit
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="deficit_percentage"
                value={inputs.deficit_percentage}
                label="Is Allowed Calorie Deficit"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.deficit_percentage && (
            <>
              <div className="col-12 col-md-4 mt-3">
                <FormControl fullWidth required>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Calorie Deficit Percentage
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    value={inputs?.deficit_value}
                    type="number"
                    onChange={handleChange}
                    name="deficit_value"
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                    label="Calorie Deficit Percentage"
                  />
                </FormControl>
              </div>
              <div className="col-12 col-md-4 mt-3">
                <TextField
                  id="outlined-basic"
                  label="Calorie Deficit Duration (Days)"
                  variant="outlined"
                  fullWidth
                  type="number"
                  required
                  name="days_for_deficit"
                  onChange={handleChange}
                  value={inputs.days_for_deficit}
                />
              </div>
              {inputs.days_for_deficit > 0 && inputs.days_for_deficit < 31 && (
                <div className="col-12 text-end mt-2">
                  <p className="text-end text-muted">
                    Calorie Deficit Period:{" "}
                    {moment(inputs.deficit_start_date || new Date()).format(
                      "DD-MM-YYYY"
                    )}{" "}
                    to {moment(formattedDate).format("DD-MM-YYYY")}
                  </p>
                </div>
              )}
            </>
          )}
          <Divider className="mt-2" />
          <h3 className="mt-3">Configurations</h3>
          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date of first payment"
              inputFormat="dd/MM/yyyy"
              value={date}
              onChange={handleChangeDate}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </div> */}
          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Coach</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={inputs.coach_id}
              name="coach_id"
              label="Coach"
              onChange={handleChange}
            >
              {coachValue.map((consultant) => {
                return (
                  <MenuItem value={consultant._id}>
                    {consultant.first_name + " " + consultant.last_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div> */}
          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel required id="demo-simple-select-label">
              Goal Statement Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="goal_status"
              value={inputs.goal_status}
              label="Goal Statement Status"
              onChange={handleChange}
            >
              <MenuItem value="false">Lock</MenuItem>
              <MenuItem value="true">Unlock</MenuItem>
            </Select>
          </FormControl>
        </div> */}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <Autocomplete
              value={timeZoneValue}
              onChange={(event, newValue) => {
                setTimeZoneValue(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id="controllable-states-demo"
              options={TimeZones}
              renderInput={(params) => (
                <TextField {...params} label="Time Zone *" />
              )}
            />
          </div>
          <Divider className="mt-2" />
          <div className="col-lg-12 col-md-12 col-sm-12 text-end">
            <FormControlLabel
              control={
                <Switch checked={checkedPace} onChange={handleChangePace} />
              }
              label="Pace Calculator"
            />
          </div>
          {checkedPace && (
            <>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="mt-4">
                  <h3>Pace Calculator</h3>
                  <Typography sx={{ fontSize: 12, opacity: 0.8 }}>
                    Enter results to calculate the pace for the member.
                  </Typography>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="mt-4 select-box">
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-label">
                      Distance
                    </InputLabel>
                    <Select
                      className="svg-color"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={inputs.distance}
                      name="distance"
                      label="Distance"
                      MenuProps={{
                        classes: {
                          paper: classes.paper,
                        },
                      }}
                      onChange={handleChange}
                    >
                      <MenuItem value="5km">5 (km)</MenuItem>
                      <MenuItem value="10km">10 (km)</MenuItem>
                      <MenuItem value="15km">15 (km)</MenuItem>
                      <MenuItem value="half_marathon">Half Marathon</MenuItem>
                      <MenuItem value="marathon">Marathon</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="mt-4">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="Duration "
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      // style: {
                      //   fontSize: "13px",
                      //   paddingLeft: "0px",
                      //   paddingRight: "0px",
                      //   justifyContent: "center",
                      // },
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <TextField
                            id="outlined-basic-hours"
                            type="number"
                            placeholder="Hrs"
                            value={formatWithLeadingZeros(
                              Math.floor(memberVdotData.time_interval / 3600)
                            )}
                            onChange={handleHoursChange}
                            InputProps={
                              {
                                // style: {
                                //   fontSize: "19px",
                                //   fontWeight: "500",
                                // },
                              }
                            }
                            sx={{
                              "& fieldset": {
                                border: "none",
                              },
                              "& input": {
                                textAlign: "center",
                                padding: "0px",
                              },
                              width: "20%",
                            }}
                          />
                          <div className="fs-4 fw-semibold ">:</div>

                          <TextField
                            id="outlined-basic-minutes"
                            type="number"
                            placeholder="Min"
                            value={formatWithLeadingZeros(
                              Math.floor(
                                (memberVdotData.time_interval % 3600) / 60
                              )
                            )}
                            onChange={handleMinutesChange}
                            InputProps={
                              {
                                // style: {
                                //   fontSize: "19px",
                                //   fontWeight: "500",
                                // },
                              }
                            }
                            sx={{
                              "& fieldset": {
                                border: "none",
                              },
                              "& input": {
                                textAlign: "center",
                                padding: "0px",
                              },
                              width: "20%",
                            }}
                          />
                          <div className="fs-4 fw-semibold ">:</div>

                          <TextField
                            id="outlined-basic-seconds"
                            type="number"
                            placeholder="Sec"
                            value={formatWithLeadingZeros(
                              memberVdotData.time_interval % 60
                            )}
                            onChange={handleSecondsChange}
                            InputProps={
                              {
                                // style: {
                                //   fontSize: "19px",
                                //   fontWeight: "500",
                                // },
                              }
                            }
                            sx={{
                              "& fieldset": {
                                border: "none",
                              },
                              "& input": {
                                textAlign: "center",
                                padding: "0px",
                              },
                              width: "20%",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      borderRadius: "5px",
                      fontSize: "13px",
                      width: "100%", // Adjust the width as needed
                    }}
                  />
                </div>
              </div>
            </>
          )}
          <div className="col-12 mt-5">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description "
                multiline
                rows={6}
                name="short_description"
                value={inputs.short_description}
                onChange={handleChange}
              />
              <FormHelperText>
                Member Biography (Maximum limit 500 characters)
              </FormHelperText>
            </FormControl>
          </div>

          <div className="text-end mt-4">
            <button type="submit" className="small-contained-button">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
