import {
  Avatar,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Box,
  Typography,
  Chip,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import SyncIcon from "@mui/icons-material/Sync";

import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "@mui/styles";

import CloseIcon from "@mui/icons-material/Close";

import { s3baseUrl } from "src/config/config";

// import CustomPopovers from "src/components/GeneralComponents/CustomPopovers";

import Label from "src/components/Label";
import { Icon } from "@iconify/react";
// import ExerciseHistoryModel from "./components/ExerciseHistoryModel";
// import { programme_workout_exercise_history_list_api } from "src/DAL/programmeWorkout/programmeWorkout";
import ToshowExercise from "./component/modalToshowExercise";
import ConfirmationWithDescription from "src/components/ModalPopover/ConfirmationWithDescription";
import ParameterModel from "../Workout/components/ParameterModel";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import { htmlDecode } from "src/utils/convertHtml";
import ModalToShowAnExerciseInfo from "../Workout/components/ModalToShowAnExerciseInfo";
import CustomConfirmationOk from "src/components/ModalPopover/ConfirmationOk";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  loading2: {
    position: "absolute",
    top: "50%",
    right: "50%",
    zIndex: 1000,
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));

const RepsForTimeExercisesUI = ({
  addArray,
  setaddArray,
  setModalChangeExe,
  isHistory,
  memberId,
  mainindex,
  message,
  isPersonal,
  isSidebar,
  isWithHistoryAndBigScreen,
}) => {
  const { state } = useLocation();
  const myInputRef = useRef(null);
  const [exerInfo, setExsrInfo] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenForSuperset, setModalOpenForSuperset] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [openSingleExerciseInfo, setOpenSingleExerciseInfo] = useState(false);
  const [singleExerciseInfo, setSingleExerciseInfo] = useState("");
  const [repsRound, setRepsRound] = useState(
    addArray[mainindex]["reps_round"] || [1]
  );
  const [historyModalOpenSuperset, setHistoryModalOpenSuperset] =
    useState(false);
  const [historyModalData, setHistoryModalData] = useState([
    {
      exercise: "",

      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          heightType: "cm",
          height: "",
          distance: "",
          distanceType: "m",
          reps: "",
          maxReps: "",
          rest: "",
        },
      ],
      parameters: ["Weight", "Reps", "Rest"],
      description: "",

      superSet: [],
      superset_sets: "",
    },
  ]);
  const [superAlterIndex, setSuperAlterIndex] = useState("");
  const [alterChanges, setAlterChanges] = useState("");
  const [toAlterChanges, setToAlterChanges] = useState("");
  const [openExerciseAlter, setOpenExerciseAlter] = useState(false);
  const [openSuperSetAlter, setOpenSuperSetAlter] = useState(false);
  const [alterIndex, setalterIndex] = useState();
  const [intervalTime, setIntervalTime] = useState(0);
  const [intervalSeconds, setIntervalSeconds] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const [isReaload, setIsReaload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openPaceAlert, setOpenPaceAlert] = useState(false);
  const [detailsData, setDetailsData] = useState("");
  const [exerIndex, setExerIndex] = useState("");
  const [outerIndex, setOuterIndex] = useState("");

  const [openDetails, setOpenDetails] = useState(false);

  const [isHovered, setIsHovered] = useState("");
  const [isHoveredExercise, setIsHoveredExercise] = useState("");
  const [isExerciseForSuperSet, setIsExerciseForSuperSet] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const handleMinutesChange = (e) => {
    const minutes = parseInt(e.target.value) || 0;
    const seconds = addArray[mainindex].time_interval % 60;
    updateIntervalTime(minutes, seconds);
  };
  const handleChangeComplete = (e, index) => {
    const { checked } = e.target;
    // if (original[index]["is_completed"]) {
    //   return;
    // }
    const list = [...addArray];
    list[index]["is_completed"] = checked;

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleSecondsChange = (e) => {
    let seconds = parseInt(e.target.value) || 0;
    seconds = Math.min(Math.max(seconds, 0), 59); // Ensure the value is between 0 and 60
    const minutes = Math.floor(addArray[mainindex].time_interval / 60);
    updateIntervalTime(minutes, seconds);
  };

  // Function to update intervalTime state
  const updateIntervalTime = (minutes, seconds) => {
    const totalSeconds = minutes * 60 + seconds;
    setIntervalTime(totalSeconds);
    const list = [...addArray];

    list[mainindex]["time_interval"] = totalSeconds;

    setaddArray(list);
  };
  const formatWithLeadingZeros = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };
  const handleMouseEnter = (setIndex, exerciseIndex) => {
    setIsHovered(setIndex);
    setIsHoveredExercise(exerciseIndex);
  };
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Cleanup function to clear the timeout on component unmount
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);
  const handleMouseLeave = () => {
    // Introduce a delay of 100 milliseconds before updating state on hover out
    const delay = 0;
    timeoutRef.current = setTimeout(() => {
      setIsHovered("");
    }, delay);
  };

  const handleAdd = (i) => {
    let list = addArray;

    list.splice(i + 1, 0, {
      exercise: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          heightType: "cm",
          height: "",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          maxReps: "",
          rest: "",
          tempo: "",
        },
      ],
      parameters: ["Weight", "Reps", "Rest"],
      time_interval: 60,
      rounds: 10,
      type: "general",
      emomSets: [[]],
      description: "",
      superSet: [],
      superset_sets: "",
    });

    setaddArray([...list]);
    setModalChangeExe(true);
  };
  const handleAddInterval = (e) => {
    e.preventDefault();
    let list = addArray;
    list[mainindex].emomSets.push([]);
    setaddArray([...list]);
    setModalChangeExe(true);
  };
  const handleAddMoment = (outerIndex, inerIndex) => {
    let list = addArray;
    const lastIndex = list[mainindex].emomSets[outerIndex].length;
    list[mainindex].emomSets[outerIndex].splice(lastIndex, 0, {
      exercise: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          heightType: "cm",
          height: "",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          maxReps: "",
          rest: "",
          tempo: "",
        },
      ],
      parameters: ["Weight", "Reps", "Rest"],
      description: "",
      superSet: [],
      superset_sets: "",
    });

    setaddArray([...list]);
    setModalChangeExe(true);
  };
  const handleAddSet = (mainindex, index) => {
    let list = addArray;
    const data = { ...list[mainindex]["set"][index] };
    list[mainindex]["set"].splice(index + 1, 0, data);
    // ======= just add new set without values
    // list[mainindex]["set"].splice(index + 1, 0, {
    //   time: "",
    //   weight: "",
    //   weightType: "kg",
    //   distance: "",
    //   distanceType: "m",
    //   timeType: "sec",
    //   restType: "sec",
    //   reps: "",
    //   rest: "",
    // });

    setaddArray([...list]);
    setModalChangeExe(true);
  };

  const handleDeleteInterval = (mainindex, index) => {
    let list = addArray;

    list[mainindex]["emomSets"].splice(index, 1);

    setaddArray([...list]);
    setModalChangeExe(true);
  };
  const handleDeleteIntervalExercise = (index, exerIndex) => {
    let list = addArray;

    list[mainindex]["emomSets"][index].splice(exerIndex, 1);

    setaddArray([...list]);
    setModalChangeExe(true);
  };
  const handleDeleteExer = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);

    setaddArray(delValue);
    setModalChangeExe(true);
  };

  const removeSuperSet = (mainIndex, index) => {
    //  const { name, value } = e.target;
    const list = [...addArray];
    let firstPart = list[mainIndex]["superSet"].slice(0, index + 1);
    let secondPart = list[mainIndex]["superSet"].slice(index + 1);
    console.log(firstPart, secondPart, "dlfkjksdhfjas");
    let firstObject = { ...firstPart[0] };
    if (index > 0) {
      firstObject.superSet = firstPart;
      firstObject.superset_sets = list[mainIndex].superset_sets;
      console.log(firstObject, "fdjhsjkdfjkajds1", list[mainIndex]);
      // superset_sets;
    }

    let secondObject = { ...secondPart[0] };
    if (secondPart.length > 1) {
      secondObject.superSet = secondPart;
      secondObject.superset_sets = list[mainIndex].superset_sets;
      console.log(firstObject, "fdjhsjkdfjkajds2", list[mainIndex]);
    }

    let list1 = addArray;
    list1.splice(mainIndex + 1, 0, secondObject);
    list1.splice(mainIndex + 1, 0, firstObject);

    setaddArray([...list1]);
    setModalChangeExe(true);
    handleDeleteExer(mainIndex);
  };

  const handleAgreeSuperSet = (mainindex) => {
    setSuperAlterIndex(mainindex);

    setAlterChanges(
      addArray[mainindex + 1].superSet.length
        ? addArray[mainindex + 1].superset_sets
        : addArray[mainindex + 1].set.length
    );

    setToAlterChanges(
      addArray[mainindex].superSet.length
        ? addArray[mainindex].superset_sets
        : addArray[mainindex].set.length
    );

    const first = addArray[mainindex].superSet.length
      ? addArray[mainindex].superset_sets
      : addArray[mainindex].set.length;
    const second = addArray[mainindex + 1].superSet.length
      ? addArray[mainindex + 1].superset_sets
      : addArray[mainindex + 1].set.length;

    if (first == second) {
      addInSuperSet(mainindex);
      console.log(alterChanges, toAlterChanges, "dfjkhakjsdhfa");
    } else setOpenSuperSetAlter(true);
  };
  const handleAgreeExercise = (mainindex) => {
    setalterIndex(mainindex);

    setAlterChanges(
      addArray[mainindex + 1].superSet.length
        ? addArray[mainindex + 1].superset_sets
        : addArray[mainindex + 1].set.length
    );

    setToAlterChanges(
      addArray[mainindex].superSet.length
        ? addArray[mainindex].superSet.length
        : addArray[mainindex].set.length
    );

    const first = addArray[mainindex].superSet.length
      ? addArray[mainindex].superSet.length
      : addArray[mainindex].set.length;
    const second = addArray[mainindex + 1].superSet.length
      ? addArray[mainindex + 1].superset_sets
      : addArray[mainindex + 1].set.length;

    if (first == second) {
      addSuperSet(mainindex);
    } else {
      setOpenExerciseAlter(true);
    }
  };
  const addAgreeSuperSet = (index) => {
    //  const { name, value } = e.target;

    let current = { ...addArray[alterIndex] };
    current.superSet = [];
    let array = [current];

    if (
      addArray[alterIndex + 1]?.superSet &&
      addArray[alterIndex + 1].superSet.length > 0
    ) {
      addArray[alterIndex + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[alterIndex + 1]);
    }

    const list = [...addArray];
    list[alterIndex]["superSet"] = array;

    setaddArray(list);
    setModalChangeExe(true);

    let e = {
      target: {
        name: "superset_sets",
        value: addArray[alterIndex].set.length,
      },
    };
    handleChangeSuperSetCount(e, alterIndex);
    handleDelete(alterIndex + 1);
    setOpenExerciseAlter(false);
  };
  const addSuperSet = (index) => {
    //  const { name, value } = e.target;

    console.log(index, "dsjkhdkfgs", alterIndex);
    let current = { ...addArray[index] };
    current.superSet = [];
    let array = [current];

    if (
      addArray[index + 1]?.superSet &&
      addArray[index + 1].superSet.length > 0
    ) {
      addArray[index + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[index + 1]);
    }

    const list = [...addArray];
    list[index]["superSet"] = array;

    setaddArray(list);
    setModalChangeExe(true);

    let e = {
      target: {
        name: "superset_sets",
        value: addArray[index].set.length,
      },
    };
    handleChangeSuperSetCount(e, index);
    handleDelete(index + 1);
    setOpenExerciseAlter(false);
  };
  const addInSuperSet = (index) => {
    let array = addArray[index].superSet;
    if (addArray[index + 1].superSet.length > 0) {
      addArray[index + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[index + 1]);
    }
    const list = [...addArray];
    list[index]["superSet"] = array;

    setaddArray(list);
    setModalChangeExe(true);
    let e = {
      target: {
        name: "superset_sets",
        value: list[index].superset_sets,
      },
    };
    handleChangeSuperSetCount(e, index);
    handleDelete(index + 1);
    setOpenSuperSetAlter(false);
  };
  const addAgreeInSuperSet = () => {
    let array = addArray[superAlterIndex].superSet;
    if (addArray[superAlterIndex + 1].superSet.length > 0) {
      addArray[superAlterIndex + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[superAlterIndex + 1]);
    }
    const list = [...addArray];
    list[superAlterIndex]["superSet"] = array;

    setaddArray(list);
    setModalChangeExe(true);
    let e = {
      target: {
        name: "superset_sets",
        value: list[superAlterIndex].superset_sets,
      },
    };
    handleChangeSuperSetCount(e, superAlterIndex);
    handleDelete(superAlterIndex + 1);
    setOpenSuperSetAlter(false);
  };
  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);

    setaddArray(delValue);
    setModalChangeExe(true);
  };

  const handleArrow = (value, index) => {
    //  const { name, value } = e.target;

    const list = [...addArray];
    list[index]["open"] = value;

    setaddArray(list);
  };

  const handleArrowSuperSet = (value, index, exerIndex) => {
    //  const { name, value } = e.target;

    const list = [...addArray];
    list[mainindex]["emomSets"][index][exerIndex]["open"] = value;
    // list[mainindex]["superSet"][exerIndex]["open"] = value;

    setaddArray(list);
  };

  const handleChange = (e, index, index1) => {
    const { name, value } = e.target;

    const list = [...addArray];

    list[index]["set"][index1][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };
  const handleChangeTempo = (e, index, index1) => {
    const { name, value } = e.target;
    if (value.length <= 4) {
      const list = [...addArray];

      list[index]["set"][index1][name] = value;

      setaddArray(list);
      setModalChangeExe(true);
    }
  };
  const handleChangeSuperSetDes = (e, index, index1) => {
    const { name, value } = e.target;
    const list = [...addArray];
    list[index]["superSet"][index1][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeDes = (e, index) => {
    const { name, value } = e.target;

    const list = [...addArray];
    list[index][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };

  const getPaceText = (type) => {
    let result = "";
    if (type?.includes("_200_")) {
      result = "Time Cap";
    } else if (type?.includes("_300_")) {
      result = "Time Cap";
    } else if (type?.includes("_400_")) {
      result = "Time Cap";
    } else if (type?.includes("_600_")) {
      result = "Time Cap";
    } else if (type?.includes("_800_")) {
      result = "Time Cap";
    } else if (type?.includes("_1200_")) {
      result = "Time Cap";
    } else if (type?.includes("mi")) {
      result = "Pace Min/Mile";
    } else if (type?.includes("km")) {
      result = "Pace Min/Km";
    }
    return result;
  };

  const getVdotPace = (index, exerIndex, setIndex) => {
    const list = [...addArray];
    if (
      !list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex]["vdot"]
    ) {
      return;
    }
    const vodt =
      list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex]["vdot"];
    const distanceType =
      list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][
        "race_type"
      ];
    const paceType =
      list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][
        "pace_type"
      ];

    // Example: Find object with vdot value of 33
    const vdot33Object = findPaceObjectByVdot(vodt);
    if (distanceType != "el") {
      list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex]["pace"] =
        vdot33Object[paceType];
      if (!vdot33Object[paceType]) {
        memberId && setOpenPaceAlert(true);
      }
    } else {
      list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex]["pace"] =
        vdot33Object[paceType + "_low"];
      list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex]["paceUp"] =
        vdot33Object[paceType + "_up"];
    }

    setaddArray(list);
    // setModalChangeExe(true);
  };

  const handleChangeVdotPaceType = (e, index, exerIndex, setIndex) => {
    const { name, value } = e.target;

    const list = [...addArray];

    list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][name] =
      value;
    setaddArray(list);
    setModalChangeExe(true);
    getVdotPace(index, exerIndex, setIndex);
  };

  const handleChangeVdotDistance = (e, index, exerIndex, setIndex) => {
    const { name, value } = e.target;
    const list = [...addArray];
    list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][name] =
      value;

    let optionsTypeSArray = [];

    switch (value) {
      case "r":
        optionsTypeSArray = [
          { title: "200 Meters Pace", key: "r_200_m" },
          { title: "300 Meters Pace", key: "r_300_m" },
          { title: "400 Meters Pace", key: "r_400_m" },
          { title: "600 Meters Pace", key: "r_600_m" },
          { title: "800 Meters Pace", key: "r_800_m" },
        ];
        break;

      case "c":
        optionsTypeSArray = [
          { title: "1 KM Pace", key: "c_km" },
          { title: "1 MILE Pace", key: "c_mi" },
        ];
        break;

      case "t":
        optionsTypeSArray = [
          { title: "400 Meters Pace", key: "t_400_m" },
          { title: "1 KM Pace", key: "t_km" },
          { title: "1 MILE Pace", key: "t_mi" },
        ];
        break;

      case "i":
        optionsTypeSArray = [
          { title: "400 Meters Pace", key: "i_400_m" },
          { title: "1 KM Pace", key: "i_km" },
          { title: "1200 Meters Pace", key: "i_1200_m" },
          { title: "1 MILE Pace", key: "i_mi" },
        ];
        break;

      default:
        optionsTypeSArray = [
          { title: "1 KM Pace", key: "e_km" },
          { title: "1 MILE Pace", key: "e_mi" },
        ];
    }

    list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][
      "optionsType"
    ] = optionsTypeSArray;
    list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][
      "pace_type"
    ] = optionsTypeSArray[0].key;

    setaddArray(list);
    setModalChangeExe(true);

    if (list[mainindex]["emomSets"][index].parameters.includes("Vdot"))
      getVdotPace(index, exerIndex, setIndex);
  };

  const handleChangeEmomSet = (e, index, exerIndex, setIndex) => {
    const { name, value } = e.target;

    const list = [...addArray];
    list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][name] =
      value;

    setaddArray(list);
    setModalChangeExe(true);
  };
  const handleChangeEmomSetTempo = (e, index, exerIndex, setIndex) => {
    const { name, value } = e.target;
    if (value.length <= 4) {
      const list = [...addArray];
      list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][name] =
        value;
      setaddArray(list);
      setModalChangeExe(true);
    }
  };
  const handleAddSetCount = (index) => {
    const list = [...addArray];
    const name = "rounds";
    const value = parseInt(list[index].rounds) + 1;
    // console.log(list[index].superset_sets, "ksdfjhkjsdnfkjs");
    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    // list[index]?.superSet.forEach((superSetItem) => {
    //   const currentSetLength = superSetItem.set.length;

    //   const data = { ...superSetItem.set[currentSetLength - 1] };
    //   if (currentSetLength < targetValue) {
    //     const objectsToAdd = targetValue - currentSetLength;

    //     for (let i = 0; i < objectsToAdd; i++) {
    //       superSetItem.set.push(data);
    //     }
    //   } else if (currentSetLength > targetValue) {
    //     // If the new count is less than the current count, remove excess objects
    //     superSetItem.set.splice(targetValue);
    //   }
    // });

    setaddArray(list);
    setModalChangeExe(true);
  };
  const handleRearageSetCount = (index, count) => {
    const list = [...addArray];
    const name = "superset_sets";
    const value = count;
    // console.log(list[index].superset_sets, "ksdfjhkjsdnfkjs");
    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    list[index]?.superSet.forEach((superSetItem) => {
      const currentSetLength = superSetItem.set.length;

      if (currentSetLength < targetValue) {
        const objectsToAdd = targetValue - currentSetLength;

        for (let i = 0; i < objectsToAdd; i++) {
          superSetItem.set.push({
            time: "",
            weight: "",
            weightType: "kg",
            heightType: "cm",
            height: "",
            distance: "",
            distanceType: "m",
            timeType: "sec",
            restType: "sec",
            reps: "",
            maxReps: "",
            rest: "",
          });
        }
      } else if (currentSetLength > targetValue) {
        // If the new count is less than the current count, remove excess objects
        superSetItem.set.splice(targetValue);
      }
    });

    setaddArray(list);
    setModalChangeExe(true);
    setHistoryModalOpenSuperset(false);
  };

  const handleChangeCompletedRounds = (e, index) => {
    const { name, value } = e.target;
    const list = [...addArray];
    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));
    list[index][name] = targetValue;

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeUp = (value) => {
    if (value == 0) {
      enqueueSnackbar("You are already on Top !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value - 1, 0, reorderedItem);

    setaddArray(items);
    setModalChangeExe(true);
  };

  const handleExerciseList = (value) => {
    setIsExerciseForSuperSet(false);
    setDetailsData(value);
    setOpenDetails(true);
  };
  const handleSupersetExerciseList = (mainIndex, exerInde) => {
    setDetailsData(mainIndex);
    setExerIndex(exerInde);
    setIsExerciseForSuperSet(true);
  };
  const handleSelectExercise = (value) => {
    const list = [...addArray];
    if (list[detailsData]["exercise"]._id == value._id) {
      return;
    }
    list[detailsData]["exercise"] = value;
    list[detailsData]["description"] = value?.instructions;

    setaddArray(list);
    setModalChangeExe(false);
    if (isHistory) handleHistory(value, detailsData);
  };
  const handleCopyExercise = (value) => {
    const list = [...addArray];
    list[detailsData]["set"] = value.set;
    list[detailsData]["parameters"] = value?.parameters;
    setaddArray(list);
    setModalChangeExe(true);
    setHistoryModalOpen(false);
  };
  const handleCopySupersetExercise = (value) => {
    const list = [...addArray];
    console.log(list[detailsData], "dskjfjkdshafkja");
    list[detailsData]["superSet"][exerIndex]["set"] = value.set;
    list[detailsData]["superSet"][exerIndex]["parameters"] = value?.parameters;

    setaddArray(list);
    setModalChangeExe(true);

    handleRearageSetCount(detailsData, list[detailsData].superset_sets);
  };
  const handleSelectSupersetExercise = (value) => {
    const list = [...addArray];

    // return;
    // console.log(
    //   list[mainindex]["emomSets"][detailsData][exerIndex]["exercise"],
    //   "dsfkjhksjdhfja",
    //   list[mainindex]["emomSets"][detailsData],
    //   value
    // );

    list[mainindex]["emomSets"][detailsData][exerIndex]["exercise"] = value;
    list[mainindex]["emomSets"][detailsData][exerIndex]["description"] =
      value?.instructions;
    list[mainindex]["emomSets"][detailsData][exerIndex]["open"] = true;

    setaddArray(list);
    setModalChangeExe(true);
    if (isHistory) handleHistorySuperset(value, detailsData, exerIndex);
  };

  const handleChangeDown = (value) => {
    if (value >= addArray.length - 1) {
      enqueueSnackbar("You are already on Bottom !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value + 1, 0, reorderedItem);

    setaddArray(items);
    setModalChangeExe(true);
  };

  const handleParameters = (value) => {
    setModalOpen(true);
    setModalOpenForSuperset(false);
    setOuterIndex(value);
    setExsrInfo(addArray[value].parameters);
  };
  const handleHistoryOpen = async (value, index) => {
    setDetailsData(index);

    setHistoryModalData(value.history);

    setHistoryModalOpen(true);
  };
  const handleHistory = async (value, index) => {
    setDetailsData(index);
    let postData = {
      exercise: value?._id,
      member: memberId,
    };

    try {
      let result = await programme_workout_exercise_history_list_api(postData);
      if (result.code == 200) {
        if (!result.exercise_history.length > 0) {
          const list = [...addArray];
          delete list[index]["history"];
          setaddArray(list);

          return;
        }
        let givenExercise = result.exercise_history.map((item) => {
          return {
            ...item,
            date: item?.date,
            exercise: item?.exercise_info,
            set: item?.sets.map((item1) => {
              return {
                time: item1?.duration,
                weight: item1?.weight,
                weightType: item1?.weight_unit,
                reps: item1?.reps,
                maxReps: item1?.maxReps,
                rest: item1?.rest,
                distance: item1?.distance,
                distanceType: item1?.distance_unit,
                timeType: item1?.duration_unit,
                restType: item1?.rest_unit,
                tempo: item1?.tempo,
                vdot: item1?.vdot ? item1?.vdot : null,
                pace_type: item1?.pace_type ? item1?.pace_type : "c_km",
                paceUp: item1?.pace_up ? item1?.pace_up : 0,
                optionsType:
                  item1?.pace_options && item1?.pace_options.length > 0
                    ? item1?.pace_options
                    : [
                        { title: "1 KM Pace", key: "c_km" },
                        { title: "1 MILE Pace", key: "c_mi" },
                      ],
              };
            }),
            parameters: item?.parameters,
            open: true,
          };
        });

        setHistoryModalData(givenExercise);

        // setHistoryModalOpen(true);
        const list = [...addArray];
        list[index]["history"] = givenExercise;
        setaddArray(list);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleHistorySupersetOpen = async (value, mainIndex, exerInde) => {
    setDetailsData(mainIndex);
    setExerIndex(exerInde);
    setHistoryModalData(value.history);
    setHistoryModalOpenSuperset(true);
  };
  const handleHistorySuperset = async (value, mainIndex, exerInde) => {
    setDetailsData(mainIndex);
    setExerIndex(exerInde);
    let postData = {
      exercise: value?._id,
      member: memberId,
    };

    try {
      let result = await programme_workout_exercise_history_list_api(postData);
      if (result.code == 200) {
        if (!result.exercise_history.length > 0) {
          const list = [...addArray];
          delete list[mainindex]["emomSets"][detailsData][exerIndex]["history"];
          // delete list[mainIndex]["superSet"][exerInde]["history"];
          setaddArray(list);

          return;
        }
        let givenExercise = result.exercise_history.map((item) => {
          return {
            ...item,
            date: item?.date,
            exercise: item?.exercise_info,
            set: item?.sets.map((item1) => {
              return {
                time: item1?.duration,
                weight: item1?.weight,
                weightType: item1?.weight_unit,
                reps: item1?.reps,
                maxReps: item1?.maxReps,
                rest: item1?.rest,
                distance: item1?.distance,
                distanceType: item1?.distance_unit,
                timeType: item1?.duration_unit,
                restType: item1?.rest_unit,
                tempo: item1?.tempo,
                vdot: item1?.vdot ? item1?.vdot : null,
                pace_type: item1?.pace_type ? item1?.pace_type : "c_km",
                paceUp: item1?.pace_up ? item1?.pace_up : 0,
                optionsType:
                  item1?.pace_options && item1?.pace_options.length > 0
                    ? item1?.pace_options
                    : [
                        { title: "1 KM Pace", key: "c_km" },
                        { title: "1 MILE Pace", key: "c_mi" },
                      ],
              };
            }),
            parameters: item?.parameters,
            open: true,
          };
        });
        console.log(givenExercise, "kjdshakfjsdfhaj");
        setHistoryModalData(givenExercise);
        // setIsLoading(false);
        // setHistoryModalOpenSuperset(true);
        const list = [...addArray];
        list[mainindex]["emomSets"][detailsData][exerIndex]["history"] =
          givenExercise;
        setaddArray(list);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleParametersSuperset = (main, inr) => {
    setModalOpen(true);
    setModalOpenForSuperset(true);
    setOuterIndex(main);
    setExerIndex(inr);
    setExsrInfo(addArray[mainindex].emomSets[main][inr].parameters);
  };
  const handleChangeParametersInsetFieldValueSuperSet = (
    value,
    outerIndex,
    exerIndex
  ) => {
    const items = Array.from(addArray);

    const sets = items[mainindex].emomSets[outerIndex][exerIndex].set.map(
      (item) => {
        return {
          time: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Duration/Pace")
            ? item?.time
            : "",
          weight: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Weight")
            ? item?.weight
            : "",
          weightType: item?.weightType,
          reps: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Reps")
            ? item?.reps
            : "",
          maxReps: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Max_Reps")
            ? item?.maxReps
            : "",
          rest: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Rest")
            ? item?.rest
            : "",
          distance: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Distance/Calories")
            ? item?.distance
            : "",
          distanceType: item?.distanceType,
          timeType: item?.timeType,
          restType: item?.restType,
          tempo: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Tempo")
            ? item?.tempo
            : "",
        };
      }
    );
    // setExsrInfo(items[outerIndex].superSet[exerIndex].parameters);
    items[mainindex].emomSets[outerIndex][exerIndex].set = sets;
    setaddArray(items);
  };
  const handleChangeParametersInsetFieldValue = (value, outerIndex) => {
    const items = Array.from(addArray);
    const sets = items[outerIndex].set.map((item) => {
      return {
        time: items[outerIndex].parameters.includes("Duration/Pace")
          ? item?.time
          : "",
        weight: items[outerIndex].parameters.includes("Weight")
          ? item?.weight
          : "",
        weightType: item?.weightType,
        reps: items[outerIndex].parameters.includes("Reps") ? item?.reps : "",
        maxReps: items[outerIndex].parameters.includes("Max_Reps")
          ? item?.maxReps
          : "",
        rest: items[outerIndex].parameters.includes("Rest") ? item?.rest : "",
        distance: items[outerIndex].parameters.includes("Distance/Calories")
          ? item?.distance
          : "",
        distanceType: item?.distanceType,
        timeType: item?.timeType,
        restType: item?.restType,
        tempo: items[outerIndex].parameters.includes("Tempo")
          ? item?.tempo
          : "",
      };
    });
    console.log(items[outerIndex], "sdkfjklsdjfjlkakj");
    // setExsrInfo(items[outerIndex].parameters);
    items[outerIndex].set = sets;
    setaddArray(items);
  };
  const handleChangeParametersInset = (value) => {
    const items = Array.from(addArray);
    if (modalOpenForSuperset) {
      items[mainindex].emomSets[outerIndex][exerIndex].parameters = value;
      setExsrInfo(items[mainindex].emomSets[outerIndex][exerIndex].parameters);
      setaddArray(items);
      setModalChangeExe(true);
      handleChangeParametersInsetFieldValueSuperSet(
        value,
        outerIndex,
        exerIndex
      );
      return;
    }
    items[outerIndex].parameters = value;
    setExsrInfo(items[outerIndex].parameters);
    setaddArray(items);
    setModalChangeExe(true);
    handleChangeParametersInsetFieldValue(value, outerIndex);
  };

  const memueOptions = (value) => {
    const MENU_OPTIONS = [];
    MENU_OPTIONS.unshift(
      {
        label: "Move Up",
        icon: "mdi:arrow-up",
        handleClick: handleChangeUp,
        disabled: value == 0 ? true : false,
      },
      {
        label: "Move Down",
        icon: "ic:outline-arrow-downward",
        handleClick: handleChangeDown,
        disabled: value >= addArray.length - 1 ? true : false,
      }
      // {
      //   label: "Parameters",
      //   icon: "pajamas:labels",
      //   handleClick: handleParameters,
      // }
    );

    return MENU_OPTIONS;
  };
  const getPaceTextinPaces = (type) => {
    let result = "";
    if (type == "m") {
      result = "Time Cap";
    } else if (type == "mi") {
      result = "Pace Min/Mile";
    } else if (type == "km") {
      result = "Pace Min/Km";
    }
    return result;
  };
  const getKmhrForPace = (value, time, type) => {
    if (!value || !time) {
      return 0;
    }
    let result = 0;
    if (type == "m") {
      result = (value / time) * 3.6;
    } else if (type == "mi") {
      result = ((1 * 1000) / time) * 3.6 * 1.609;
    } else if (type == "km") {
      result = ((1 * 1000) / time) * 3.6;
    }
    return result.toFixed(2);
  };
  const getMihrForPace = (value, time, type) => {
    if (!value || !time) {
      return 0;
    }
    let result = 0;
    if (type == "m") {
      result = result = ((value / time) * 3.6) / 1.609;
    } else if (type == "mi") {
      result = ((1 * 1000) / time) * 3.6;
    } else if (type == "km") {
      result = (((1 * 1000) / time) * 3.6) / 1.609;
    }
    return result.toFixed(2);
  };

  const handleChangeCompletedRoundsReps = (e, index) => {
    const value = parseInt(e.target.value);
    const updatedAddArray = [...addArray];

    updatedAddArray[mainindex]["reps_round"] =
      updatedAddArray[mainindex]["reps_round"] || [];
    updatedAddArray[mainindex]["reps_round"][index] = value;

    setaddArray(updatedAddArray);
    setRepsRound(updatedAddArray[mainindex]["reps_round"]);
  };

  const handleAddRepsRound = () => {
    const updatedAddArray = [...addArray];

    updatedAddArray[mainindex]["reps_round"] = updatedAddArray[mainindex][
      "reps_round"
    ] || [...repsRound];

    const updatedRepsRound = [
      ...updatedAddArray[mainindex]["reps_round"],
      updatedAddArray[mainindex]["reps_round"].length + 1,
    ];

    updatedAddArray[mainindex]["reps_round"] = updatedRepsRound;
    setRepsRound(updatedRepsRound);
    setaddArray(updatedAddArray);
  };

  const handleDeleteRepsRound = () => {
    const updatedAddArray = [...addArray];

    updatedAddArray[mainindex]["reps_round"] = updatedAddArray[mainindex][
      "reps_round"
    ] || [...repsRound];

    const updatedRepsRound = updatedAddArray[mainindex]["reps_round"].slice(
      0,
      -1
    );

    updatedAddArray[mainindex]["reps_round"] = updatedRepsRound;
    setRepsRound(updatedRepsRound);
    setaddArray(updatedAddArray);
  };

  const handleMinutesChangeSets = (e, index, exerIndex, setIndex) => {
    const { name, value } = e.target;

    const list = [...addArray];

    const minutes = parseInt(e.target.value) || 0;
    const seconds =
      list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][name] % 60;
    updateIntervalTimeSets(minutes, seconds, index, exerIndex, setIndex, name);
  };

  const handleSecondsChangeSets = (e, index, exerIndex, setIndex) => {
    const { name, value } = e.target;
    const list = [...addArray];

    let seconds = parseInt(e.target.value) || 0;
    seconds = Math.min(Math.max(seconds, 0), 59); // Ensure the value is between 0 and 60
    const minutes = Math.floor(
      list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][name] / 60
    );
    updateIntervalTimeSets(minutes, seconds, index, exerIndex, setIndex, name);
  };

  // Function to update intervalTime state
  const updateIntervalTimeSets = (
    minutes,
    seconds,
    index,
    exerIndex,
    setIndex,
    name
  ) => {
    const totalSeconds = minutes * 60 + seconds;
    const list = [...addArray];
    list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][name] =
      totalSeconds;
    setaddArray(list);
    setModalChangeExe(true);
  };
  const handleClickOpen = async (exercise) => {
    setSingleExerciseInfo(exercise);
    setOpenSingleExerciseInfo(true);
  };
  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="my-2">
      {isLoading && (
        <CircularProgress className={classes.loading2} color="primary" />
      )}
      <CustomConfirmationOk
        open={openPaceAlert}
        setOpen={setOpenPaceAlert}
        title="Oops!"
        descriotion="This type & distance not suitable for that pace"
      />
      <ConfirmationWithDescription
        open={openSuperSetAlter}
        setOpen={setOpenSuperSetAlter}
        title={"Are you sure you want to take this action?"}
        descriotion={`You are trying to alternate an exercise with  ${toAlterChanges} sets with an exercise with ${alterChanges} sets. If you proceed, we’ll modify the exercise to have  ${toAlterChanges} sets.`}
        handleAgree={addAgreeInSuperSet}
      />
      <ConfirmationWithDescription
        open={openExerciseAlter}
        setOpen={setOpenExerciseAlter}
        title={"Are you sure you want to take this action?"}
        descriotion={`You are trying to alternate an exercise with  ${toAlterChanges} sets with an exercise with ${alterChanges} sets. If you proceed, we’ll modify the exercise to have  ${toAlterChanges} sets.`}
        handleAgree={addAgreeSuperSet}
      />
      <ModalToShowAnExerciseInfo
        open={openSingleExerciseInfo}
        setOpen={setOpenSingleExerciseInfo}
        Id={singleExerciseInfo}
      />
      <ToshowExercise
        open={openDetails}
        setOpen={setOpenDetails}
        handleChange={handleSelectExercise}
      />
      <ToshowExercise
        open={isExerciseForSuperSet}
        setOpen={setIsExerciseForSuperSet}
        handleChange={handleSelectSupersetExercise}
      />
      <ParameterModel
        open={modalOpen}
        setOpen={setModalOpen}
        data={exerInfo}
        handleChange={handleChangeParametersInset}
        addArray={addArray}
      />

      <div className="mt-2 ">
        <div className="characters">
          <form onSubmit={() => {}}>
            <div
              style={{ position: "relative" }}
              className="workout-Superset-card my-1"
            >
              <Box>
                <div className="row mb-3">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="d-flex align-items-center flex-wrap">
                      <div>Reps</div>
                      {repsRound.map((item, index) => {
                        return (
                          <div className={``}>
                            <Tooltip touch="on" placement="top" title={message}>
                              <TextField
                                id="outlined-basic"
                                size="small"
                                variant="outlined"
                                placeholder=""
                                type="number"
                                value={item}
                                name="total_rounds"
                                onChange={(e) =>
                                  handleChangeCompletedRoundsReps(e, index)
                                }
                                InputLabelProps={{
                                  className: "textfiels-input-label",
                                  shrink: true,
                                  style: { fontSize: "14px" },
                                }}
                                InputProps={{
                                  className: "textfiels-input-value",
                                  style: { fontSize: "13px", padding: "0" },
                                  readOnly: true,
                                }}
                                sx={{
                                  mx: 1,
                                  borderRadius: "5px",
                                  "& legend": { display: "none" },
                                  "& fieldset": { top: 0 },
                                  fontSize: "13px",
                                  "& input": {
                                    padding: "5px 5px",
                                    textAlign: "center",
                                  },
                                  width: "33px",
                                }}
                              />{" "}
                            </Tooltip>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div
                    className={`col-sm-12 col-md-7 col-lg-7 order-3 order-md-2`}
                  >
                    <div className="d-flex align-items-center justify-content-end text-center text-md-start  my-2">
                      Reps For Time
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-5 col-lg-5  cross-icon order-1 order-md-3 d-flex justify-content-end">
                    <>
                      <Tooltip title="Remove Exercise">
                        <RemoveCircleOutlineIcon
                          onClick={() => handleDelete(mainindex)}
                          className="diary-icon-remove"
                        />
                      </Tooltip>
                    </>
                    <Tooltip title="Add Exercise">
                      <AddCircleOutlineIcon
                        onClick={() => handleAdd(mainindex)}
                        className="diary-icon-add"
                      />
                    </Tooltip>

                    <CustomPopoverSection
                      menu={memueOptions(mainindex)}
                      data={mainindex}
                    />
                  </div>
                </div>

                {addArray[mainindex].emomSets.length > 0 &&
                  addArray[mainindex].emomSets.map((item, index) => {
                    return (
                      <div className=" my-2 ">
                        <div className="d-flex justify-content-between align-items-center ">
                          <div className="fs-5">Movements</div>
                        </div>

                        {item.length > 0 &&
                          item.map((exerData, exerIndex) => {
                            return (
                              <div className="ms-4 my-1">
                                <div
                                  className="d-flex workout-set-card  "
                                  style={{
                                    width: "100%",
                                    position: "relative",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      position: "relative",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      className="row  "
                                      onMouseEnter={() => handleMouseLeave()}
                                    >
                                      <div
                                        className={`col-sm-12 ${"col-md-6"} col-lg-6 d-flex align-items-center p-0 mt-2 mt-md-0 mt-lg-0 `}
                                      >
                                        <div className="exercise-label-box">
                                          <span className="outer-label">
                                            Exercise
                                          </span>
                                          <div className="d-flex align-items-center justify-content-between">
                                            <Tooltip
                                              placement="top"
                                              touch="on"
                                              title={message}
                                            >
                                              <div className="w-100">
                                                <div className="exercise-label w-100">
                                                  <div className="d-flex align-items-center">
                                                    {exerData?.exercise?.image
                                                      ?.thumbnail_3 && (
                                                      <Avatar
                                                        sx={{
                                                          borderRadius: "5px",
                                                        }}
                                                        alt="User Image"
                                                        src={
                                                          s3baseUrl +
                                                          exerData?.exercise
                                                            ?.image?.thumbnail_3
                                                        }
                                                      />
                                                    )}
                                                    <div className="label-text ms-1">
                                                      {exerData?.exercise?.title
                                                        ? exerData?.exercise
                                                            ?.title
                                                        : ""}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Tooltip>
                                            <div className="exercise-action">
                                              <div style={{ fontSize: "16px" }}>
                                                {exerData?.exercise ? (
                                                  <div>
                                                    <InfoIcon
                                                      onClick={() =>
                                                        handleClickOpen(
                                                          exerData?.exercise
                                                            ?._id
                                                        )
                                                      }
                                                      className="d-block"
                                                      fontSize="14px"
                                                      sx={{
                                                        opacity: 0.5,
                                                        cursor: "pointer",
                                                      }}
                                                    />
                                                  </div>
                                                ) : (
                                                  <AddIcon
                                                    sx={{
                                                      opacity: 0.5,
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <Button
                                          sx={{ textTransform: "inherit" }}
                                          size="small"
                                          color="primary"
                                          variant="contained"
                                          className="d-flex align-items-center ms-2 small-contained-dialog-button"
                                          onClick={() => {
                                            handleArrowSuperSet(
                                              !exerData?.open ? true : false,
                                              index,
                                              exerIndex
                                            );
                                          }}
                                        >
                                          {!exerData?.open ? (
                                            <KeyboardArrowDownIcon
                                              sx={{
                                                cursor: "pointer",
                                                // opacity: 0.5,
                                                fontSize: "20px",
                                              }}
                                            />
                                          ) : (
                                            <KeyboardArrowUpIcon
                                              sx={{
                                                cursor: "pointer",
                                                // opacity: 0.5,
                                                fontSize: "20px",
                                              }}
                                            />
                                          )}

                                          <Typography
                                            variant="body2"
                                            sx={{
                                              // opacity: 0.6,
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {` Sets ${1}`}
                                          </Typography>
                                        </Button>
                                      </div>

                                      <div
                                        className={`col-sm-12 ${"col-md-5"} col-lg-6 p-0 mt-2 mt-md-0 mt-lg-0  d-flex align-items-center justify-content-start `}
                                      >
                                        <div className="d-flex align-items-center mx-1">
                                          <Tooltip
                                            touch="on"
                                            placement="top"
                                            title={message}
                                          >
                                            <div className="d-flex align-items-center ms-3 set-parameters ">
                                              <Icon
                                                icon="pajamas:labels"
                                                fontSize="10px"
                                              />
                                              <Typography
                                                variant="body2"
                                                sx={{ ml: 0.5 }}
                                              >
                                                {`Parameters`}
                                              </Typography>
                                            </div>
                                          </Tooltip>
                                        </div>
                                      </div>
                                    </div>

                                    {exerData?.open &&
                                      exerData?.set?.map((item, setIndex) => (
                                        <div className="d-flex align-items-center my-3 w-100 workout-card-inset">
                                          <Tooltip
                                            placement="top"
                                            title={message}
                                          >
                                            <div className="row w-100">
                                              {exerData.parameters.includes(
                                                "Vdot"
                                              ) && (
                                                <>
                                                  {memberId && (
                                                    <div
                                                      className={
                                                        isWithHistoryAndBigScreen
                                                          ? "col-2 pe-0 py-1"
                                                          : `col-12 col-md-2 col-lg-1 pe-0 py-1`
                                                      }
                                                    >
                                                      <TextField
                                                        id="outlined-basic"
                                                        readOnly
                                                        size="small"
                                                        variant="outlined"
                                                        placeholder="0"
                                                        type="number"
                                                        onMouseLeave={() =>
                                                          handleMouseEnter(
                                                            setIndex,
                                                            mainindex
                                                          )
                                                        }
                                                        onClick={() =>
                                                          handleMouseLeave()
                                                        }
                                                        label="Auto Pacer"
                                                        value={item?.vdot}
                                                        name="vdot"
                                                        InputLabelProps={{
                                                          className:
                                                            "textfiels-input-label",
                                                          shrink: true,
                                                          style: {
                                                            fontSize: "14px",
                                                          },
                                                        }}
                                                        InputProps={{
                                                          className:
                                                            "textfiels-input-value",
                                                          style: {
                                                            fontSize: "13px",
                                                            paddingLeft: "0px",
                                                            paddingRight: "5px",
                                                          },
                                                          readOnly: true,
                                                        }}
                                                        sx={{
                                                          borderRadius: "5px",
                                                          fontSize: "13px",
                                                          width: "100%",
                                                          marginTop: "5px",
                                                        }}
                                                      />
                                                    </div>
                                                  )}
                                                  <div
                                                    className={
                                                      isWithHistoryAndBigScreen
                                                        ? "col-5 pe-0 py-1"
                                                        : `col-12 col-md-5 ${
                                                            isSidebar
                                                              ? "col-lg-4"
                                                              : "col-lg-3"
                                                          } pe-0 py-1`
                                                    }
                                                  >
                                                    <TextField
                                                      id="outlined-basic"
                                                      variant="outlined"
                                                      label="Type"
                                                      readOnly
                                                      InputLabelProps={{
                                                        readOnly: true,
                                                        className:
                                                          "textfiels-input-label",
                                                        shrink: true,
                                                        style: {
                                                          fontSize: "14px",
                                                        },
                                                      }}
                                                      InputProps={{
                                                        className:
                                                          "textfiels-input-value",
                                                        style: {
                                                          fontSize: "13px",
                                                          paddingLeft: "0px",
                                                          paddingRight: "0px",

                                                          justifyContent:
                                                            "center",
                                                        },
                                                        endAdornment: (
                                                          <InputAdornment
                                                            position="end"
                                                            style={{
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                            }}
                                                          >
                                                            <Select
                                                              id="select-option"
                                                              readOnly
                                                              variant="outlined"
                                                              size="small"
                                                              name="race_type"
                                                              value={
                                                                item?.race_type
                                                              }
                                                              label="Weight Type"
                                                              inputProps={{
                                                                readOnly: true,
                                                                style: {
                                                                  padding: 0,
                                                                },
                                                              }}
                                                              onChange={(e) => {
                                                                handleChangeVdotDistance(
                                                                  e,
                                                                  index,
                                                                  exerIndex,
                                                                  setIndex
                                                                );
                                                              }}
                                                              sx={{
                                                                "& legend": {
                                                                  display:
                                                                    "none",
                                                                },
                                                                "& fieldset": {
                                                                  top: 0,
                                                                },
                                                                fontSize:
                                                                  "13px",
                                                                border: "none",
                                                                "& fieldset": {
                                                                  border:
                                                                    "none",
                                                                },
                                                                "& .MuiOutlinedInput-input":
                                                                  {
                                                                    padding: 0,
                                                                  },
                                                              }}
                                                            >
                                                              <MenuItem value="el">
                                                                Easy/Long
                                                              </MenuItem>
                                                              <MenuItem value="c">
                                                                Compromised
                                                              </MenuItem>
                                                              <MenuItem value="t">
                                                                Threshold
                                                              </MenuItem>
                                                              <MenuItem value="i">
                                                                Interval
                                                              </MenuItem>
                                                              <MenuItem value="r">
                                                                Repitition
                                                              </MenuItem>
                                                            </Select>

                                                            <Select
                                                              id="select-option"
                                                              variant="outlined"
                                                              readOnly
                                                              size="small"
                                                              name="pace_type"
                                                              value={
                                                                item?.pace_type
                                                              }
                                                              label="Weight Type"
                                                              inputProps={{
                                                                readOnly: true,
                                                                style: {
                                                                  padding: 0,
                                                                },
                                                              }}
                                                              onChange={(e) => {
                                                                handleChangeVdotPaceType(
                                                                  e,
                                                                  index,
                                                                  exerIndex,
                                                                  setIndex
                                                                );
                                                              }}
                                                              sx={{
                                                                "& legend": {
                                                                  display:
                                                                    "none",
                                                                },
                                                                "& fieldset": {
                                                                  top: 0,
                                                                },
                                                                fontSize:
                                                                  "13px",
                                                                border: "none",
                                                                "& fieldset": {
                                                                  border:
                                                                    "none",
                                                                },
                                                                "& .MuiOutlinedInput-input":
                                                                  {
                                                                    padding: 0,
                                                                  },
                                                              }}
                                                            >
                                                              {item?.optionsType?.map(
                                                                (option) => {
                                                                  return (
                                                                    <MenuItem
                                                                      key={
                                                                        option?.key
                                                                      }
                                                                      value={
                                                                        option?.key
                                                                      }
                                                                    >
                                                                      {
                                                                        option?.title
                                                                      }
                                                                    </MenuItem>
                                                                  );
                                                                }
                                                              )}
                                                            </Select>
                                                          </InputAdornment>
                                                        ),
                                                      }}
                                                      sx={{
                                                        borderRadius: "5px",
                                                        fontSize: "13px",

                                                        marginTop: "5px",
                                                        "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                                                          {
                                                            width:
                                                              "0px !important",
                                                            padding:
                                                              "9px 0px !important",
                                                          },
                                                        "& .css-1nuss9t": {
                                                          width:
                                                            "0px !important",
                                                          padding:
                                                            "9px 0px !important",
                                                        },

                                                        width: "100%",
                                                      }}
                                                    />
                                                  </div>
                                                  {exerData.parameters.includes(
                                                    "Distance"
                                                  ) && (
                                                    <div
                                                      className={
                                                        isWithHistoryAndBigScreen
                                                          ? "col-3 pe-0 py-2"
                                                          : `col-12 col-md-3 col-lg-2 pe-0 py-2`
                                                      }
                                                    >
                                                      <TextField
                                                        sx={{
                                                          borderRadius: "5px",
                                                          paddingRight: 0,
                                                          width: "100%",
                                                        }}
                                                        readOnly
                                                        size="small"
                                                        id="custom-input"
                                                        variant="outlined"
                                                        placeholder={"Distance"}
                                                        name="distance"
                                                        label="Distance"
                                                        value={item?.distance}
                                                        onChange={(e) => {
                                                          handleChangeEmomSet(
                                                            e,
                                                            index,
                                                            exerIndex,
                                                            setIndex
                                                          );
                                                        }}
                                                        type="number"
                                                        InputLabelProps={{
                                                          className:
                                                            "textfiels-input-label",
                                                          shrink: true,
                                                          style: {
                                                            fontSize: "14px",
                                                          },
                                                        }}
                                                        InputProps={{
                                                          readOnly: true,
                                                          className:
                                                            "textfiels-input-value",
                                                          style: {
                                                            fontSize: "13px",
                                                            paddingRight: 0,
                                                          },
                                                          endAdornment: (
                                                            <InputAdornment position="end">
                                                              <Select
                                                                id="select-option"
                                                                variant="outlined"
                                                                readOnly
                                                                size="small"
                                                                name="distanceType"
                                                                value={
                                                                  item?.distanceType
                                                                }
                                                                label="Weight Type"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleChangeEmomSet(
                                                                    e,
                                                                    index,
                                                                    exerIndex,
                                                                    setIndex
                                                                  );
                                                                }}
                                                                sx={{
                                                                  "& legend": {
                                                                    display:
                                                                      "none",
                                                                  },
                                                                  "& fieldset":
                                                                    {
                                                                      top: 0,
                                                                    },
                                                                  fontSize:
                                                                    "13px",
                                                                  border:
                                                                    "none",
                                                                  "& fieldset":
                                                                    {
                                                                      border:
                                                                        "none",
                                                                    },
                                                                }}
                                                              >
                                                                <MenuItem value="m">
                                                                  Meter
                                                                </MenuItem>
                                                                <MenuItem value="km">
                                                                  KM
                                                                </MenuItem>
                                                                <MenuItem value="mi">
                                                                  Mile
                                                                </MenuItem>
                                                              </Select>
                                                            </InputAdornment>
                                                          ),
                                                        }}
                                                      />
                                                    </div>
                                                  )}
                                                  {memberId ? (
                                                    item?.race_type == "el" ? (
                                                      <div
                                                        className={
                                                          isWithHistoryAndBigScreen
                                                            ? "col-3 pe-0 py-1"
                                                            : `col-12 col-md-3 col-lg-2 pe-0 py-1`
                                                        }
                                                      >
                                                        <TextField
                                                          readOnly
                                                          id="outlined-basic"
                                                          variant="outlined"
                                                          label={getPaceText(
                                                            item?.pace_type
                                                          )}
                                                          InputLabelProps={{
                                                            className:
                                                              "textfiels-input-label",
                                                            shrink: true,
                                                            style: {
                                                              fontSize: "14px",
                                                            },
                                                          }}
                                                          InputProps={{
                                                            className:
                                                              "textfiels-input-value",
                                                            style: {
                                                              fontSize: "13px",
                                                              paddingLeft:
                                                                "0px",
                                                              paddingRight:
                                                                "0px",

                                                              justifyContent:
                                                                "center",
                                                            },
                                                            readOnly: true,
                                                            endAdornment: (
                                                              <InputAdornment
                                                                position="end"
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                }}
                                                              >
                                                                <TextField
                                                                  id="outlined-basic-minutes"
                                                                  readOnly
                                                                  type="number"
                                                                  size="small"
                                                                  placeholder="Min"
                                                                  name="pace"
                                                                  value={formatWithLeadingZeros(
                                                                    Math.floor(
                                                                      item?.pace /
                                                                        60
                                                                    )
                                                                  )}
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleMinutesChange(
                                                                      e,
                                                                      index,
                                                                      exerIndex,
                                                                      setIndex
                                                                    )
                                                                  }
                                                                  InputProps={{
                                                                    className:
                                                                      "textfiels-input-value",
                                                                    style: {
                                                                      fontSize:
                                                                        "14px",
                                                                      // width: "40px",
                                                                    },
                                                                    readOnly: true,
                                                                  }}
                                                                  sx={{
                                                                    "& fieldset":
                                                                      {
                                                                        border:
                                                                          "none",
                                                                      },
                                                                    // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                                    //   {
                                                                    //     padding: "0px !important",
                                                                    //   },
                                                                    "& input": {
                                                                      textAlign:
                                                                        "center",
                                                                      padding:
                                                                        "0px",
                                                                    },
                                                                    width:
                                                                      "100%",
                                                                  }}
                                                                />
                                                                <div className="fs-6 fw-semibold ms-1 ">
                                                                  :
                                                                </div>

                                                                <TextField
                                                                  id="outlined-basic-seconds"
                                                                  type="number"
                                                                  readOnly
                                                                  size="small"
                                                                  placeholder="Sec"
                                                                  name="pace"
                                                                  value={formatWithLeadingZeros(
                                                                    item?.pace %
                                                                      60
                                                                  )}
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleSecondsChange(
                                                                      e,
                                                                      index,
                                                                      exerIndex,
                                                                      setIndex
                                                                    )
                                                                  }
                                                                  InputProps={{
                                                                    className:
                                                                      "textfiels-input-value",
                                                                    style: {
                                                                      fontSize:
                                                                        "14px",
                                                                      // width: "40px",
                                                                    },
                                                                    readOnly: true,
                                                                  }}
                                                                  sx={{
                                                                    "& fieldset":
                                                                      {
                                                                        border:
                                                                          "none",
                                                                      },
                                                                    // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                                    //   {
                                                                    //     padding: "0px !important",
                                                                    //   },
                                                                    "& input": {
                                                                      textAlign:
                                                                        "center",
                                                                      padding:
                                                                        "0px",
                                                                    },
                                                                    width:
                                                                      "100%",
                                                                  }}
                                                                />

                                                                <div className="fs-6 fw-semibold ms-1 me-1 ">
                                                                  -
                                                                </div>

                                                                <TextField
                                                                  id="outlined-basic-minutes"
                                                                  readOnly
                                                                  type="number"
                                                                  size="small"
                                                                  placeholder="Min"
                                                                  name="paceUp"
                                                                  value={formatWithLeadingZeros(
                                                                    Math.floor(
                                                                      item?.paceUp /
                                                                        60
                                                                    )
                                                                  )}
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleMinutesChange(
                                                                      e,
                                                                      index,
                                                                      exerIndex,
                                                                      setIndex
                                                                    )
                                                                  }
                                                                  InputProps={{
                                                                    className:
                                                                      "textfiels-input-value",
                                                                    style: {
                                                                      fontSize:
                                                                        "14px",
                                                                      // width: "40px",
                                                                    },
                                                                    readOnly: true,
                                                                  }}
                                                                  sx={{
                                                                    "& fieldset":
                                                                      {
                                                                        border:
                                                                          "none",
                                                                      },
                                                                    // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                                    //   {
                                                                    //     padding: "0px !important",
                                                                    //   },
                                                                    "& input": {
                                                                      textAlign:
                                                                        "center",
                                                                      padding:
                                                                        "0px",
                                                                    },
                                                                    width:
                                                                      "100%",
                                                                  }}
                                                                />
                                                                <div className="fs-6 fw-semibold ms-1">
                                                                  :
                                                                </div>
                                                                <TextField
                                                                  id="outlined-basic-seconds"
                                                                  type="number"
                                                                  size="small"
                                                                  placeholder="Sec"
                                                                  readOnly
                                                                  name="paceUp"
                                                                  value={formatWithLeadingZeros(
                                                                    item?.paceUp %
                                                                      60
                                                                  )}
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleSecondsChange(
                                                                      e,
                                                                      index,
                                                                      exerIndex,
                                                                      setIndex
                                                                    )
                                                                  }
                                                                  InputProps={{
                                                                    className:
                                                                      "textfiels-input-value",
                                                                    style: {
                                                                      fontSize:
                                                                        "14px",
                                                                      // width: "40px",
                                                                    },
                                                                    readOnly: true,
                                                                  }}
                                                                  sx={{
                                                                    "& fieldset":
                                                                      {
                                                                        border:
                                                                          "none",
                                                                      },
                                                                    // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                                    //   {
                                                                    //     padding: "0px !important",
                                                                    //   },
                                                                    "& input": {
                                                                      textAlign:
                                                                        "center",
                                                                      padding:
                                                                        "0px",
                                                                    },
                                                                    width:
                                                                      "100%",
                                                                    marginRight: 1,
                                                                  }}
                                                                />
                                                              </InputAdornment>
                                                            ),
                                                          }}
                                                          sx={{
                                                            borderRadius: "5px",
                                                            fontSize: "13px",

                                                            marginTop: "5px",
                                                            "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                                                              {
                                                                width:
                                                                  "0px !important",
                                                                padding:
                                                                  "9px 0px !important",
                                                              },
                                                            "& .css-1nuss9t": {
                                                              width:
                                                                "0px !important",
                                                              padding:
                                                                "9px 0px !important",
                                                            },

                                                            width: "100%",
                                                          }}
                                                        />
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className={
                                                          isWithHistoryAndBigScreen
                                                            ? "col-2 pe-0 py-1"
                                                            : `col-12 col-md-2 col-lg-1 pe-0 py-1`
                                                        }
                                                      >
                                                        <TextField
                                                          id="outlined-basic"
                                                          variant="outlined"
                                                          label={getPaceText(
                                                            item?.pace_type
                                                          )}
                                                          readOnly
                                                          InputLabelProps={{
                                                            className:
                                                              "textfiels-input-label",
                                                            shrink: true,
                                                            style: {
                                                              fontSize: "14px",
                                                            },
                                                          }}
                                                          InputProps={{
                                                            className:
                                                              "textfiels-input-value",
                                                            style: {
                                                              fontSize: "13px",
                                                              paddingLeft:
                                                                "0px",
                                                              paddingRight:
                                                                "0px",

                                                              justifyContent:
                                                                "center",
                                                            },
                                                            readOnly: true,
                                                            endAdornment: (
                                                              <InputAdornment
                                                                position="end"
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                }}
                                                              >
                                                                <TextField
                                                                  id="outlined-basic-minutes"
                                                                  type="number"
                                                                  readOnly
                                                                  size="small"
                                                                  placeholder="Min"
                                                                  name="pace"
                                                                  value={formatWithLeadingZeros(
                                                                    Math.floor(
                                                                      item?.pace /
                                                                        60
                                                                    )
                                                                  )}
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleMinutesChange(
                                                                      e,
                                                                      index,
                                                                      exerIndex,
                                                                      setIndex
                                                                    )
                                                                  }
                                                                  InputProps={{
                                                                    className:
                                                                      "textfiels-input-value",
                                                                    style: {
                                                                      fontSize:
                                                                        "14px",
                                                                      // width: "40px",
                                                                    },
                                                                    readOnly: true,
                                                                  }}
                                                                  sx={{
                                                                    "& fieldset":
                                                                      {
                                                                        border:
                                                                          "none",
                                                                      },
                                                                    // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                                    //   {
                                                                    //     padding: "0px !important",
                                                                    //   },
                                                                    "& input": {
                                                                      textAlign:
                                                                        "center",
                                                                      padding:
                                                                        "0px",
                                                                    },
                                                                    width:
                                                                      "100%",
                                                                  }}
                                                                />
                                                                <div className="fs-6 fw-semibold ms-1 ">
                                                                  :
                                                                </div>

                                                                <TextField
                                                                  id="outlined-basic-seconds"
                                                                  type="number"
                                                                  readOnly
                                                                  size="small"
                                                                  placeholder="Sec"
                                                                  name="pace"
                                                                  value={formatWithLeadingZeros(
                                                                    item?.pace %
                                                                      60
                                                                  )}
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleSecondsChange(
                                                                      e,
                                                                      index,
                                                                      exerIndex,
                                                                      setIndex
                                                                    )
                                                                  }
                                                                  InputProps={{
                                                                    className:
                                                                      "textfiels-input-value",
                                                                    style: {
                                                                      fontSize:
                                                                        "14px",
                                                                      // width: "40px",
                                                                    },
                                                                    readOnly: true,
                                                                  }}
                                                                  sx={{
                                                                    "& fieldset":
                                                                      {
                                                                        border:
                                                                          "none",
                                                                      },
                                                                    // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                                    //   {
                                                                    //     padding: "0px !important",
                                                                    //   },
                                                                    "& input": {
                                                                      textAlign:
                                                                        "center",
                                                                      padding:
                                                                        "0px",
                                                                    },
                                                                    width:
                                                                      "100%",
                                                                  }}
                                                                />
                                                              </InputAdornment>
                                                            ),
                                                          }}
                                                          sx={{
                                                            borderRadius: "5px",
                                                            fontSize: "13px",
                                                            "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                                                              {
                                                                width:
                                                                  "0px !important",
                                                                padding:
                                                                  "9px 0px !important",
                                                              },
                                                            "& .css-1nuss9t": {
                                                              width:
                                                                "0px !important",
                                                              padding:
                                                                "9px 0px !important",
                                                            },
                                                            // "& input": {
                                                            //   textAlign: "center",
                                                            //   padding: "0px",
                                                            // },
                                                            width: "100%",
                                                            // flexGrow: 1,
                                                            marginTop: "5px",
                                                          }}
                                                        />
                                                      </div>
                                                    )
                                                  ) : (
                                                    ""
                                                  )}
                                                </>
                                              )}
                                              {exerData.parameters.includes(
                                                "Pace"
                                              ) && (
                                                <>
                                                  <div
                                                    className={`col-12 col-md-3 col-lg-2 pe-0 py-2`}
                                                  >
                                                    <FormControl
                                                      readOnly
                                                      fullWidth
                                                    >
                                                      <InputLabel
                                                        className="set-Type-lable"
                                                        sx={{
                                                          fontSize: "14px",
                                                        }}
                                                        id="demo-simple-select-label"
                                                      >
                                                        Type
                                                      </InputLabel>

                                                      <Select
                                                        className="set-Type-value"
                                                        id="select-option"
                                                        variant="outlined"
                                                        size="small"
                                                        name="race_type"
                                                        value={item?.race_type}
                                                        label="Type"
                                                        readOnly="true"
                                                        inputProps={{
                                                          style: {
                                                            padding: 0,
                                                          },
                                                        }}
                                                        onChange={(e) => {
                                                          handleChangeEmomSet(
                                                            e,
                                                            index,
                                                            exerIndex,
                                                            setIndex
                                                          );
                                                        }}
                                                        sx={{
                                                          width: "100%",
                                                          fontSize: "13px",
                                                          "& .MuiOutlinedInput-input":
                                                            {
                                                              paddingLeft: 1,
                                                            },
                                                        }}
                                                      >
                                                        <MenuItem value="el">
                                                          Easy/Long
                                                        </MenuItem>
                                                        <MenuItem value="c">
                                                          Compromised
                                                        </MenuItem>

                                                        <MenuItem value="i">
                                                          Interval
                                                        </MenuItem>
                                                        <MenuItem value="r">
                                                          Repitition
                                                        </MenuItem>
                                                      </Select>
                                                    </FormControl>
                                                  </div>
                                                  <div
                                                    className={`col-12 col-md-3 col-lg-2 pe-0 py-2`}
                                                  >
                                                    <TextField
                                                      sx={{
                                                        borderRadius: "5px",
                                                        paddingRight: 0,
                                                        width: "100%",
                                                      }}
                                                      readOnly
                                                      size="small"
                                                      id="custom-input"
                                                      variant="outlined"
                                                      placeholder={"Distance"}
                                                      name="distance"
                                                      label="Distance"
                                                      value={item?.distance}
                                                      onChange={(e) => {
                                                        handleChangeEmomSet(
                                                          e,
                                                          index,
                                                          exerIndex,
                                                          setIndex
                                                        );
                                                      }}
                                                      type="number"
                                                      InputLabelProps={{
                                                        className:
                                                          "textfiels-input-label",
                                                        shrink: true,
                                                        style: {
                                                          fontSize: "14px",
                                                        },
                                                      }}
                                                      InputProps={{
                                                        className:
                                                          "textfiels-input-value",
                                                        style: {
                                                          fontSize: "13px",
                                                          paddingRight: 0,
                                                        },
                                                        readOnly: true,
                                                        endAdornment: (
                                                          <InputAdornment position="end">
                                                            <Select
                                                              id="select-option"
                                                              variant="outlined"
                                                              size="small"
                                                              name="distanceType"
                                                              value={
                                                                item?.distanceType
                                                              }
                                                              label="Weight Type"
                                                              readOnly={true}
                                                              onChange={(e) => {
                                                                handleChangeEmomSet(
                                                                  e,
                                                                  index,
                                                                  exerIndex,
                                                                  setIndex
                                                                );
                                                              }}
                                                              sx={{
                                                                "& legend": {
                                                                  display:
                                                                    "none",
                                                                },
                                                                "& fieldset": {
                                                                  top: 0,
                                                                },
                                                                fontSize:
                                                                  "13px",
                                                                border: "none",
                                                                "& fieldset": {
                                                                  border:
                                                                    "none",
                                                                },
                                                              }}
                                                            >
                                                              <MenuItem value="m">
                                                                Meter
                                                              </MenuItem>
                                                              <MenuItem value="km">
                                                                KM
                                                              </MenuItem>
                                                              <MenuItem value="mi">
                                                                Mile
                                                              </MenuItem>
                                                            </Select>
                                                          </InputAdornment>
                                                        ),
                                                      }}
                                                    />
                                                  </div>
                                                  <div
                                                    className={`col-12 col-md-2 col-lg-2 pe-0 py-2`}
                                                  >
                                                    <TextField
                                                      id="outlined-basic"
                                                      variant="outlined"
                                                      label={getPaceTextinPaces(
                                                        item?.distanceType
                                                      )}
                                                      InputLabelProps={{
                                                        className:
                                                          "textfiels-input-label",
                                                        shrink: true,
                                                        style: {
                                                          fontSize: "14px",
                                                        },
                                                      }}
                                                      readOnly={true}
                                                      InputProps={{
                                                        className:
                                                          "textfiels-input-value",
                                                        style: {
                                                          fontSize: "13px",
                                                          paddingLeft: "0px",
                                                          paddingRight: "0px",

                                                          justifyContent:
                                                            "center",
                                                        },
                                                        readOnly: true,
                                                        endAdornment: (
                                                          <InputAdornment
                                                            position="end"
                                                            style={{
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                            }}
                                                          >
                                                            <TextField
                                                              id="outlined-basic-minutes"
                                                              type="number"
                                                              size="small"
                                                              placeholder="Min"
                                                              name="pace"
                                                              value={formatWithLeadingZeros(
                                                                Math.floor(
                                                                  item?.pace /
                                                                    60
                                                                )
                                                              )}
                                                              readOnly={true}
                                                              onChange={(e) =>
                                                                handleMinutesChangeSets(
                                                                  e,
                                                                  index,
                                                                  exerIndex,
                                                                  setIndex
                                                                )
                                                              }
                                                              InputProps={{
                                                                className:
                                                                  "textfiels-input-value",
                                                                style: {
                                                                  fontSize:
                                                                    "14px",
                                                                },
                                                                readOnly: true,
                                                              }}
                                                              sx={{
                                                                "& fieldset": {
                                                                  border:
                                                                    "none",
                                                                },
                                                                "& input": {
                                                                  textAlign:
                                                                    "center",
                                                                  padding:
                                                                    "0px",
                                                                },
                                                                width: "100%",
                                                              }}
                                                            />
                                                            <div className="fs-6 fw-semibold ms-1 ">
                                                              :
                                                            </div>

                                                            <TextField
                                                              id="outlined-basic-seconds"
                                                              type="number"
                                                              size="small"
                                                              placeholder="Sec"
                                                              name="pace"
                                                              value={formatWithLeadingZeros(
                                                                item?.pace % 60
                                                              )}
                                                              onChange={(e) =>
                                                                handleSecondsChangeSets(
                                                                  e,
                                                                  index,
                                                                  exerIndex,
                                                                  setIndex
                                                                )
                                                              }
                                                              InputProps={{
                                                                className:
                                                                  "textfiels-input-value",
                                                                style: {
                                                                  fontSize:
                                                                    "14px",
                                                                },
                                                                readOnly: true,
                                                              }}
                                                              sx={{
                                                                "& fieldset": {
                                                                  border:
                                                                    "none",
                                                                },
                                                                "& input": {
                                                                  textAlign:
                                                                    "center",
                                                                  padding:
                                                                    "0px",
                                                                },
                                                                width: "100%",
                                                              }}
                                                            />
                                                          </InputAdornment>
                                                        ),
                                                      }}
                                                      sx={{
                                                        borderRadius: "5px",
                                                        fontSize: "13px",
                                                        "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                                                          {
                                                            width:
                                                              "0px !important",
                                                            padding:
                                                              "9px 0px !important",
                                                          },
                                                        "& .css-1nuss9t": {
                                                          width:
                                                            "0px !important",
                                                          padding:
                                                            "9px 0px !important",
                                                        },
                                                        width: "100%",
                                                      }}
                                                    />
                                                  </div>
                                                </>
                                              )}
                                              {exerData.parameters.includes(
                                                "Pace"
                                              ) ||
                                                (exerData.parameters.includes(
                                                  "Vdot"
                                                ) && (
                                                  <div
                                                    style={{
                                                      position: "absolute",
                                                      bottom: "4px",
                                                      left: "5px",
                                                    }}
                                                  >
                                                    <Typography
                                                      variant="subtitle2"
                                                      sx={{
                                                        opacity: 0.6,
                                                        fontWeight: "600",
                                                        fontSize: "12px",
                                                      }}
                                                    >{` @ Speed ${getKmhrForPace(
                                                      item?.distance,
                                                      item?.pace,
                                                      item?.distanceType
                                                    )} km/hr or ${getMihrForPace(
                                                      item?.distance,
                                                      item?.pace,
                                                      item?.distanceType
                                                    )} mi/hr`}</Typography>
                                                  </div>
                                                ))}
                                              {(exerData.parameters.includes(
                                                "Duration/Pace"
                                              ) ||
                                                exerData.parameters.includes(
                                                  "Time"
                                                )) && (
                                                <div
                                                  className={`col-12 col-md-3 col-lg-2 pe-0 py-1`}
                                                >
                                                  <TextField
                                                    id="outlined-basic"
                                                    size="small"
                                                    variant="outlined"
                                                    onMouseLeave={() =>
                                                      handleMouseEnter(
                                                        setIndex,
                                                        mainindex
                                                      )
                                                    }
                                                    readOnly={true}
                                                    onClick={() =>
                                                      handleMouseLeave()
                                                    }
                                                    placeholder="0"
                                                    type="number"
                                                    value={item?.time}
                                                    name="time"
                                                    onChange={(e) => {
                                                      handleChangeEmomSet(
                                                        e,
                                                        index,
                                                        exerIndex,
                                                        setIndex
                                                      );
                                                    }}
                                                    InputLabelProps={{
                                                      className:
                                                        "textfiels-input-label",
                                                      shrink: true,
                                                      style: {
                                                        fontSize: "14px",
                                                      },
                                                    }}
                                                    label={
                                                      exerData.parameters.includes(
                                                        "Duration/Pace"
                                                      )
                                                        ? "Duration/Pace"
                                                        : "Time"
                                                    }
                                                    InputProps={{
                                                      className:
                                                        "textfiels-input-value",
                                                      style: {
                                                        fontSize: "13px",
                                                        paddingRight: 0,
                                                      },
                                                      readOnly: true,
                                                      endAdornment: (
                                                        <InputAdornment position="end">
                                                          <Select
                                                            id="select-option"
                                                            variant="outlined"
                                                            size="small"
                                                            name="timeType"
                                                            value={
                                                              item?.timeType
                                                            }
                                                            label="Weight Type"
                                                            inputProps={{
                                                              style: {
                                                                padding: 0,
                                                              },
                                                            }}
                                                            readOnly={true}
                                                            onChange={(e) => {
                                                              handleChangeEmomSet(
                                                                e,
                                                                index,
                                                                exerIndex,
                                                                setIndex
                                                              );
                                                            }}
                                                            sx={{
                                                              "& legend": {
                                                                display: "none",
                                                              },
                                                              "& fieldset": {
                                                                top: 0,
                                                              },
                                                              fontSize: "13px",
                                                              border: "none",
                                                              "& fieldset": {
                                                                border: "none",
                                                              },
                                                              "& .MuiOutlinedInput-input":
                                                                {
                                                                  padding: 0,
                                                                },
                                                            }}
                                                          >
                                                            <MenuItem value="sec">
                                                              sec
                                                            </MenuItem>
                                                            <MenuItem value="min">
                                                              min
                                                            </MenuItem>
                                                          </Select>
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                    sx={{
                                                      borderRadius: "5px",
                                                      fontSize: "13px",
                                                      width: "100%",
                                                      marginTop: "5px",
                                                    }}
                                                  />
                                                </div>
                                              )}

                                              {/* {exerData.parameters.includes(
                                                "Reps"
                                              ) && (
                                                <div className="col-12 col-md-2 col-lg-1 pe-0 py-2">
                                                  <TextField
                                                    id="outlined-basic"
                                                    size="small"
                                                    variant="outlined"
                                                    placeholder="Reps"
                                                    type="number"
                                                    label="Reps"
                                                    value={item?.reps}
                                                    name="reps"
                                                    readOnly
                                                    onChange={(e) => {
                                                      handleChangeEmomSet(
                                                        e,
                                                        index,
                                                        exerIndex,
                                                        setIndex
                                                      );
                                                    }}
                                                    InputLabelProps={{
                                                      className:
                                                        "textfiels-input-label",
                                                      shrink: true,
                                                      style: {
                                                        fontSize: "14px",
                                                      },
                                                    }}
                                                    InputProps={{
                                                      className:
                                                        "textfiels-input-value",
                                                      style: {
                                                        fontSize: "13px",
                                                      },
                                                      readOnly: true,
                                                    }}
                                                    sx={{
                                                      borderRadius: "5px",
                                                      fontSize: "13px",
                                                      width: "100%",
                                                    }}
                                                  />
                                                </div>
                                              )} */}
                                              {exerData.parameters.includes(
                                                "Height"
                                              ) && (
                                                <div className="col-12 col-md-3 col-lg-2 pe-0 py-2">
                                                  <TextField
                                                    id="outlined-basic"
                                                    size="small"
                                                    variant="outlined"
                                                    placeholder="0"
                                                    type="number"
                                                    readOnly="true"
                                                    value={item?.height}
                                                    name="height"
                                                    onChange={(e) => {
                                                      handleChangeEmomSet(
                                                        e,
                                                        index,
                                                        exerIndex,
                                                        setIndex
                                                      );
                                                    }}
                                                    InputLabelProps={{
                                                      className:
                                                        "textfiels-input-label",
                                                      shrink: true,
                                                      style: {
                                                        fontSize: "14px",
                                                      },
                                                    }}
                                                    label="Height"
                                                    InputProps={{
                                                      className:
                                                        "textfiels-input-value",
                                                      style: {
                                                        fontSize: "13px",
                                                        paddingRight: 0,
                                                      },
                                                      readOnly: true,
                                                      endAdornment: (
                                                        <InputAdornment position="end">
                                                          <Select
                                                            id="select-option"
                                                            variant="outlined"
                                                            size="small"
                                                            name="heightType"
                                                            value={
                                                              item?.heightType
                                                            }
                                                            label="Weight Type"
                                                            inputProps={{
                                                              style: {
                                                                padding: 0,
                                                              },
                                                            }}
                                                            readOnly
                                                            onChange={(e) => {
                                                              handleChangeEmomSet(
                                                                e,
                                                                index,
                                                                exerIndex,
                                                                setIndex
                                                              );
                                                            }}
                                                            sx={{
                                                              "& legend": {
                                                                display: "none",
                                                              },
                                                              "& fieldset": {
                                                                top: 0,
                                                              },
                                                              fontSize: "13px",
                                                              border: "none",
                                                              "& fieldset": {
                                                                border: "none",
                                                              },
                                                              "& .MuiOutlinedInput-input":
                                                                {
                                                                  padding: 0,
                                                                },
                                                            }}
                                                          >
                                                            <MenuItem value="cm">
                                                              Cm
                                                            </MenuItem>
                                                            <MenuItem value="ft">
                                                              Feet
                                                            </MenuItem>
                                                            <MenuItem value="inch">
                                                              Inch
                                                            </MenuItem>
                                                          </Select>
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                    sx={{
                                                      borderRadius: "5px",
                                                      fontSize: "13px",
                                                      width: "100%",
                                                    }}
                                                  />
                                                </div>
                                              )}
                                              {exerData.parameters.includes(
                                                "Weight"
                                              ) && (
                                                <div className="col-12 col-md-4 col-lg-2 pe-0 py-2">
                                                  <TextField
                                                    sx={{
                                                      borderRadius: "5px",
                                                      paddingRight: 0,
                                                      width: "100%",
                                                    }}
                                                    size="small"
                                                    id="custom-input"
                                                    variant="outlined"
                                                    placeholder="Weight"
                                                    name="weight"
                                                    label="Weight"
                                                    readOnly="true"
                                                    value={item.weight}
                                                    onChange={(e) => {
                                                      handleChangeEmomSet(
                                                        e,
                                                        index,
                                                        exerIndex,
                                                        setIndex
                                                      );
                                                    }}
                                                    type="number"
                                                    InputLabelProps={{
                                                      className:
                                                        "textfiels-input-label",
                                                      shrink: true,
                                                      style: {
                                                        fontSize: "14px",
                                                      },
                                                    }}
                                                    InputProps={{
                                                      className:
                                                        "textfiels-input-value",
                                                      style: {
                                                        fontSize: "13px",
                                                        paddingRight: 0,
                                                      },
                                                      readOnly: true,
                                                      endAdornment: (
                                                        <InputAdornment position="end">
                                                          <Select
                                                            id="select-option"
                                                            variant="outlined"
                                                            size="small"
                                                            name="weightType"
                                                            value={
                                                              item?.weightType
                                                            }
                                                            readOnly
                                                            label="Weight Type"
                                                            onChange={(e) => {
                                                              handleChangeEmomSet(
                                                                e,
                                                                index,
                                                                exerIndex,
                                                                setIndex
                                                              );
                                                            }}
                                                            sx={{
                                                              "& legend": {
                                                                display: "none",
                                                              },
                                                              "& fieldset": {
                                                                top: 0,
                                                              },
                                                              fontSize: "13px",
                                                              border: "none",
                                                              "& fieldset": {
                                                                border: "none",
                                                              },
                                                              "& .MuiOutlinedInput-input":
                                                                {
                                                                  padding: 0,
                                                                },
                                                            }}
                                                          >
                                                            <MenuItem value="1rm">
                                                              % 1RM
                                                            </MenuItem>
                                                            <MenuItem value="body_weight">
                                                              % BW
                                                            </MenuItem>
                                                            <MenuItem value="kg">
                                                              KG
                                                            </MenuItem>
                                                            <MenuItem value="lb">
                                                              Pound
                                                            </MenuItem>
                                                            <MenuItem value="poods">
                                                              Poods
                                                            </MenuItem>
                                                          </Select>
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                  />
                                                </div>
                                              )}
                                              {exerData.parameters.includes(
                                                "Distance/Calories"
                                              ) && (
                                                <div className="col-12 col-md-3 col-lg-2 pe-0 py-2">
                                                  <TextField
                                                    sx={{
                                                      borderRadius: "5px",
                                                      paddingRight: 0,
                                                      width: "100%",
                                                    }}
                                                    size="small"
                                                    id="custom-input"
                                                    variant="outlined"
                                                    placeholder={
                                                      item?.distanceType ==
                                                      "calories"
                                                        ? "Calories"
                                                        : "Distance"
                                                    }
                                                    name="distance"
                                                    label="Distance/Calories"
                                                    value={item?.distance}
                                                    onChange={(e) => {
                                                      handleChangeEmomSet(
                                                        e,
                                                        index,
                                                        exerIndex,
                                                        setIndex
                                                      );
                                                    }}
                                                    type="number"
                                                    InputLabelProps={{
                                                      className:
                                                        "textfiels-input-label",
                                                      shrink: true,
                                                      style: {
                                                        fontSize: "14px",
                                                      },
                                                    }}
                                                    InputProps={{
                                                      className:
                                                        "textfiels-input-value",
                                                      style: {
                                                        fontSize: "13px",
                                                        paddingRight: 0,
                                                      },
                                                      readOnly: true,
                                                      endAdornment: (
                                                        <InputAdornment position="end">
                                                          <Select
                                                            id="select-option"
                                                            variant="outlined"
                                                            size="small"
                                                            name="distanceType"
                                                            value={
                                                              item?.distanceType
                                                            }
                                                            label="Weight Type"
                                                            readOnly={true}
                                                            onChange={(e) => {
                                                              handleChangeEmomSet(
                                                                e,
                                                                index,
                                                                exerIndex,
                                                                setIndex
                                                              );
                                                            }}
                                                            sx={{
                                                              "& legend": {
                                                                display: "none",
                                                              },
                                                              "& fieldset": {
                                                                top: 0,
                                                              },
                                                              fontSize: "13px",
                                                              border: "none",
                                                              "& fieldset": {
                                                                border: "none",
                                                              },
                                                              "& .MuiOutlinedInput-input":
                                                                {
                                                                  padding: 0,
                                                                },
                                                            }}
                                                          >
                                                            <MenuItem value="m">
                                                              Meter
                                                            </MenuItem>
                                                            <MenuItem value="km">
                                                              KM
                                                            </MenuItem>
                                                            <MenuItem value="mi">
                                                              Mile
                                                            </MenuItem>
                                                            <MenuItem value="calories">
                                                              Calories
                                                            </MenuItem>
                                                          </Select>
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                  />
                                                </div>
                                              )}
                                              {exerData.parameters.includes(
                                                "%MHR"
                                              ) && (
                                                <div className="col-12 col-md-2 col-lg-1 pe-0 py-2">
                                                  <TextField
                                                    id="outlined-basic"
                                                    size="small"
                                                    variant="outlined"
                                                    placeholder="%MHR"
                                                    type="number"
                                                    label="%MHR"
                                                    readOnly="true"
                                                    value={item?.mhr}
                                                    name="mhr"
                                                    onChange={(e) => {
                                                      handleChangeEmomSetTempo(
                                                        e,
                                                        index,
                                                        exerIndex,
                                                        setIndex
                                                      );
                                                    }}
                                                    InputLabelProps={{
                                                      className:
                                                        "textfiels-input-label",
                                                      shrink: true,
                                                      style: {
                                                        fontSize: "14px",
                                                      },
                                                    }}
                                                    InputProps={{
                                                      className:
                                                        "textfiels-input-value",
                                                      style: {
                                                        fontSize: "13px",
                                                      },
                                                      readOnly: true,
                                                    }}
                                                    sx={{
                                                      borderRadius: "5px",

                                                      fontSize: "13px",
                                                      width: "100%",
                                                      "& .MuiOutlinedInput-input":
                                                        {
                                                          padding: "8px 7px",
                                                        },
                                                    }}
                                                  />
                                                </div>
                                              )}
                                              {exerData.parameters.includes(
                                                "Tempo"
                                              ) && (
                                                <div className="col-12 col-md-2 col-lg-1 pe-0 py-2">
                                                  <TextField
                                                    id="outlined-basic"
                                                    size="small"
                                                    variant="outlined"
                                                    placeholder="Tempo"
                                                    type="number"
                                                    label="Tempo"
                                                    readOnly="true"
                                                    value={item?.tempo}
                                                    name="tempo"
                                                    onChange={(e) => {
                                                      handleChangeEmomSetTempo(
                                                        e,
                                                        index,
                                                        exerIndex,
                                                        setIndex
                                                      );
                                                    }}
                                                    InputLabelProps={{
                                                      className:
                                                        "textfiels-input-label",
                                                      shrink: true,
                                                      style: {
                                                        fontSize: "14px",
                                                      },
                                                    }}
                                                    InputProps={{
                                                      className:
                                                        "textfiels-input-value",
                                                      style: {
                                                        fontSize: "13px",
                                                      },
                                                      readOnly: true,
                                                    }}
                                                    sx={{
                                                      borderRadius: "5px",
                                                      fontSize: "13px",
                                                      width: "100%",
                                                      "& .MuiOutlinedInput-input":
                                                        {
                                                          padding: "8px 7px",
                                                        },
                                                    }}
                                                  />
                                                </div>
                                              )}
                                              {exerData.parameters.includes(
                                                "Rest"
                                              ) && (
                                                <div className="col-12 col-md-3 col-lg-2 pe-0 py-2">
                                                  <TextField
                                                    id="outlined-basic"
                                                    size="small"
                                                    variant="outlined"
                                                    placeholder="0"
                                                    type="number"
                                                    readOnly="true"
                                                    value={item?.rest}
                                                    name="rest"
                                                    onChange={(e) => {
                                                      handleChangeEmomSet(
                                                        e,
                                                        index,
                                                        exerIndex,
                                                        setIndex
                                                      );
                                                    }}
                                                    InputLabelProps={{
                                                      className:
                                                        "textfiels-input-label",
                                                      shrink: true,
                                                      style: {
                                                        fontSize: "14px",
                                                      },
                                                    }}
                                                    label="Rest"
                                                    InputProps={{
                                                      className:
                                                        "textfiels-input-value",
                                                      style: {
                                                        fontSize: "13px",
                                                        paddingRight: 0,
                                                      },
                                                      readOnly: true,
                                                      endAdornment: (
                                                        <InputAdornment position="end">
                                                          <Select
                                                            readOnly
                                                            id="select-option"
                                                            variant="outlined"
                                                            size="small"
                                                            name="restType"
                                                            value={
                                                              item?.restType
                                                            }
                                                            label="Weight Type"
                                                            inputProps={{
                                                              style: {
                                                                padding: 0,
                                                              },
                                                            }}
                                                            onChange={(e) => {
                                                              handleChangeEmomSet(
                                                                e,
                                                                index,
                                                                exerIndex,
                                                                setIndex
                                                              );
                                                            }}
                                                            sx={{
                                                              "& legend": {
                                                                display: "none",
                                                              },
                                                              "& fieldset": {
                                                                top: 0,
                                                              },
                                                              fontSize: "13px",
                                                              border: "none",
                                                              "& fieldset": {
                                                                border: "none",
                                                              },
                                                              "& .MuiOutlinedInput-input":
                                                                {
                                                                  padding: 0,
                                                                },
                                                            }}
                                                          >
                                                            <MenuItem value="sec">
                                                              sec
                                                            </MenuItem>
                                                            <MenuItem value="min">
                                                              min
                                                            </MenuItem>
                                                          </Select>
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                    sx={{
                                                      borderRadius: "5px",
                                                      fontSize: "13px",
                                                      width: "100%",
                                                    }}
                                                  />
                                                </div>
                                              )}
                                            </div>
                                          </Tooltip>
                                        </div>
                                      ))}
                                  </Box>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
                {isPersonal && (
                  <Tooltip touch="on" placement="top" title={message}>
                    <div className="mx-4 d-flex align-items-center justify-content-start flex-wrap mt-3 ">
                      <div className="d-flex align-items-center justify-content-start mt-3 pe-3 ">
                        <div style={{ whiteSpace: "nowrap" }}>Reps</div>
                        {repsRound.map((item, index) => {
                          return (
                            <div className={``}>
                              <TextField
                                id="outlined-basic"
                                size="small"
                                variant="outlined"
                                placeholder=""
                                type="number"
                                value={item}
                                name="total_rounds"
                                onChange={(e) =>
                                  handleChangeCompletedRoundsReps(e, index)
                                }
                                InputLabelProps={{
                                  className: "textfiels-input-label",
                                  shrink: true,
                                  style: { fontSize: "14px" },
                                }}
                                InputProps={{
                                  className: "textfiels-input-value",
                                  style: { fontSize: "13px", padding: "0" },
                                  readOnly: true,
                                }}
                                sx={{
                                  mx: 1,
                                  borderRadius: "5px",
                                  "& legend": { display: "none" },
                                  "& fieldset": { top: 0 },
                                  fontSize: "13px",
                                  "& input": {
                                    padding: "5px 5px",
                                    textAlign: "center",
                                  },
                                  width: "33px",
                                }}
                              />{" "}
                            </div>
                          );
                        })}
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-7 d-flex align-items-center justify-content-start mt-3 ">
                          <div
                            style={{ whiteSpace: "nowrap" }}
                            className="pe-3"
                          >
                            Total Time Completed
                          </div>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            label="Total Time"
                            InputLabelProps={{
                              className: "textfiels-input-label",
                              shrink: true,
                              style: {
                                fontSize: "14px",
                              },
                            }}
                            InputProps={{
                              className: "textfiels-input-value",
                              style: {
                                fontSize: "13px",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                justifyContent: "center",
                              },
                              readOnly: true,
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <TextField
                                    id="outlined-basic-minutes"
                                    type="number"
                                    size="small"
                                    placeholder="Min"
                                    value={formatWithLeadingZeros(
                                      Math.floor(
                                        addArray[mainindex].time_interval / 60
                                      )
                                    )}
                                    onChange={handleMinutesChange}
                                    InputProps={{
                                      className: "textfiels-input-value",
                                      style: {
                                        fontSize: "14px",
                                      },
                                      readOnly: true,
                                    }}
                                    sx={{
                                      "& fieldset": {
                                        border: "none",
                                      },
                                      "& input": {
                                        textAlign: "center",
                                        padding: "0px",
                                      },
                                      width: "100%",
                                    }}
                                  />
                                  <div className="fs-6 fw-semibold ms-1 ">
                                    :
                                  </div>

                                  <TextField
                                    id="outlined-basic-seconds"
                                    type="number"
                                    size="small"
                                    placeholder="Sec"
                                    value={formatWithLeadingZeros(
                                      addArray[mainindex].time_interval % 60
                                    )}
                                    onChange={handleSecondsChange}
                                    InputProps={{
                                      className: "textfiels-input-value",
                                      style: {
                                        fontSize: "14px",
                                      },
                                      readOnly: true,
                                    }}
                                    sx={{
                                      "& fieldset": {
                                        border: "none",
                                      },
                                      "& input": {
                                        textAlign: "center",
                                        padding: "0px",
                                      },
                                      width: "100%",
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            sx={{
                              borderRadius: "5px",
                              fontSize: "13px",
                              "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                                {
                                  width: "0px !important",
                                  padding: "9px 0px !important",
                                },
                              "& .css-1nuss9t": {
                                width: "0px !important",
                                padding: "9px 0px !important",
                              },
                              width: "100%",
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="d-flex justify-content-end">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{ p: "3px" }}
                              checked={addArray[mainindex].is_completed}
                              onChange={(e) =>
                                handleChangeComplete(e, mainindex)
                              }
                            />
                          }
                          label="Mark as Complete"
                        />
                      </FormGroup>
                      </div> */}
                    </div>
                  </Tooltip>
                )}
              </Box>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default RepsForTimeExercisesUI;
